import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function HeaderContainer({ children }) {
    const location = useLocation();

    const [showNavbar, setShowNavbar] = useState(false);
    useEffect(() => {
        if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/home') )   {
            setShowNavbar(false);
        } else {
            setShowNavbar(true);
        }
    }, [location]);

    return (
        <div>
            {showNavbar && children}
        </div>
    )
}

export default HeaderContainer