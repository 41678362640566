// Loader.js
import React from 'react';
import './Loader.css'; // Example CSS for styling the loader

function Loader() {
    return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
}

export default Loader;
