import React, { useState } from 'react';

const APIsDocumentation = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <section className="max-w-[80rem] px-8 mx-auto py-20 overflow-hidden">

            <div className='flex space-x-8 items-start justify-between'>
                <nav className="relative w-1/4 flex flex-col justify-start space-y-4 overflow-hidden">
                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab1' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab1')}
                        aria-selected={activeTab === 'tab1'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Split PDFs</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab2' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab2')}
                        aria-selected={activeTab === 'tab2'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Merge PDFs</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab3' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab3')}
                        aria-selected={activeTab === 'tab3'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">JSON to PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab4' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab4')}
                        aria-selected={activeTab === 'tab4'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Encrypt PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab5' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab5')}
                        aria-selected={activeTab === 'tab5'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Decrypt PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab6' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab6')}
                        aria-selected={activeTab === 'tab6'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Decode Base64 PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab7' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab7')}
                        aria-selected={activeTab === 'tab7'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Lock PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>

                    <button
                        className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab8' ? 'bg-gray-900 text-white' : 'bg-blue-50 text-blue-900 hover:text-white'}`}
                        onClick={() => handleTabClick('tab8')}
                        aria-selected={activeTab === 'tab8'}
                        role='tab'>
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                        <div className="relative flex items-center justify-between">
                            <span className="mr-4">Watermark PDF</span>
                            <span>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                    </button>
                </nav>

                <div className='w-3/4'>
                    <div className={`tab-content ${activeTab === 'tab1' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Split PDF</h1>
                                    <p className='text-md text-gray-600'>Split your pdf into mutiple pages.</p>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/split-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Send these data in req.body.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">base64Pdf</span>
                                                    <span className="text-gray-400">, </span>
                                                    <span className="text-yellow-400">startPage</span>
                                                    <span className="text-gray-400">, </span>
                                                    <span className="text-yellow-400">endPage</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab2' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Merge PDF</h1>
                                    <p className='text-md text-gray-600'>Merge multiple PDFs into one PDF</p>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/merge-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload multiple PDFs files as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">pdfs</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab3' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>JSON to PDF</h1>
                                    <p className='text-md text-gray-600'>Upload a JSON File or set json data in body</p>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/json-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload multiple PDFs files as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">[JSON Data here...]</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab4' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Encrypt PDF</h1>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/encrypt-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload PDFs file and add password as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">pdf,</span>
                                                    <span className="text-yellow-400">password</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab5' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Decrypt PDF</h1>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/decrypt-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload PDFs file and add password as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">pdf,</span>
                                                    <span className="text-yellow-400">password</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab6' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Decode Base64 PDF</h1>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/decode-base64-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload PDFs file and add password as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">base64Data</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab7' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Lock PDF</h1>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/lock-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload PDFs file and add password as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">pdfBase64,</span>
                                                    <span className="text-yellow-400">userPassword</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tab-content ${activeTab === 'tab8' ? 'block' : 'hidden'}`}>
                        <div className='w-full bg-slate-100 border border-slate-200 h-full rounded-md p-8'>
                            <div className='flex flex-col space-y-8'>

                                <div>
                                    <h1 className='text-5xl font-light mb-2'>Watermark PDF</h1>
                                </div>


                                <div className="w-full">

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code className="text-blue-400">
                                                <span className="text-green-400">https://</span>
                                                <span className="text-yellow-400">api.</span>
                                                <span className="text-yellow-300">quickdoc</span>
                                                <span className="text-red-400">.app</span>
                                                <span className="text-green-400">/api</span>
                                                <span className="text-blue-400">/watermark-pdf</span>
                                            </code>
                                        </pre>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-1">Options</h3>
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                                                <br />
                                                {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                                                <br />
                                                {'}'}
                                            </code>
                                        </pre>

                                    </div>

                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                                            <p className='text-sm text-gray-600 mb-2'>Upload PDFs file and add password as form data.</p>
                                            <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                                <code>
                                                    <span className="text-red-400">{'{ '}</span>
                                                    <span className="text-yellow-400">pdfBase64,</span>
                                                    <span className="text-yellow-400">watermarkText,</span>
                                                    <span className="text-yellow-400">textSize,</span>
                                                    <span className="text-yellow-400">opacity,</span>
                                                    <span className="text-yellow-400">rotationDegree - (can be 0, 45 or -45 only),</span>
                                                    <span className="text-red-400">{' }'}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="text-xl font-semibold mb-2">Response</h3>
                                    </div>
                                    <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                                            <code>
                                                <span className="text-red-400">{'{ '}</span>
                                                <span className="text-yellow-400">Base64ProcessedPDF </span>
                                                <span className="text-red-400">{'} '}</span>
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default APIsDocumentation;