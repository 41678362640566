import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';
import toast from 'react-hot-toast';
import Loader from '../../components/Loader/Loader';

function AdminProtectedRoute(props) {
    const { Component } = props;
    const { authURL } = useAuth();
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [loading, setLoading] = useState(true); // Start with loading state true

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${authURL}/admin-authenticate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 200) {
                    console.log(`Access Granted!`);
                } else if (response.status === 403) {
                    toast.error(`Access Forbidden!`);
                    setRedirectToLogin(true);
                } else if (response.status === 401) {
                    console.log(`Token Invalid!`);
                    toast.error(`Login First!`);
                    setRedirectToLogin(true);
                } else if (response.status === 404) {
                    console.log('No Token Found');
                    setRedirectToLogin(true);
                } else {
                    console.log('Not admin');
                    setRedirectToLogin(true);
                }
            } catch (error) {
                console.error('Error while verifying token:', error);
                setRedirectToLogin(true);
            } finally {
                setTimeout(() => setLoading(false), 2000); // Show loader for 5 seconds
            }
        };

        verifyToken();
    }, [redirectToLogin, authURL]);

    if (redirectToLogin) {
        return <Navigate to="/admin-login" />;
    }

    return (
        <>
            {loading && <Loader />} {/* Show loader if loading is true */}
            {!loading && <Component />} {/* Show component after loading is false */}
        </>
    );
}

export default AdminProtectedRoute;
