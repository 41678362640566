import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function EncryptionHistory() {
  const { user } = useUser();
  const { authURL } = useAuth();
  const [encryptionData, setEncryptionData] = useState([]);

  // *toggling hide/show buttons
  const [showKeyStates, setShowKeyStates] = useState(encryptionData.map(() => false));

  // Function to toggle key visibility for a specific index
  const toggleKeyVisibility = (index) => {
    const newShowKeyStates = [...showKeyStates];
    newShowKeyStates[index] = !newShowKeyStates[index];
    setShowKeyStates(newShowKeyStates);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${authURL}/fetch-encrypted-data?email=${user.primaryEmailAddress.emailAddress}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEncryptionData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data');
    }
  };

  return (
    <div className='my-32 h-screen'>
      <div className='md:max-w-4xl xl:max-w-6xl mx-auto flex items-end justify-between'>
        <div>
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Encryption History
          </h1>
          <p className="mt-3 text-gray-600">
            See all your encrypted files with their respective encryption keys
          </p>
        </div>
        <div>
          <Link to='/home/encrypt-pdf' className='text-blue-600 underline'>
            <span className='flex items-center'>
              <i class="fa-duotone fa-arrow-left mr-2 no-underline"></i>  Back to Encryption
            </span>
          </Link>
        </div>
      </div>


      <table class="md:max-w-4xl xl:max-w-6xl mx-auto w-full mt-12 text-left border border-separate rounded border-slate-200" cellspacing="0">
        <tbody>
          <tr>
            <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">File Name</th>
            <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">File Link</th>
            <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Encryption Date</th>
            <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Encryption Key</th>
            <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Action</th>
          </tr>

          {encryptionData.map((entry, index) => (
            <tr key={entry._id} className={index % 2 === 0 ? 'bg-slate-50' : ''}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{entry.fileName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 underline"><a href={entry.fileURL} target="_blank" rel="noopener noreferrer">Link</a></td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{entry.date}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {showKeyStates[index] ? (
                  <span>{entry.encryptionKey}</span>
                ) : (
                  <span>************</span>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button className="text-xs text-blue-600 underline" onClick={() => toggleKeyVisibility(index)}>
                  {showKeyStates[index] ? <i class="fa-duotone fa-eye-slash"></i> : <i class="fa-duotone fa-eye"></i>}
                </button>
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div >
  )
}

export default EncryptionHistory