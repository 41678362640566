import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../context/data';
import toast from 'react-hot-toast';
import { useUser } from '@clerk/clerk-react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './Modal.css'

const BuisnessInfoModal = ({ isVisible, onClose }) => {
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { user } = useUser();
    const userID = user.primaryEmailAddress.emailAddress;
    const { authURL } = useAuth();
    const [businessInfo, setBusinessInfo] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessEmail, setBusinessEmail] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');

    const storage = getStorage();



    const resetForm = () => {
        setBusinessName('');
        setBusinessPhone('');
        setBusinessEmail('');
        setBusinessAddress('');
        setBase64Image('');
    };

    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        // Check if the selected file is an image
        if (!selectedFile.type.startsWith('image/')) {
            toast.error('Please select a valid image file.');
            return;
        }
        setFile(selectedFile);

        if (selectedFile) {
            setImageLoading(true);

            const storageRef = ref(storage, `images/${selectedFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, selectedFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Calculate the progress percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error('Upload failed:', error);
                    setImageLoading(false);
                    setUploadProgress(0);  // Reset progress on failure
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setBase64Image(downloadURL);
                        setImageLoading(false);
                        setUploadProgress(0);  // Reset progress on successful upload
                    });
                }
            );
        }
    };


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${authURL}/get-buisness-info?userID=${userID}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessInfo(data);
            setBase64Image(data.data.base64Image);
            setBusinessName(data.data.businessName);
            setBusinessAddress(data.data.businessAddress);
            setBusinessPhone(data.data.businessPhone);
            setBusinessEmail(data.data.businessEmail);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const sendBuisnessDetails = async () => {
        setLoading(true);
        if (businessName === '') {
            toast.error("Business Name is required!");
        }
        const buisnessObj = {
            userID: user.primaryEmailAddress.emailAddress,
            businessName,
            businessPhone,
            businessEmail,
            businessAddress,
            base64Image,
        };

        try {
            const response = await fetch(`${authURL}/add-buisness-info`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(buisnessObj),
            });

            if (response.ok) {
                toast.success('Information Added Successfully');
                resetForm();
                fetchData();
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateBuisnessDetails = async () => {

        if (businessName === '') {
            toast.error('Business Name is required!');
            return;
        }

        if (businessPhone === '') {
            toast.error('Business Phone is required!');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${authURL}/update-buisness-info?userID=${userID}`, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    base64Image,
                    businessName,
                    businessPhone,
                    businessEmail,
                    businessAddress,
                }),
            });

            if (response.ok) {
                toast.success('Information Updated Successfully');
                setEditMode(false);
                resetForm();
                fetchData();
                onClose();
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditButtonClick = () => {
        setEditMode(true);
    };

    useEffect(() => {
        if (isVisible) {
            fetchData();
        }
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] bg-opacity-75 backdrop-blur-sm flex justify-center items-center'>
            <div className='flex flex-col md:max-w-3xl xl:max-w-5xl mx-auto w-full'>
                <div className='bg-white h-full px-6 py-6 flex flex-col w-full max-w-7xl mx-auto border-2 border-slate-100 rounded shadow-sm'>

                    <div className='flex items-start justify-between'>
                        <div>
                            <h1 className='text-2xl font-semibold'>Business Information</h1>
                            <p className='text-sm text-gray-500'>Manage your Business Information for generating documents</p>
                        </div>
                        <button onClick={() => onClose()} className='activeBtn text-gray-800 text-sm'>
                            <i className="fa-solid fa-circle-xmark fa-lg text-gray-800"></i>
                        </button>
                    </div>

                    {loading && (
                        <div className="flex justify-center items-center h-[20rem] w-full">
                            <div className="loader"></div>
                        </div>
                    )}

                    {!loading && businessInfo ? (
                        <div className='flex items-start justify-between space-x-4'>
                            <div className='mt-8 space-y-4 p-2 w-full h-full'>
                                {editMode ? (
                                    <div className='flex space-x-4 w-full'>
                                        <div className='w-1/2'>
                                            <label htmlFor="dropzone-file" className="relative flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100" style={{ backgroundImage: `url(${base64Image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                                {imageLoading && (
                                                    <>
                                                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90">
                                                            <div className="w-1/2 h-2 bg-gray-800 rounded">
                                                                <div
                                                                    className="h-full bg-blue-500 rounded"
                                                                    style={{ width: `${uploadProgress}%` }}
                                                                ></div>
                                                            </div>
                                                            <p className="text-xs text-gray-500 mt-2">Uploading: {Math.round(uploadProgress)}%</p>
                                                        </div>
                                                    </>
                                                )}
                                                {!imageLoading && !base64Image && (
                                                    <div className="flex flex-col items-center justify-center">
                                                        <i className="fa-duotone fa-cloud-arrow-up fa-2xl m-4"></i>
                                                        <p className="text-xs text-center text-gray-500 mt-2 font-semibold">Click to upload <br /> your Logo</p>
                                                    </div>
                                                )}
                                                <input id="dropzone-file" type="file" accept="image/*" className="hidden" onChange={handleImageUpload} />
                                            </label>
                                            <p className="text-xs text-red-500 mt-2">Click on image to change the change!</p>
                                        </div>

                                        <div className='flex flex-col space-y-4 w-full'>
                                            <input
                                                type="text"
                                                value={businessName}
                                                maxLength={30}
                                                onChange={
                                                    (e) => {
                                                        let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s@._-]/g, '');
                                                        // Prevent leading spaces
                                                        if (cleanedValue.startsWith(' ')) {
                                                            cleanedValue = cleanedValue.trimStart();
                                                        }
                                                        setBusinessName(cleanedValue)
                                                    }}
                                                placeholder='Enter Business Name'
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                            <input
                                                type="text"
                                                value={businessPhone}
                                                onChange={
                                                    (e) => {
                                                        let cleanedValue = e.target.value.replace(/[^0-9\s+]/g, '');
                                                        // Prevent leading spaces
                                                        if (cleanedValue.startsWith(' ')) {
                                                            cleanedValue = cleanedValue.trimStart();
                                                        }
                                                        setBusinessPhone(cleanedValue)
                                                    }}
                                                maxLength={16}
                                                placeholder='Enter Business Phone'
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex flex-col space-y-4 w-full'>
                                        <div className='flex space-x-4 w-full h-full'>
                                            <div className='flex flex-col items-center justify-center w-1/2 h-full border-2 border-gray-300 border-dashed rounded-lg bg-gray-50'>
                                                <img src={base64Image} alt="Business Logo" className='w-full h-64 p-8 object-contain' />
                                            </div>
                                            <div className='flex flex-col space-y-4 w-full'>
                                                <div className='flex items-end justify-between'>
                                                    <p className='text-gray-500 w-1/2'>Full Name</p>
                                                    <h1 className='font-semibold text-end w-full'>{businessName}</h1>
                                                </div>
                                                <hr />
                                                <div className='flex items-end justify-between'>
                                                    <p className='text-gray-500 w-1/2'>Phone</p>
                                                    <h1 className='font-semibold text-end w-full'>{businessPhone}</h1>
                                                </div>
                                                <hr />
                                                <div className='flex items-end justify-between'>
                                                    <p className='text-gray-500 w-1/2'>Email</p>
                                                    <h1 className='font-semibold text-end w-full'>{businessEmail}</h1>
                                                </div>
                                                <hr />
                                                <div className='flex items-end justify-between'>
                                                    <p className='text-gray-500 w-1/2'>Address</p>
                                                    <h1 className='font-semibold text-end w-full'>{businessAddress}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={handleEditButtonClick}
                                            className='px-8 w-full py-4 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200'
                                        >
                                            <i className="fa-regular fa-pen-to-square fa-xl"></i> Edit Information
                                        </button>
                                    </div>
                                )}
                                {editMode && (
                                    <div className='flex flex-col space-y-4'>
                                        <input
                                            type="text"
                                            value={businessEmail}
                                            maxLength={32}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Remove any invalid characters
                                                cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9@._-]/g, '');

                                                // Prevent starting with @
                                                if (cleanedValue.startsWith('@')) {
                                                    cleanedValue = cleanedValue.slice(1);
                                                }

                                                // Ensure there is at most one @ symbol
                                                const atSymbolCount = (cleanedValue.match(/@/g) || []).length;
                                                if (atSymbolCount > 1) {
                                                    // Remove extra @ symbols while keeping the first one
                                                    cleanedValue = cleanedValue.replace(/@/g, (match, offset) => (offset === cleanedValue.indexOf('@') ? match : ''));
                                                }

                                                // Trim leading and trailing spaces
                                                cleanedValue = cleanedValue.trim();

                                                setBusinessEmail(cleanedValue);
                                            }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />

                                        <textarea
                                            value={businessAddress}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Prevent leading spaces
                                                if (cleanedValue.startsWith(' ')) {
                                                    cleanedValue = cleanedValue.trimStart();
                                                }

                                                // Remove any special characters
                                                cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s]/g, '');

                                                setBusinessAddress(cleanedValue);
                                            }}
                                            maxLength={60}
                                            placeholder='Enter Business Address'
                                            className="w-full max-h-[150px] bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                        <button
                                            onClick={updateBuisnessDetails}
                                            className='px-8 w-full py-4 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200'
                                            disabled={loading || imageLoading}
                                        >
                                            {loading ? 'Updating Info...' : 'Update Information'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        !loading && (
                            <div className='flex items-start justify-between space-x-4'>
                                <div className='mt-8 space-y-4 p-2 w-full h-full'>
                                    <div className='flex space-x-4 w-full'>
                                        <div className='w-1/2'>
                                            <label htmlFor="dropzone-file" className="relative flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100" style={{ backgroundImage: `url(${base64Image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                                {imageLoading && (
                                                    <>
                                                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90">
                                                            <div className="w-1/2 h-2 bg-gray-800 rounded">
                                                                <div
                                                                    className="h-full bg-blue-500 rounded"
                                                                    style={{ width: `${uploadProgress}%` }}
                                                                ></div>
                                                            </div>
                                                            <p className="text-xs text-gray-500 mt-2">Uploading: {Math.round(uploadProgress)}%</p>
                                                        </div>
                                                    </>
                                                )}
                                                {!imageLoading && !base64Image && (
                                                    <div className="flex flex-col items-center justify-center">
                                                        <i className="fa-duotone fa-cloud-arrow-up fa-2xl m-4"></i>
                                                        <p className="text-xs text-center text-gray-500 mt-2 font-semibold">Click to upload <br /> your Logo</p>
                                                    </div>
                                                )}
                                                <input id="dropzone-file" type="file" className="hidden" onChange={handleImageUpload} />
                                            </label>
                                            <p className="text-xs text-red-500 mt-2">Click on image to change the image!</p>
                                        </div>
                                        <div className='flex flex-col space-y-4 w-full'>
                                            <input
                                                type="text"
                                                value={businessName}
                                                maxLength={30}
                                                onChange={
                                                    (e) => {
                                                        let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s@._-]/g, '');
                                                        // Prevent leading spaces
                                                        if (cleanedValue.startsWith(' ')) {
                                                            cleanedValue = cleanedValue.trimStart();
                                                        }
                                                        setBusinessName(cleanedValue)
                                                    }}
                                                placeholder='Enter Business Name'
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                            <input
                                                type="text"
                                                maxLength={16}
                                                value={businessPhone}
                                                onChange={
                                                    (e) => {
                                                        let cleanedValue = e.target.value.replace(/[^0-9\s+]/g, '');
                                                        // Prevent leading spaces
                                                        if (cleanedValue.startsWith(' ')) {
                                                            cleanedValue = cleanedValue.trimStart();
                                                        }
                                                        setBusinessPhone(cleanedValue)
                                                    }}
                                                placeholder='Enter Business Phone'
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        value={businessEmail}
                                        maxLength={32}
                                        onChange={(e) => {
                                            let cleanedValue = e.target.value;

                                            // Remove any invalid characters
                                            cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9@._-]/g, '');

                                            // Prevent starting with @
                                            if (cleanedValue.startsWith('@')) {
                                                cleanedValue = cleanedValue.slice(1);
                                            }

                                            // Ensure there is at most one @ symbol
                                            const atSymbolCount = (cleanedValue.match(/@/g) || []).length;
                                            if (atSymbolCount > 1) {
                                                // Remove extra @ symbols while keeping the first one
                                                cleanedValue = cleanedValue.replace(/@/g, (match, offset) => (offset === cleanedValue.indexOf('@') ? match : ''));
                                            }

                                            // Trim leading and trailing spaces
                                            cleanedValue = cleanedValue.trim();

                                            setBusinessEmail(cleanedValue);
                                        }}
                                        placeholder='Enter Business Email'
                                        className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                    <textarea
                                        value={businessAddress}
                                        onChange={(e) => {
                                            let cleanedValue = e.target.value;

                                            // Prevent leading spaces
                                            if (cleanedValue.startsWith(' ')) {
                                                cleanedValue = cleanedValue.trimStart();
                                            }

                                            // Remove any special characters
                                            cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s]/g, '');

                                            setBusinessAddress(cleanedValue);
                                        }}
                                        maxLength={60}
                                        placeholder='Enter Business Address'
                                        className="w-full max-h-[150px] bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                    <button
                                        onClick={sendBuisnessDetails}
                                        className='px-8 w-full py-4 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200'
                                        disabled={loading || imageLoading || editMode}
                                    >
                                        {loading ? 'Adding Info...' : 'Add Information'}
                                    </button>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default BuisnessInfoModal;
