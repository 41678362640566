import React, { useEffect, useState } from 'react';
import { SignedIn, useUser, UserButton } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';

function ClientProtectedRoute(props) {
    const { authURL } = useAuth();
    const { Component } = props;
    const { isSignedIn, user, isLoaded } = useUser();

    useEffect(() => {
        if (isSignedIn && user) {
            sendClientData();
        }
    }, [isSignedIn, user]);

    if (!isLoaded) {
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='loader'></span>
            </div>
        );
    }

    if (isSignedIn) {
        <Navigate to="/home" />;
    } else {
        return <Navigate to="/login" />;
    }

    async function sendClientData() {
        const clientData = {
            fullName: user.fullName,
            email: user.primaryEmailAddress.emailAddress,
        };

        try {
            const response = await fetch(`${authURL}/client-status`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(clientData),
            });

            if (response.status === 409) {
                return console.error('Failed to send client data');
            } else if (response.status === 200) {
                console.log('OK');
            } else {
                console.log("Internal Server Error");
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            <SignedIn>
                <Component />
            </SignedIn>
        </>
    );
}

export default ClientProtectedRoute;
