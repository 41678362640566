import React, { useEffect, useState } from 'react';
import { SignedIn, useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';

function APIsProtectedRoute({ Component }) {
    const { authURL } = useAuth();
    const { isSignedIn, user, isLoaded } = useUser();
    const [status, setStatus] = useState(null); // Default to null for loading state

    useEffect(() => {
        const getStatus = async () => {
            if (isSignedIn && user) {
                try {
                    const response = await fetch(`${authURL}/get-status?email=${user.primaryEmailAddress.emailAddress}`);

                    if (!response.ok) {
                        console.error("Failed to fetch status");
                        return;
                    }

                    const data = await response.json();
                    if (data && data.package) {
                        console.log("API Response:", data.package);
                        setStatus(data.package); // Set the package status
                    } else {
                        console.error("Invalid API response structure");
                    }
                } catch (error) {
                    console.error("Error fetching status:", error);
                }
            }
        };

        getStatus();
    }, [isSignedIn, user, authURL]);

    useEffect(() => {
        console.log("Status updated:", status); // Log when status changes
    }, [status]);

    if (!isLoaded) {
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='loader'></span>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" />;
    }

    if (status === null) { // While loading, show the loader
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='loader'></span>
            </div>
        );
    }

    if (status !== 'pro') { // Redirect to pricing if status is not 'pro'
        return <Navigate to="/pricing" />;
    }

    return (
        <SignedIn>
            <Component />
        </SignedIn>
    );
}

export default APIsProtectedRoute;
