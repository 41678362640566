import React, { useState, useEffect, useRef } from "react";
import { useAuth } from '../../../../../context/data';
import { useLocation, Link } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DOMPurify from 'dompurify';
import toast from "react-hot-toast";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function SalesTemplate() {
    const { authURL } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const QuotesId = queryParams.get('QuotesId');
    const iframeRef = useRef();
    const iframeRefTwo = useRef();

    const [paymentOption, setPaymentOption] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [generating, setGenerating] = useState(false);
    const [invoiceData, setInvoiceData] = useState('');
    const [_id, set_id] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setbusinessPhone] = useState('');
    const [businessEmail, setbusinessEmail] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientName, setclientName] = useState('');
    const [clientPhone, setclientPhone] = useState('');
    const [clientEmail, setclientEmail] = useState('');
    const [clientAddress, setclientAddress] = useState('');
    const [signature, setSignature] = useState('');
    const [signatureLabel, setSignatureLabel] = useState('');
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoiceNote, SetinvoiceNote] = useState('');
    const [termsConditions, settandC] = useState('');
    const [currency, setCurrency] = useState('');
    const [items, setItems] = useState([]);

    const [status, setSelectedStatus] = useState('');

    const handleChange = (event) => {
        setSelectedStatus(event.target.value);
    }

    const fetchData = async () => {
        try {
            const response = await fetch(`${authURL}/get-quotes-receipt?QuotesId=${QuotesId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            const res = await response.json();
            set_id(res.data._id)
            setBase64Image(res.data.base64Image);
            setBusinessName(res.data.businessName);
            setBusinessAddress(res.data.businessAddress);
            setbusinessEmail(res.data.businessEmail);
            setbusinessPhone(res.data.businessPhone);

            setclientName(res.data.clientName);
            setclientPhone(res.data.clientPhone);
            setclientEmail(res.data.clientEmail);
            setclientAddress(res.data.clientAddress);
            setSelectedStatus(res.data.status)
            setInvoiceDate(res.data.QuotesDate);
            setInvoiceTitle(res.data.QuotesTitle)
            setDueDate(res.data.dueDate);
            SetinvoiceNote(res.data.QuotesNote);
            settandC(res.data.termsConditions);
            setItems(res.data.items);
            setCurrency(res.data.currencySymbol);
            setSignature(res.data.signature);
            setSignatureLabel(res.data.signatureLabel);
            console.log("Items from fetched", res.data);
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const calculateTotalItem = (item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const subtotal = quantity * unitPrice;

        const taxPercentage = parseFloat(item.tax) || 0;
        const discountPercentage = parseFloat(item.discount) || 0;

        // Calculate discount and tax on subtotal
        const discount = (discountPercentage / 100) * subtotal;
        const amountAfterDiscount = subtotal - discount;
        const tax = (taxPercentage / 100) * amountAfterDiscount;

        // Total amount including tax and discount
        const total = amountAfterDiscount + tax;
        return total;  // Return as a number
    };

    const calculateSubtotal = () => {
        return items.reduce((acc, item) => acc + (parseFloat(item.quantity) || 0) * (parseFloat(item.unitPrice) || 0), 0);
    };

    const calculateTotalDiscount = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;
            const discountPercentage = parseFloat(item.discount) || 0;
            const discount = (discountPercentage / 100) * subtotal;
            return acc + discount;
        }, 0);
    };

    const calculateTotalTax = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;
            const discountPercentage = parseFloat(item.discount) || 0;
            const taxPercentage = parseFloat(item.tax) || 0;

            const discount = (discountPercentage / 100) * subtotal;
            const amountAfterDiscount = subtotal - discount;
            const tax = (taxPercentage / 100) * amountAfterDiscount;

            return acc + tax;
        }, 0);
    };

    const calculateTotals = (items) => {
        return items.reduce(
            (totals, item) => {
                const quantity = parseFloat(item.quantity) || 0;
                const unitPrice = parseFloat(item.unitPrice) || 0;
                const subtotal = quantity * unitPrice;

                const discountPercentage = parseFloat(item.discount) || 0;
                const discount = (discountPercentage / 100) * subtotal;

                const amountAfterDiscount = subtotal - discount;

                const taxPercentage = parseFloat(item.tax) || 0;
                const tax = (taxPercentage / 100) * amountAfterDiscount;

                totals.subtotal += subtotal;
                totals.discount += discount;
                totals.tax += tax;
                totals.total += amountAfterDiscount + tax;

                return totals;
            },
            { subtotal: 0, tax: 0, discount: 0, total: 0 }
        );
    };

    const calculateTotalAmount = () => {
        return calculateSubtotal() + calculateTotalTax() - calculateTotalDiscount();
    };



    const shouldHideColumn = (columnName) => {
        // Only check for 'discount' and 'tax' columns
        if (columnName === 'discount' || columnName === 'tax') {
            return items.every((item) => !item[columnName]);
        }
        return false;
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (iframeRef.current) {
            const sanitizedHtml = DOMPurify.sanitize(termsConditions);
            const iframeDocument = iframeRef.current.contentDocument;
            iframeDocument.open();
            iframeDocument.write(`
                <html>
                    <head>
                        <style>
                            body {
                                font-family: "Outfit", sans-serif;
                                margin: 0;
                                padding: 10px;
                            }
                        </style>
                    </head>
                    <body>
                        ${sanitizedHtml}
                    </body>
                </html>
            `);
            iframeDocument.close();
        }
    }, [termsConditions]);

    useEffect(() => {
        if (iframeRefTwo.current) {
            const sanitizedHtml = DOMPurify.sanitize(invoiceNote);
            const iframeDocument = iframeRefTwo.current.contentDocument;
            iframeDocument.open();
            iframeDocument.write(`
                <html>
                    <head>
                        <style>
                            body {
                                font-family: "Outfit", sans-serif;
                                margin: 0;
                                padding: 10px;
                            }
                        </style>
                    </head>
                    <body>
                        ${sanitizedHtml}
                    </body>
                </html>
            `);
            iframeDocument.close();
        }
    }, [invoiceNote]);

    const pdfRef = useRef();
    const footerRef = useRef();
    const headerRef = useRef();
    const downloadPDF = async () => {
        setLoading(true);

        const input = pdfRef.current;
        const footerContent = footerRef.current;
        const headerContent = headerRef.current;  // Reference to the header content

        const getIframeContentWithStyles = (iframe) => {
            if (iframe) {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const iframeContent = iframeDoc.body.innerHTML;
                const styles = `
                    <style>
                        body {
                            font-family: "Outfit", sans-serif;
                            margin: 0;
                        }
                        ul {
                            list-style-type: disc;
                        }
                        ul.circle {
                            list-style-type: circle;
                        }
                        ul.square {
                            list-style-type: square;
                        }
                        ol {
                            list-style-type: decimal;
                        }
                        ol.lower-roman {
                            list-style-type: lower-roman;
                        }
                        ol.upper-roman {
                            list-style-type: upper-roman;
                        }
                        ol.lower-alpha {
                            list-style-type: lower-alpha;
                        }
                        ol.upper-alpha {
                            list-style-type: upper-alpha;
                        }
                    </style>
                `;
                return `${styles}${iframeContent}`;
            }
            return '';
        };

        const termsConditionsContent = getIframeContentWithStyles(iframeRef.current);
        const PurchaseNoteContent = getIframeContentWithStyles(iframeRefTwo.current);

        const termsConditionsDiv = document.createElement('div');
        termsConditionsDiv.innerHTML = termsConditionsContent;
        termsConditionsDiv.style.fontFamily = 'Outfit, sans-serif';
        termsConditionsDiv.style.margin = '0';
        termsConditionsDiv.style.padding = '10px';

        const PurchaseNoteDiv = document.createElement('div');
        PurchaseNoteDiv.innerHTML = PurchaseNoteContent;
        PurchaseNoteDiv.style.fontFamily = 'Outfit, sans-serif';
        PurchaseNoteDiv.style.margin = '0';
        PurchaseNoteDiv.style.padding = '10px';

        let iframeRefParent, iframeRefTwoParent;
        if (iframeRef.current) {
            iframeRefParent = iframeRef.current.parentNode;
            iframeRefParent.replaceChild(termsConditionsDiv, iframeRef.current);
        }
        if (iframeRefTwo.current) {
            iframeRefTwoParent = iframeRefTwo.current.parentNode;
            iframeRefTwoParent.replaceChild(PurchaseNoteDiv, iframeRefTwo.current);
        }

        try {
            const mainCanvas = await html2canvas(input, { scale: 4, useCORS: true, logging: true });
            const footerCanvas = await html2canvas(footerContent, { scale: 2, useCORS: true, logging: true });
            const headerCanvas = await html2canvas(headerContent, { scale: 2, useCORS: true, logging: true }); // Capture header

            const mainImgData = mainCanvas.toDataURL('image/jpeg', 0.4);
            const footerImgData = footerCanvas.toDataURL('image/jpeg', 0.4);
            const headerImgData = headerCanvas.toDataURL('image/jpeg', 0.4);

            if (!mainImgData || !footerImgData || !headerImgData) {
                throw new Error("Image data URL is empty.");
            }

            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const topMargin = 5;  // Adjusted for header space
            const bottomMargin = 2;  // Adjusted for footer space

            const mainImgWidth = mainCanvas.width;
            const mainImgHeight = mainCanvas.height;

            const headerHeight = headerCanvas.height * (pdfWidth / headerCanvas.width);
            const footerHeight = footerCanvas.height * (pdfWidth / footerCanvas.width);

            let heightLeft = mainImgHeight;
            let position = 0;

            const mainRatio = pdfWidth / mainImgWidth;
            const pageHeight = (pdfHeight - topMargin - bottomMargin - footerHeight - headerHeight) / mainRatio;

            while (heightLeft > 0) {
                const canvasHeight = Math.min(heightLeft, pageHeight);

                const canvas = document.createElement('canvas');
                canvas.width = mainImgWidth;
                canvas.height = canvasHeight;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(mainCanvas, 0, position, mainImgWidth, canvasHeight, 0, 0, mainImgWidth, canvasHeight);

                const currentImgData = canvas.toDataURL('image/jpeg', 0.4);

                pdf.setFillColor(255, 255, 255);
                pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

                // Draw header
                pdf.addImage(headerImgData, 'JPEG', 0, 0, pdfWidth, headerHeight);

                // Draw main content
                pdf.addImage(
                    currentImgData,
                    'JPEG',
                    0,
                    headerHeight + topMargin,
                    pdfWidth,
                    canvasHeight * mainRatio
                );

                // Draw footer on each page
                const footerYPosition = pdfHeight - footerHeight - bottomMargin;
                pdf.addImage(footerImgData, 'JPEG', 0, footerYPosition, pdfWidth, footerHeight);

                heightLeft -= canvasHeight;
                position += canvasHeight;

                if (heightLeft > 0) {
                    pdf.addPage();
                    position = mainImgHeight - heightLeft;
                }
            }

            pdf.save('Quotes.pdf');
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            if (iframeRefParent && iframeRef.current) {
                iframeRefParent.replaceChild(iframeRef.current, termsConditionsDiv);
            }
            if (iframeRefTwoParent && iframeRefTwo.current) {
                iframeRefTwoParent.replaceChild(iframeRefTwo.current, PurchaseNoteDiv);
            }
            setLoading(false);
        }
    };

    const updateInvoice = async () => {
        const updateData = { status }
        try {
            const response = await fetch(`${authURL}/update-quotes?id=${_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An error occurred');
            }
            const data = await response.json();
            toast.success("Status Updated!");
            return data;
        } catch (error) {
            console.error('Error updating invoice:', error);
            throw error; // Re-throw the error if needed
        }
    };

    const sharePDF = async () => {
        setGenerating(true);
        setStatusMessage('Generating link...');

        const input = pdfRef.current;
        const footerContent = footerRef.current;
        const headerContent = headerRef.current;  // Reference to the header content

        const getIframeContentWithStyles = (iframe) => {
            if (iframe) {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const iframeContent = iframeDoc.body.innerHTML;
                const styles = `
                        <style>
                            body {
                                font-family: "Outfit", sans-serif;
                                margin: 0;
                            }
                            ul {
                                list-style-type: disc;
                            }
                            ul.circle {
                                list-style-type: circle;
                            }
                            ul.square {
                                list-style-type: square;
                            }
                            ol {
                                list-style-type: decimal;
                            }
                            ol.lower-roman {
                                list-style-type: lower-roman;
                            }
                            ol.upper-roman {
                                list-style-type: upper-roman;
                            }
                            ol.lower-alpha {
                                list-style-type: lower-alpha;
                            }
                            ol.upper-alpha {
                                list-style-type: upper-alpha;
                            }
                        </style>
                    `;
                return `${styles}${iframeContent}`;
            }
            return '';
        };

        const termsConditionsContent = getIframeContentWithStyles(iframeRef.current);
        const PurchaseNoteContent = getIframeContentWithStyles(iframeRefTwo.current);

        const termsConditionsDiv = document.createElement('div');
        termsConditionsDiv.innerHTML = termsConditionsContent;
        termsConditionsDiv.style.fontFamily = 'Outfit, sans-serif';
        termsConditionsDiv.style.margin = '0';
        termsConditionsDiv.style.padding = '10px';

        const PurchaseNoteDiv = document.createElement('div');
        PurchaseNoteDiv.innerHTML = PurchaseNoteContent;
        PurchaseNoteDiv.style.fontFamily = 'Outfit, sans-serif';
        PurchaseNoteDiv.style.margin = '0';
        PurchaseNoteDiv.style.padding = '10px';

        let iframeRefParent, iframeRefTwoParent;
        if (iframeRef.current) {
            iframeRefParent = iframeRef.current.parentNode;
            iframeRefParent.replaceChild(termsConditionsDiv, iframeRef.current);
        }
        if (iframeRefTwo.current) {
            iframeRefTwoParent = iframeRefTwo.current.parentNode;
            iframeRefTwoParent.replaceChild(PurchaseNoteDiv, iframeRefTwo.current);
        }

        try {
            const mainCanvas = await html2canvas(input, { scale: 4, useCORS: true, logging: true });
            const footerCanvas = await html2canvas(footerContent, { scale: 2, useCORS: true, logging: true });
            const headerCanvas = await html2canvas(headerContent, { scale: 2, useCORS: true, logging: true }); // Capture header

            const mainImgData = mainCanvas.toDataURL('image/jpeg', 0.4);
            const footerImgData = footerCanvas.toDataURL('image/jpeg', 0.4);
            const headerImgData = headerCanvas.toDataURL('image/jpeg', 0.4);

            if (!mainImgData || !footerImgData || !headerImgData) {
                throw new Error("Image data URL is empty.");
            }

            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const topMargin = 5;  // Adjusted for header space
            const bottomMargin = 2;  // Adjusted for footer space

            const mainImgWidth = mainCanvas.width;
            const mainImgHeight = mainCanvas.height;

            const headerHeight = headerCanvas.height * (pdfWidth / headerCanvas.width);
            const footerHeight = footerCanvas.height * (pdfWidth / footerCanvas.width);

            let heightLeft = mainImgHeight;
            let position = 0;

            const mainRatio = pdfWidth / mainImgWidth;
            const pageHeight = (pdfHeight - topMargin - bottomMargin - footerHeight - headerHeight) / mainRatio;

            while (heightLeft > 0) {
                const canvasHeight = Math.min(heightLeft, pageHeight);

                const canvas = document.createElement('canvas');
                canvas.width = mainImgWidth;
                canvas.height = canvasHeight;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(mainCanvas, 0, position, mainImgWidth, canvasHeight, 0, 0, mainImgWidth, canvasHeight);

                const currentImgData = canvas.toDataURL('image/jpeg', 0.4);

                pdf.setFillColor(255, 255, 255);
                pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

                // Draw header
                pdf.addImage(headerImgData, 'JPEG', 0, 0, pdfWidth, headerHeight);

                // Draw main content
                pdf.addImage(
                    currentImgData,
                    'JPEG',
                    0,
                    headerHeight + topMargin,
                    pdfWidth,
                    canvasHeight * mainRatio
                );

                // Draw footer on each page
                const footerYPosition = pdfHeight - footerHeight - bottomMargin;
                pdf.addImage(footerImgData, 'JPEG', 0, footerYPosition, pdfWidth, footerHeight);

                heightLeft -= canvasHeight;
                position += canvasHeight;

                if (heightLeft > 0) {
                    pdf.addPage();
                    position = mainImgHeight - heightLeft;
                }
            }


            const pdfBlob = pdf.output('blob');

            // Upload to Firebase
            const storage = getStorage();
            const storageRef = ref(storage, `reciepts/quotes_${QuotesId}.pdf`);
            const uploadResult = await uploadBytes(storageRef, pdfBlob);

            // Get the download URL
            const downloadURL = await getDownloadURL(uploadResult.ref);
            console.log("Firebase file link:", downloadURL);

            // Copy to clipboard and show alert
            navigator.clipboard.writeText(downloadURL).then(() => {
                toast('File link copied to clipboard!', {
                    icon: '📋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                });
            }).catch((err) => {
                console.error("Failed to copy to clipboard: ", err);
            });

            setStatusMessage('Link generated successfully!');
        } catch (error) {
            console.error("Error generating PDF:", error);
            setStatusMessage('Error generating link.');
        } finally {
            if (iframeRefParent && iframeRef.current) {
                iframeRefParent.replaceChild(iframeRef.current, termsConditionsDiv);
            }
            if (iframeRefTwoParent && iframeRefTwo.current) {
                iframeRefTwoParent.replaceChild(iframeRefTwo.current, PurchaseNoteDiv);
            }
            setGenerating(false);
        }
    };

    return (
        <div className="my-32">
            <div className="flex items-end justify-between space-x-4 mb-8">

                <div className="flex flex-col">
                    <label className="leading-7 text-gray-600 mr-2">Quote Reciept Status</label>
                    <div className="space-x-3">
                        <select value={status} onChange={handleChange} className="w-[20rem] h-[2.5rem] bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                            <option value="noStatus">Select Quote Reciept Status</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Un-Paid</option>
                            <option value="failed">Failed</option>
                        </select>
                        <button onClick={updateInvoice} className="px-4 py-2.5 text-sm rounded bg-gradient-to-b from-orange-500 to-orange-600 text-white focus:ring-2 focus:ring-orange-400 hover:shadow-xl transition duration-200">
                            Update Status
                        </button>
                    </div>
                </div>


                <div className="space-x-4">
                    <button
                        onClick={sharePDF}
                        className='px-8 py-2 rounded border border-slate-300  text-blue-600 focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200'
                        disabled={generating}
                    >
                        {generating ? 'Generating Link...' : <span> <i class="fa-duotone fa-solid fa-share-from-square mr-2"></i>Share</span>}
                    </button>


                    <Link to={`/home/generate-docx/quotes-form/update-quotes?_id=${_id}`} className=" h-full px-4 py-2.5 rounded bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-green-400 hover:shadow-xl transition duration-200">
                        <i className="fa-light fa-pen text-white mr-2"></i>Update Quotes Reciept
                    </Link>

                    <button
                        onClick={downloadPDF}
                        className="h-full px-4 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? (
                            <span>Downloading...</span> // Show loading text while generating PDF
                        ) : (
                            <>
                                <i className="fa-light fa-download text-white mr-2"></i>Download Reciept
                            </>
                        )}
                    </button>
                </div>

            </div>

            <div className="max-w-7xl mx-auto bg-white lg p-8 h-full border-2">
                <div ref={headerRef} className="max-w-7xl mx-auto bg-white px-8 pt-8 h-full">
                    <div className="topDiv flex items-start justify-between w-full space-x-4">
                        <div className="flex w-full flex-col">

                            {base64Image && (
                                <div className="flex items-center justify-center w-full min-h-[8rem] max-h-[10rem] min-w-[14rem] max-w-[16rem] overflow-hidden">
                                    <img src={base64Image} alt="Business" className="object-fill h-auto w-auto max-h-full max-w-full" />
                                </div>
                            )}

                            {/* Business Details */}
                            <div className="w-full mt-4">
                                <h1 className="text-3xl font-semibold">{businessName}</h1>
                                <div className="flex items-center justify-start space-x-4">
                                    <p className="max-w-lg text-xl">{businessEmail}</p>
                                    <p>|</p>
                                    <p className="max-w-lg text-xl">{businessPhone}</p>
                                </div>
                                <p className="max-w-lg text-xl">{businessAddress}</p>
                            </div>
                        </div>

                        <div className="flex flex-col items-end justify-end w-1/2">
                            <h1 className="text-[2.6rem] uppercase tracking-wide mb-2">Quote Reciept</h1>
                            <div className="flex items-center justify-center">
                                <code className="text-gray-800  text-2xl rounded-full font-semibold mt-2"># {QuotesId}</code>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4 border-t-2 rounded-full" />
                </div>
                <div ref={pdfRef} className="max-w-7xl mx-auto bg-white px-8  h-full">

                    <div className="flex w-full space-x-4">

                        <div className="w-full">
                            <h4 className="leading-7 text-gray-600">Client Detail</h4>
                            <div>
                                <p className="text-xl font-semibold">{clientName}</p>
                                <p className="text-lg">{clientEmail}</p>
                                <p className="text-lg">{clientPhone}</p>
                                <p className="space-y-4 text-lg">
                                    {clientAddress}
                                </p>
                            </div>
                        </div>

                        <div className="w-1/3 flex flex-col items-end justify-start space-y-4">
                            <dl className="flex space-x-4">
                                <dt className="col-span-3 font-semibold text-lg text-gray-800">Reciept Date:</dt>
                                <dd className="col-span-2 text-lg text-gray-500">{invoiceDate}</dd>
                            </dl>
                            
                            <dl className="flex space-x-4">
                                <dt className="col-span-3 text-lg font-semibold text-gray-800">Payment Option:</dt>
                                <dd className="col-span-2 text-lg">{dueDate}</dd>
                            </dl>
                            <dl className="flex space-x-4">
                                <dt className="col-span-3 text-lg font-semibold text-gray-800">Status:</dt>
                                <dd className="col-span-2 text-lg capitalize">{status}</dd>
                            </dl>
                        </div>

                    </div>

                    <div className="flex w-full space-x-4 mt-8">
                        <div className="w-full overflow-x-auto">
                            <table className="w-full text-left border border-separate rounded border-slate-200" cellSpacing="2">
                                <thead>
                                    <tr>
                                        <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Sr#</th>
                                        <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Product Name</th>
                                        <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Quantity</th>
                                        <th className={`h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100 ${shouldHideColumn("discount") && "hidden"}`}>Discount <span className="text-red-600 font-semibold">%</span></th>
                                        <th className={`h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100 ${shouldHideColumn("tax") && "hidden"}`}>Tax <span className="text-red-600 font-semibold">%</span></th>
                                        <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Unit Price</th>
                                        <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((product, index) => {
                                        const total = calculateTotalItem(product);
                                        return (
                                            <tr key={index}>
                                                <td className="cellTwo h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">{index + 1}</td>
                                                <td className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">{product.productName}</td>
                                                <td className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">{product.quantity}</td>
                                                <td className={`h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white ${shouldHideColumn("discount") && "hidden"}`}>
                                                    {product.discount ? `${parseFloat(product.discount).toFixed(2)} %` : 'N/A'}
                                                </td>
                                                <td className={`h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white ${shouldHideColumn("tax") && "hidden"}`}>
                                                    {product.tax ? `${parseFloat(product.tax).toFixed(2)} %` : 'N/A'}
                                                </td>
                                                <td className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">
                                                    {parseFloat(product.unitPrice).toFixed(2)} {currency}
                                                </td>
                                                <td className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">
                                                    {total.toFixed(2)} {currency}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={`mt-10 w-full flex items-start justify-between  ${items.length == 15 || items.length === 40 || items.length === 64 || items.length === 61 ? "mt-2" : ""} ${items.length === 16 || items.length === 17 || items.length === 41 || items.length === 42 || items.length === 65 || items.length === 66 ? "mt-44" : ""} ${items.length === 18 || items.length === 67 || items.length === 43 ? "mt-32" : ""}`}>

                        <div className="flex items-start justify-start flex-col w-1/2">
                            {termsConditions && (
                                <div className="w-full text-start">
                                    <h4 className="text-lg text-gray-800 underline">Terms & Conditions</h4>
                                    <iframe ref={iframeRef} className="w-full h-full border-2" />
                                </div>
                            )}

                            {invoiceNote && (
                                <div className="w-full text-start">
                                    <h4 className="text-lg text-gray-800 underline">Sales Reciept Note</h4>
                                    <iframe ref={iframeRefTwo} className="w-full h-full border-2" />
                                </div>
                            )}
                        </div>

                        <div className="max-w-xs w-full space-y-2 text-right mt-8">
                            <div className="flex w-full items-center text-lg justify-between text-slate-700">
                                <p>Subtotal:</p>
                                <p className="text-lg">{calculateSubtotal().toFixed(2)} {currency}</p>
                            </div>
                            {!shouldHideColumn("discount") && (
                                <div className="flex w-full items-center text-lg justify-between text-slate-700">
                                    <p>Total Discount:</p>
                                    <p className="text-emerald-500 text-xl font-semibold">-{calculateTotalDiscount().toFixed(2)} {currency}</p>
                                </div>
                            )}
                            {!shouldHideColumn("tax") && (
                                <div className="flex w-full items-center text-lg justify-between text-slate-700">
                                    <p>Total Tax:</p>
                                    <p className="text-red-500 text-xl font-semibold">+{calculateTotalTax().toFixed(2)} {currency}</p>
                                </div>
                            )}
                            <hr className="border-t-2 border-slate-200 mt-2 mb-2" />
                            <div className="flex items-center justify-between w-full text-slate-700">
                                <p className="text-lg">Total:</p>
                                <code className="text-2xl font-semibold">{calculateTotals(items).total.toFixed(2)} {currency}</code>
                            </div>
                        </div>
                    </div>



                    <div className={`h-32 mt-8 w-full flex items-end justify-end ${items.length === 13 || items.length === 14 || items.length === 15 || items.length === 37 || items.length === 38 || items.length === 39 || items.length === 62 || items.length === 63 || items.length === 64 ? "mt-48" : ""}`}>
                        {signature && (
                            <div className="flex flex-col items-center justify-end p-2">
                                <img src={signature} alt="signature" style={{ width: '200px', height: '100px' }} />
                                <div className="mt-4 border-t-2 border-gray-600 w-full">
                                    <center>{signatureLabel}</center>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div >
                    <footer ref={footerRef} className="max-w-7xl mx-auto bg-white lg px-8 pb-8 pt-3 h-full">
                        <hr className=" border-t-2 rounded-full" />

                        <div class="mt-2 flex-grow-1">
                            <h4 class="text-lg font-semibold text-gray-800  ">Thank you!</h4>
                            <p class="text-gray-500">
                                This Quote reciept is generated by <span className="text-blue-600 underline">www.quickdoc.app</span> <br /> If you have any questions concerning this invoice, use the following
                                contact information:
                            </p>
                            <div class="mt-2">
                                <p class="block text-sm font-medium text-gray-800  ">
                                    info@bzsoftech.com
                                </p>
                                <p class="block text-sm font-medium text-gray-800  ">
                                    +92 3217678097
                                </p>
                            </div>
                        </div>
                    </footer>

                </div>
            </div>
        </div>
    );
}

export default SalesTemplate;
