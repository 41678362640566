import React, { useState } from "react";
import {
  ArrowUpRight,
  Split,
  Merge,
  FileJson,
  FileText,
  ScanLine,
  KeyRound,
} from "lucide-react";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Oracle Base Product",
    description: "Streamline your Oracle APEX applications with enhanced database performance and management features.",
    icon: Split,
    bgColor: "from-indigo-600 to-blue-500",
    accentColor: "indigo",
  },
  {
    title: "Directory Management System",
    description: "Manage user directories and roles efficiently, ensuring secure access and permissions within your applications.",
    icon: Merge,
    bgColor: "from-emerald-600 to-teal-500",
    accentColor: "emerald",
  },
  {
    title: "User Interface Design",
    description: "Create intuitive and responsive interfaces for seamless user interactions in your Oracle APEX applications.",
    icon: FileJson,
    bgColor: "from-amber-600 to-orange-500",
    accentColor: "amber",
  },
  {
    title: "Access Control Management",
    description: "Implement robust rights management and security measures to protect your application data effectively.",
    icon: KeyRound,
    bgColor: "from-purple-600 to-pink-500",
    accentColor: "purple",
  },
  {
    title: "Data Operations Management",
    description: "Effortlessly perform Create, Read, Update, and Delete operations to manage your data within Oracle APEX.",
    icon: FileText,
    bgColor: "from-red-600 to-rose-500",
    accentColor: "red",
  },
  {
    title: "Integration Services",
    description: "Seamlessly integrate with external systems and scanners for enhanced data capture and management capabilities.",
    icon: ScanLine,
    bgColor: "from-cyan-600 to-sky-500",
    accentColor: "cyan",
  },
];

const ServiceCard = ({ title, description, icon: Icon, bgColor, accentColor }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`relative overflow-hidden bg-white rounded-2xl shadow-lg transition-all duration-300 ease-in-out transform ${
        isHovered ? "scale-105 shadow-2xl" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`absolute inset-0 bg-gradient-to-br ${bgColor} opacity-10`}></div>
      <div className="relative p-8 flex flex-col h-full">
        <div className={`w-16 h-16 mb-6 rounded-full bg-gradient-to-br ${bgColor} flex items-center justify-center transition-all duration-300 ${
          isHovered ? "scale-110" : ""
        }`}>
          <Icon className="w-8 h-8 text-white" />
        </div>
        <h3 className={`text-2xl font-bold mb-4 text-gray-800`}>{title}</h3>
        <p className="text-gray-600 mb-6 flex-grow">{description}</p>
        <Link
          to="/contact-us"
          className={`w-full py-3 px-6 rounded-full font-semibold text-sm uppercase tracking-wider transition-all duration-300 flex items-center justify-center
            ${isHovered ? `bg-${accentColor}-600 text-blue` : `bg-${accentColor}-800 text-${accentColor}-800 border-2 border-${accentColor}-600`}
            hover:shadow-lg mt-auto`}
        >
          Integrate Now
          <ArrowUpRight className={`ml-2 h-4 w-4 transition-transform duration-300 ${
            isHovered ? "transform translate-x-1 -translate-y-1" : ""
          }`} />
        </Link>
      </div>
      <div className={`absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r ${bgColor}`}></div>
    </div>
  );
};

const Services = () => {
  return (
    <div className=" min-h-screen">
      <div className="container mx-auto px-4 py-24">
        <h2 className="text-5xl font-bold text-center mb-4 bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
          Our DMS & CRM Services
        </h2>
        <p className="text-xl text-center mb-16 text-gray-600 max-w-3xl mx-auto">
          Seamless DMS, CRM, and Oracle APEX Integration – Your Gateway to Success.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;