import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/data";
import {
  Mail,
  Search,
  Trash2,
  ChevronLeft,
  ChevronRight,
  Inbox,
  Paperclip,
  Reply,
  Star,
} from "lucide-react";

function Leads() {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedLeads, setCheckedLeads] = useState({});
  const { authURL } = useAuth();

  const fetchData = async () => {
    try {
      const response = await fetch(`${authURL}/mail-list`);
      if (!response.ok) throw new Error("Network response was not ok");
      const res = await response.json();
      const newLeads = res.data.map((lead) => ({
        ...lead,
        id: lead._id || Math.random().toString(36).substr(2, 9),
        date: new Date(lead.date || Date.now()).toISOString(),
      }));
      newLeads.sort((a, b) => new Date(b.date) - new Date(a.date));
      setLeads(newLeads);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLeadClick = (lead) => setSelectedLead(lead);

  const toggleCheckLead = (leadId, e) => {
    e.stopPropagation();
    setCheckedLeads((prev) => ({ ...prev, [leadId]: !prev[leadId] }));
  };

  const handleCheckAll = () => {
    const allChecked = leads.every((lead) => checkedLeads[lead.id]);
    const newCheckedLeads = {};
    leads.forEach((lead) => {
      newCheckedLeads[lead.id] = !allChecked;
    });
    setCheckedLeads(newCheckedLeads);
  };

  const deleteLead = async (leadId) => {
    try {
      const response = await fetch(`${authURL}/delete-mail?id=${leadId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete lead");
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  };

  const handleDelete = async () => {
    const idsToDelete = Object.keys(checkedLeads).filter(
      (leadId) => checkedLeads[leadId]
    );
    for (const leadId of idsToDelete) {
      await deleteLead(leadId);
    }
    const newLeads = leads.filter((lead) => !checkedLeads[lead.id]);
    setLeads(newLeads);
    setCheckedLeads({});
    if (selectedLead && checkedLeads[selectedLead.id]) {
      setSelectedLead(null);
    }
  };

  const filteredLeads = leads.filter(
    (lead) =>
      lead.FirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.LastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.Email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNextLead = () => {
    const currentIndex = leads.findIndex((lead) => lead.id === selectedLead.id);
    if (currentIndex < leads.length - 1) {
      setSelectedLead(leads[currentIndex + 1]);
    }
  };

  const handlePreviousLead = () => {
    const currentIndex = leads.findIndex((lead) => lead.id === selectedLead.id);
    if (currentIndex > 0) {
      setSelectedLead(leads[currentIndex - 1]);
    }
  };
  const handleReply = (email) => {
    const gmailComposeURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      email
    )}`;
    window.open(gmailComposeURL, "_blank");
  };
  return (
    <div className="container mx-auto  h-screen flex flex-col bg-gray-100">
      <div className="bg-white shadow-md rounded-t-lg p-4 flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-800 flex items-center">
          <Inbox className="w-6 h-6 mr-2 text-blue-500" /> Leads Inbox
        </h1>
        <div className="flex items-center">
          <div className="relative mr-4">
            <input
              type="text"
              placeholder="Search leads..."
              className="pl-10 pr-4 py-2 border rounded-full w-64 focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md flex items-center transition duration-150 ease-in-out"
            onClick={handleDelete}
            disabled={Object.values(checkedLeads).every((v) => !v)}
          >
            <Trash2 className="w-4 h-4 mr-2" /> Delete
          </button>
        </div>
      </div>
      <div className="flex flex-grow overflow-hidden bg-white rounded-b-lg shadow-lg">
        {/* Leads List */}
        <div className="w-2/5 border-r overflow-y-auto">
          <div className="sticky top-0 bg-gray-100 p-3 border-b">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600 rounded"
                checked={
                  leads.length > 0 &&
                  leads.every((lead) => checkedLeads[lead.id])
                }
                onChange={handleCheckAll}
              />
              <span className="ml-2 text-gray-700 font-medium">Select All</span>
            </label>
          </div>
          {filteredLeads.map((lead) => (
            <div
              key={lead.id}
              className={`p-4 border-b cursor-pointer hover:bg-gray-100 transition duration-150 ease-in-out ${
                selectedLead === lead ? "bg-blue-50" : ""
              }`}
              onClick={() => handleLeadClick(lead)}
            >
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600 rounded mr-3"
                  checked={checkedLeads[lead.id] || false}
                  onChange={(e) => toggleCheckLead(lead.id, e)}
                />
                <div className="flex-grow">
                  <h2 className="font-semibold text-gray-800">
                    {lead.FirstName} {lead.LastName}
                  </h2>
                  <p className="text-sm text-gray-600 truncate">
                    {lead.Message}
                  </p>
                </div>
                <p className="text-xs text-gray-400 whitespace-nowrap ml-2">
                  {new Date(lead.date).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* Lead Detail View */}
        {selectedLead ? (
          <div className="w-3/5 overflow-y-auto flex flex-col">
            <div className="p-6 border-b">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">
                  {selectedLead.FirstName} {selectedLead.LastName}
                </h2>
                <div className="flex items-center">
                  <button className="text-gray-500 hover:text-yellow-500 p-1 rounded transition duration-150 ease-in-out mr-2">
                    <Star className="w-5 h-5" />
                  </button>
                  <button
                    className="text-gray-500 hover:text-blue-500 p-1 rounded transition duration-150 ease-in-out mr-2"
                    onClick={() => handleReply(selectedLead.Email)}
                  >
                    <Reply className="w-5 h-5" />
                  </button>
                  <button
                    className="text-gray-500 hover:text-gray-700 p-1 rounded transition duration-150 ease-in-out"
                    onClick={handlePreviousLead}
                  >
                    <ChevronLeft className="w-5 h-5" />
                  </button>
                  <button
                    className="text-gray-500 hover:text-gray-700 p-1 rounded transition duration-150 ease-in-out"
                    onClick={handleNextLead}
                  >
                    <ChevronRight className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <Mail className="w-4 h-4 mr-2" />
                <span className="font-medium">{selectedLead.Email}</span>
              </div>
              <div className="flex items-center justify-between text-sm text-gray-500">
                <p>
                  To:{" "}
                  <span className="font-medium">Quickdoc Customer Support</span>
                </p>
                <p>
                  {new Date(selectedLead.date).toLocaleString([], {
                    dateStyle: "long",
                    timeStyle: "short",
                  })}
                </p>
              </div>
              {(selectedLead.category || selectedLead.project) && (
                <div className="mt-3 flex flex-wrap">
                  {selectedLead.category && (
                    <>
                      <p className="text-xs font-medium mr-2 my-auto">
                        Requested demo
                      </p>
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                        Category:{selectedLead.category}
                      </span>
                    </>
                  )}
                  {selectedLead.project && (
                    <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                      Project:{selectedLead.project}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="p-6 flex-grow">
              <div className="bg-white rounded-lg">
                <p className="text-gray-800 whitespace-pre-wrap">
                  Message:{selectedLead.Message}
                </p>
              </div>
            </div>
            <div className="p-6 border-t">
              <div className="flex items-center text-sm text-gray-500">
                <Paperclip className="w-4 h-4 mr-2" />
                <span>No attachments</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-3/5 flex items-center justify-center text-gray-500">
            Select a lead to view details
          </div>
        )}
      </div>
    </div>
  );
}

export default Leads;
