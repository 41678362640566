import React, { useState, useEffect, useRef } from "react";
import { useAuth } from '../../../../../context/data';
import { useLocation, useNavigate } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useUser } from "@clerk/clerk-react";
import DOMPurify from 'dompurify';
import BuisnessInfoModal from "../Windows/BuisnessInfoModal";
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditSales() {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('_id');
    const iframeRef = useRef(null);
    const iframeRefTwo = useRef(null);
    const pdfRef = useRef(null);

    const [buisImage, setBuisImage] = useState("");
    const [busName, setBusName] = useState("");
    const [busiPhone, setBusiPhone] = useState("");
    const [busiEmail, setBusiEmail] = useState("");
    const [busiAddress, setBusiAddress] = useState("");

    const [invoiceData, setInvoiceData] = useState(null);
    const [invoiceId, setInvoiceId] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessEmail, setBusinessEmail] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientName, setClientName] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoiceNote, setInvoiceNote] = useState('');
    const [termsConditions, setTermsConditions] = useState('');
    const [currency, setCurrency] = useState('');
    const [items, setItems] = useState([]);
    const [editMode, setEditMode] = useState(false);

    // ! Use States for Currency Data ===============================================
    const [currencySymbol, setCurrencySymbol] = useState("$");
    const currencies = ["Rs", "$", "€", "£", "¥"];

    const addItemRow = () => {
        // Create a new item object with default values
        const newItem = {
            productName: "",
            quantity: 0,
            unitPrice: 0,
            tax: 0,
            discount: 0,
            total: 0,
        };
        // Add the new item to the items array
        setItems([...items, newItem]);
    };

    const fetchBuisnessData = async () => {
        try {
            const response = await fetch(
                `${authURL}/get-buisness-info?userID=${user.primaryEmailAddress.emailAddress}`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const res = await response.json();
            setBuisImage(res.data.base64Image);
            setBusName(res.data.businessName);
            setBusiAddress(res.data.businessAddress);
            setBusiEmail(res.data.businessEmail);
            setBusiPhone(res.data.businessPhone);
        } catch (error) {
            console.error('Error fetching Buisness data:', error);
        }
    };

    useEffect(() => {
        fetchBuisnessData();
    }, [fetchBuisnessData]);

    const [rows, setRows] = useState([{
        productName: "",
        quantity: 0,
        discount: 0,
        tax: 0,
        unitPrice: 0,
        total: 0,
    }]);

    const addRow = () => {
        setRows([...rows, {
            productName: "",
            quantity: 0,
            discount: 0,
            tax: 0,
            unitPrice: 0,
            total: 0,
        }]);
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${authURL}/get-quotes-data-for-update?id=${id}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const res = await response.json();

            // Ensure fetched data does not overwrite user input
            if (!invoiceData) {
                setInvoiceId(res.data.QuotesId);
                setBase64Image(res.data.base64Image);
                setBusinessName(res.data.businessName);
                setBusinessAddress(res.data.businessAddress);
                setBusinessEmail(res.data.businessEmail);
                setBusinessPhone(res.data.businessPhone);

                setClientName(res.data.clientName);
                setClientPhone(res.data.clientPhone);
                setClientEmail(res.data.clientEmail);
                setClientAddress(res.data.clientAddress);

                setInvoiceTitle(res.data.QuotesTitle);
                setInvoiceDate(res.data.QuotesDate);
                setDueDate(res.data.dueDate);
                setInvoiceNote(res.data.QuotesNote);
                setTermsConditions(res.data.termsConditions);
                setCurrency(res.data.currencySymbol);

                setItems(res.data.items);
                setInvoiceData(res.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchBuisnessData();
    }, [fetchBuisnessData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Use this useEffect to handle setting iframe content
    useEffect(() => {
        if (iframeRef.current) {
            const sanitizedHtml = DOMPurify.sanitize(termsConditions);
            const iframeDocument = iframeRef.current.contentDocument;
            iframeDocument.open();
            iframeDocument.write(`
                <html>
                    <head>
                        <style>
                            body {
                                font-family: "Outfit", sans-serif;
                                margin: 0;
                                padding: 10px;
                            }
                        </style>
                    </head>
                    <body>
                        ${sanitizedHtml}
                    </body>
                </html>
            `);
            iframeDocument.close();
        }
    }, [termsConditions]);

    useEffect(() => {
        if (iframeRefTwo.current) {
            const sanitizedHtml = DOMPurify.sanitize(invoiceNote);
            const iframeDocument = iframeRefTwo.current.contentDocument;
            iframeDocument.open();
            iframeDocument.write(`
                <html>
                    <head>
                        <style>
                            body {
                                font-family: "Outfit", sans-serif;
                                margin: 0;
                                padding: 10px;
                            }
                        </style>
                    </head>
                    <body>
                        ${sanitizedHtml}
                    </body>
                </html>
            `);
            iframeDocument.close();
        }
    }, [invoiceNote]);


    const deleteRow = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const calculateTotalItem = (item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const subtotal = quantity * unitPrice;

        // Calculate the discount
        const discountPercentage = parseFloat(item.discount) || 0;
        const discount = (discountPercentage / 100) * subtotal;
        const discountedSubtotal = subtotal - discount;

        // Calculate the tax on the discounted subtotal
        const taxPercentage = parseFloat(item.tax) || 0;
        const tax = (taxPercentage / 100) * discountedSubtotal;

        // The total is the discounted subtotal plus the tax
        const total = discountedSubtotal + tax;

        return total;
    };


    const calculateSubtotal = () => {
        return items.reduce((acc, item) => acc + (item.quantity * item.unitPrice), 0);
    };

    const calculateTotalDiscount = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;

            // Calculate the discount
            const discountPercentage = parseFloat(item.discount) || 0;
            const discount = (discountPercentage / 100) * subtotal;

            return acc + discount;
        }, 0);
    };


    const calculateTotalTax = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;

            // Calculate the discount
            const discountPercentage = parseFloat(item.discount) || 0;
            const discount = (discountPercentage / 100) * subtotal;
            const discountedSubtotal = subtotal - discount;

            // Calculate the tax on the discounted subtotal
            const taxPercentage = parseFloat(item.tax) || 0;
            const tax = (taxPercentage / 100) * discountedSubtotal;

            return acc + tax;
        }, 0);
    };


    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const totalDiscount = calculateTotalDiscount();
        const totalTax = calculateTotalTax();

        // The total is the subtotal minus the total discount plus the total tax
        return subtotal - totalDiscount + totalTax;
    };

    const handleInputChange = (index, field, value) => {
        const numericValue = field === 'productName' ? value : parseFloat(value) || 0;

        const updatedItems = [...items];
        updatedItems[index] = {
            ...updatedItems[index],
            [field]: numericValue,
        };

        updatedItems[index].total = calculateTotalItem(updatedItems[index]);

        const updatedItemsWithTotals = updatedItems.map(item => ({
            ...item,
            total: calculateTotalItem(item),
        }));

        setItems(updatedItemsWithTotals);
    };

    const validateItems = () => {
        let hasTaxErrors = false;
        let hasDiscountErrors = false;
        let hasUnitPriceError = false;
        let hasQuantityError = false;
        let hasProductNameError = false;

        items.forEach(item => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;

            if (!quantity || quantity === 0) {
                hasQuantityError = true;
                toast.error("Quantity must be 1 or greater")
            }
            if (!item.productName || item.productName.trim() === '') {
                hasProductNameError = true;
                toast.error("Product Name is required")
            }

            if (!unitPrice || unitPrice === 0) {
                hasUnitPriceError = true;
                toast.error("Unit Price must be 1 or greater")
            }

            const discountPercentage = parseFloat(item.discount) || 0;
            const discount = (discountPercentage / 100) * subtotal;
            const discountedSubtotal = subtotal - discount;

            const taxPercentage = parseFloat(item.tax) || 0;
            const tax = (taxPercentage / 100) * discountedSubtotal;

            if (tax > discountedSubtotal) {
                hasTaxErrors = true;
                toast.error(`Tax for item ${item.productName} cannot be greater than the discounted subtotal of ${discountedSubtotal}.`);
            }

            if (discount > subtotal) {
                hasDiscountErrors = true;
                toast.error(`Discount for item ${item.productName} cannot be greater than the subtotal of ${subtotal}.`);
            }
        });

        return !(hasTaxErrors || hasDiscountErrors || hasQuantityError || hasUnitPriceError || hasProductNameError);
    };

    const shouldHideColumn = (columnName) => {
        // Only check for 'discount' and 'tax' columns
        if (columnName === 'discount' || columnName === 'tax') {
            return items.every((item) => !item[columnName]);
        }
        return false;
    };

    const handleSaveChanges = async () => {

        if (!validateItems()) {
            return; // Prevent saving if validation fails
        }

        if (!clientName) {
            return toast.error("Client Name is Required.");
        }

        if (!invoiceTitle) {
            return toast.error("Reciept Title is Required.");
        }

        if (!invoiceDate) {
            return toast.error("Reciept Date is required.");
        }

        const updatedQuotesData = {
            QuotesId: invoiceId,
            base64Image: buisImage,
            businessName: busName,
            businessAddress: busiAddress,
            businessEmail: busiEmail,
            businessPhone: busiPhone,
            clientName,
            clientPhone,
            clientEmail,
            clientAddress,
            QuotesTitle: invoiceTitle,
            QuotesDate: invoiceDate,
            dueDate: dueDate,
            currencySymbol,
            QuotesNote: invoiceNote,
            termsConditions,
            currency,
            items
        };

        try {
            const response = await fetch(`${authURL}/update-quotes?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedQuotesData),
            });
            if (!response.ok) {
                // Log the response status and body
                const errorText = await response.text(); // Retrieve response body as text
                console.error('Response error:', response.status, errorText);
                toast.error('Failed to update Quotes');
                throw new Error(`Server responded with status ${response.status}`);
            }
            const result = await response.json();
            console.log("Updated Quotes Data:", result.data);
            toast.success('Updated Successfully');
            navigate(`/home/generate-docx/quotes-form/quotes-template?QuotesId=${invoiceId}`);
            setEditMode(false);
            fetchData();
            return result.data;
        } catch (error) {
            console.error('Error updating Quotes:', error);
            toast.error('Failed to update Quotes');
            throw error;
        }
    };


    return (
        <div className="my-32">
            <div className="flex items-start justify-between space-x-4 my-4">
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Update Quote Reciept
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Precision Quotes, Profitable Deals: Empowering Your Business with Accurate Proposals.
                    </p>
                </div>
                <div className="flex space-x-4">
                    {!editMode && (
                        <button
                            onClick={() => setEditMode(true)}
                            className="mt-4 px-4 py-2 rounded bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-green-400 hover:shadow-xl transition duration-200"
                        >
                            <i class="fa-light fa-pen-to-square mr-2"></i> Edit
                        </button>
                    )}
                    {editMode && (
                        <>
                            <button
                                onClick={() => setEditMode(false)}
                                className="mt-4 px-4 py-2 rounded bg-gradient-to-b from-red-500 to-red-600 text-white focus:ring-2 focus:ring-red-400 hover:shadow-xl transition duration-200"
                            >
                                <i class="fa-light fa-cancel mr-2"></i>   Cancel
                            </button>
                            <button
                                onClick={handleSaveChanges}
                                className="mt-4 px-4 py-2 rounded bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-green-400 hover:shadow-xl transition duration-200"
                            >
                                <i class="fa-light fa-floppy-disk mr-2"></i>    Save Changes
                            </button>
                        </>
                    )}

                </div>
            </div>

            <div ref={pdfRef} className="max-w-7xl mx-auto bg-white border-2 border-slate-200 lg p-8 h-full">
                <div className="topDiv flex items-start justify-between w-full space-x-4">
                    <div className="flex w-full flex-col">

                        {editMode ? (
                            <>
                                <div className="w-full">
                                    <div className="flex space-x-4 items-start justify-start mb-4">
                                        {base64Image && (<img src={base64Image} alt="Business Logo" className='w-[8rem]' />)}
                                        <button onClick={() => setShowModal(true)} className="text-blue-600"><i className="fa-duotone fa-user-pen fa-xl mr-1"></i></button>
                                    </div>
                                    <h1 className="text-3xl font-semibold">{busName}</h1>
                                    <p className="max-w-lg text-gray-700">{busiAddress}</p>
                                    <p>{busiEmail}</p>
                                    <p>{busiPhone}</p>
                                </div>
                            </>
                        ) : (
                            <div className="w-full mt-4">
                                {base64Image && (<img src={base64Image} alt="Business Logo" className='w-[8rem] mb-4' />)}
                                <h1 className="text-3xl font-semibold">{businessName}</h1>
                                <p className="max-w-lg text-gray-700">{businessAddress}</p>
                                <p>{businessEmail}</p>
                                <p>{businessPhone}</p>
                            </div>
                        )}

                        {/* Client Details */}
                        <div className="w-full mt-8">
                            <h1 className="text-2xl font-semibold">Bill To:</h1>
                            {editMode ? (
                                <input
                                    type="text"
                                    value={clientName}
                                    onChange={(e) => {
                                        let newValue = e.target.value;

                                        // Trim leading spaces
                                        newValue = newValue.trimStart();

                                        // Allow only alphanumeric characters and spaces, and restrict to 20 characters
                                        if (/^[a-zA-Z0-9 ]*$/.test(newValue) && newValue.length <= 20) {
                                            setClientName(newValue);
                                        }
                                    }}
                                    className="w-full border border-gray-300 p-2 mt-2 rounded"
                                    maxLength={20}
                                    placeholder="Client Name"
                                />
                            ) : (
                                <p className="text-lg">{clientName}</p>
                            )}
                            <div className="max-w-lg text-gray-700">
                                {editMode ? (
                                    <input
                                        type="text"
                                        value={clientAddress}
                                        onChange={(e) => {
                                            let newValue = e.target.value;

                                            // Trim leading spaces
                                            newValue = newValue.trimStart();

                                            // Allow only alphanumeric characters and spaces, and restrict to 20 characters
                                            if (/^[a-zA-Z0-9 ]*$/.test(newValue) && newValue.length <= 60) {
                                                setClientAddress(newValue);
                                            }
                                        }}
                                        className="w-full border border-gray-300 p-2 mt-2 rounded"
                                        maxLength={60}
                                        placeholder="Client Address"
                                    />
                                ) : (
                                    <p>{clientAddress}</p>
                                )}
                                <div className="flex items-center flex-col">
                                    {editMode ? (
                                        <input
                                            type="text"
                                            value={clientEmail}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const validEmailFormat = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
                                                const atSymbolCount = (newValue.match(/@/g) || []).length;

                                                // Limit input to 24 characters
                                                if (newValue.length <= 24) {
                                                    if (atSymbolCount <= 1 && validEmailFormat.test(newValue)) {
                                                        setClientEmail(newValue);
                                                    } else if (atSymbolCount <= 1) {
                                                        // Allow input if it does not have double @ but isn't valid yet
                                                        setClientEmail(newValue);
                                                    }
                                                }
                                            }}
                                            className="border w-full border-gray-300 p-2 mt-2 rounded"
                                            placeholder="Client Email"
                                        />
                                    ) : (
                                        <p>{clientEmail}</p>
                                    )}
                                    {editMode ? (
                                        <input
                                            type="text"
                                            value={clientPhone}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (/^[\d()-\s]*$/.test(newValue) &&
                                                    newValue.length <= 15 &&
                                                    !newValue.startsWith('-')) {
                                                    setClientPhone(newValue);
                                                }
                                            }}
                                            className="border w-full border-gray-300 p-2 mt-2 rounded"
                                            placeholder="Client Phone"
                                        />

                                    ) : (
                                        <p>{clientPhone}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Invoice Details */}
                    <div className="flex flex-col w-80 space-y-2">
                        <h1 className="text-4xl font-semibold text-gray-800">Quote Receipt</h1>

                        <p className="text-xl text-gray-500">#{invoiceId}</p>

                        <div className="mt-4">
                            <h2 className="text-lg font-semibold text-gray-700">Quote Reciept Title</h2>
                            {editMode ? (
                                <input
                                    type="text"
                                    maxLength={28}
                                    value={invoiceTitle}
                                    onChange={(e) => {
                                        let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                        // Prevent leading spaces
                                        if (cleanedValue.startsWith(' ')) {
                                            cleanedValue = cleanedValue.trimStart();
                                        }
                                        setInvoiceTitle(cleanedValue)
                                    }}
                                    className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            ) : (
                                <p>{invoiceTitle}</p>
                            )}
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg font-semibold text-gray-700">Quote Reciept Date</h2>
                            {editMode ? (
                                <input
                                    type="date"
                                    value={invoiceDate}
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                    className="w-full border border-gray-300 p-2 mt-2 rounded"
                                />
                            ) : (
                                <p>{invoiceDate}</p>
                            )}
                        </div>
                        <div className="mt-4">
                            <h2 className="text-lg font-semibold text-gray-700">Payment via</h2>
                            {editMode ? (
                                <div className="w-full">
                                    <select value={dueDate} onChange={(e) => setDueDate(e.target.value)} className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                        <option value="no method chosen">Select Payment Option</option>
                                        <option value="via Cash">via Cash</option>
                                        <option value="via Visa Card">via Visa Card</option>
                                        <option value="via MasterCard">via Mastercard</option>
                                    </select>
                                </div>
                            ) : (
                                <p>{dueDate}</p>
                            )}
                        </div>
                    </div>
                </div>
                {editMode ? (<div className="flex items-end justify-end space-x-4 my-4">
                    <label className="leading-7 text-gray-600">Currency</label>
                    <select
                        value={currencySymbol}
                        onChange={(e) => setCurrencySymbol(e.target.value)}
                        className="w-[80px] h-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    >
                        {currencies.map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>

                </div>) : (
                    <div className="flex items-end justify-end space-x-4 my-4 mr-4">
                        <label className="leading-7 text-gray-600">Currency</label>
                        <p>{currency}</p>
                    </div>
                )}

                <div className="mt-8 overflow-x-auto">
                    <table className="w-full min-w-max table-auto border-collapse">
                        <thead>
                            <tr>
                                <th className="h-12 cellOne px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Item</th>
                                <th className="h-12 cellTwo px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Quantity</th>
                                <th className="h-12 cellTwo px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Unit Price</th>
                                <th className="h-12 cellTwo px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Tax <span className="text-red-600 font-semibold">%</span></th>
                                <th className="h-12 cellTwo px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Discount <span className="text-red-600 font-semibold">%</span></th>
                                <th className="h-12 cellTwo px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Total</th>
                                {editMode ? (
                                    <th className="h-12 w-[8px] px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-slate-200">Action</th>
                                ) : (null)}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {editMode ? (
                                            <input
                                                type="text"
                                                value={item.productName}
                                                onChange={(e) => handleInputChange(index, 'productName', e.target.value)}
                                                className="w-full border border-gray-300 p-2 rounded"
                                                maxLength="18"
                                            />
                                        ) : (
                                            <p>{item.productName}</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {editMode ? (

                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Check if value is a valid number and within length constraints
                                                    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 10) {
                                                        handleInputChange(index, 'quantity', value);
                                                    }
                                                }}
                                                className="w-full border border-gray-300 p-2 rounded"
                                                step="any" // Allows decimal values
                                                min="0"   // Restricts input to non-negative values
                                                placeholder="Quantity"
                                            />

                                        ) : (
                                            <p>{item.quantity}</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {editMode ? (
                                            <input
                                                type="number"
                                                value={item.unitPrice}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Check if value is a valid number and within length constraints
                                                    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 10) {
                                                        handleInputChange(index, 'unitPrice', value);
                                                    }
                                                }}
                                                className="w-full border border-gray-300 p-2 rounded"
                                                step="any" // Allows decimal values
                                                min="0"   // Restricts input to non-negative values
                                                placeholder="Unit Price"
                                            />
                                        ) : (
                                            <p>{item.unitPrice}</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {editMode ? (
                                            <input
                                                type="number"
                                                value={item.tax}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Check if value is a valid number and within length constraints
                                                    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 10) {
                                                        handleInputChange(index, 'tax', value);
                                                    }
                                                }}
                                                className="w-full border border-gray-300 p-2 rounded"
                                                step="any" // Allows decimal values
                                                min="0"   // Restricts input to non-negative values
                                                placeholder="Tax"
                                            />
                                        ) : (
                                            <p>{item.tax}</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {editMode ? (
                                            <input
                                                type="number"
                                                value={item.discount}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Check if value is a valid number and within length constraints
                                                    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 10) {
                                                        handleInputChange(index, 'discount', value);
                                                    }
                                                }}
                                                className="w-full border border-gray-300 p-2 rounded"
                                                step="any" // Allows decimal values
                                                min="0"   // Restricts input to non-negative values
                                                placeholder="Discount"
                                            />

                                        ) : (
                                            <p>{item.discount}</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        <p>{item.total.toFixed(2)}</p>
                                    </td>
                                    {editMode ? (
                                        <td className="px-4 py-2 text-center border border-gray-300">
                                            <button onClick={() => deleteRow(index)}>
                                                <i class="fa-solid fa-delete-left fa-xl text-red-600"></i>
                                            </button>
                                        </td>
                                    ) : (null)}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {editMode && (
                        <button
                            onClick={addItemRow}
                            className="mt-4 px-4 py-2 bg-gradient-to-b from-green-500 to-green-600 text-white rounded hover:shadow-xl transition duration-200"
                        >
                            Add Item
                        </button>
                    )}

                </div>

                <div className="w-full flex items-end justify-end text-right space-x-4">
                    <div className="max-w-xs w-full space-y-2 text-right mt-8">
                        <div className="flex w-full items-center text-lg justify-between text-slate-700">
                            <p>Subtotal:</p>
                            <p className="text-lg">{calculateSubtotal().toFixed(2)} {currency}</p>
                        </div>
                        {!shouldHideColumn("discount") && (
                            <div className="flex w-full items-center text-lg justify-between text-slate-700">
                                <p>Total Discount:</p>
                                <p className="text-emerald-500 text-xl font-semibold">-{calculateTotalDiscount().toFixed(2)} {currency}</p>
                            </div>
                        )}
                        {!shouldHideColumn("tax") && (
                            <div className="flex w-full items-center text-lg justify-between text-slate-700">
                                <p>Total Tax:</p>
                                <p className="text-red-500 text-xl font-semibold">+{calculateTotalTax().toFixed(2)} {currency}</p>
                            </div>
                        )}
                        <hr className="border-t-2 border-slate-200 mt-2 mb-2" />
                        <div className="flex items-center justify-between w-full text-slate-700">
                            <p className="text-lg">Total:</p>
                            <code className="text-2xl font-semibold">{calculateTotal().toFixed(2)} {currency}</code>
                        </div>
                    </div>
                </div>

                {/* Repalce this */}
                <div className="mt-8">
                    <h2 className="text-lg font-semibold text-gray-700">Terms & Conditions</h2>
                    {editMode ? (
                        <div>
                            <textarea
                                rows={5}
                                value={termsConditions}
                                onChange={(e) => {
                                    let newValue = e.target.value;

                                    // Remove leading spaces
                                    newValue = newValue.trimStart();

                                    // Remove special characters (keeping letters, numbers, spaces, and basic punctuation)
                                    newValue = newValue.replace(/[^a-zA-Z0-9 .,!?\-()\[\]{}]/g, '');

                                    // Update the state with the validated value
                                    setTermsConditions(newValue);
                                }}
                                className="border rounded p-2 w-full"
                                disabled={!editMode}
                                placeholder="Terms and Condition"
                            />

                        </div>
                    ) : (
                        <div>{termsConditions}</div>
                    )}
                </div>

                <div className="mt-8">
                    <h2 className="text-lg font-semibold text-gray-700">Invoice Notes</h2>
                    {editMode ? (
                        <div>
                            <textarea
                                rows={5}
                                value={invoiceNote}
                                onChange={(e) => {
                                    let newValue = e.target.value;

                                    // Remove leading spaces
                                    newValue = newValue.trimStart();

                                    // Remove special characters (keeping letters, numbers, spaces, and basic punctuation)
                                    newValue = newValue.replace(/[^a-zA-Z0-9 .,!?\-()\[\]{}]/g, '');

                                    // Update the state with the validated value
                                    setInvoiceNote(newValue);
                                }}
                                className="border rounded p-2 w-full"
                                disabled={!editMode}
                                placeholder="Invoice Note"
                            />

                        </div>
                    ) : (
                        <div>{invoiceNote}</div>
                    )}
                </div>
                {/* =============== */}



            </div>
            <BuisnessInfoModal isVisible={showModal} onClose={() => setShowModal(false)} />
        </div>
    );
}

export default EditSales;
