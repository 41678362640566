import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../../context/data";
import toast from "react-hot-toast";
import { PDFDocument } from 'pdf-lib'; // Import pdf-lib

function EncryptPDF() {
  const { authURL } = useAuth();
  const { user } = useUser();
  const [pdfFiles, setPdfFiles] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const downloadLinkRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const postFeatureLog = async () => {
    const postData = {
      userID: user.primaryEmailAddress.emailAddress,
      featureName: "Encrypt PDF",
      featureType: "Premium",
      featureCount: 1,
    };

    try {
      const response = await fetch(`${authURL}/feature-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Feature log posted successfully:", result);
    } catch (error) {
      console.error("Error posting feature log:", error);
    }
  };

  const getPdfInfo = async (pdfData) => {
    try {
      const pdfDoc = await PDFDocument.load(pdfData);
      const numPages = pdfDoc.getPageCount();
      return { numPages };
    } catch (error) {
      console.error("Error getting PDF info:", error);
      return { numPages: 0 }; // Default value if there's an error
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.type === "application/pdf");

    if (validFiles.length !== files.length) {
      toast.error("Please select only valid PDF files.");
    }

    const fileInfoPromises = validFiles.map(async (file) => {
      const arrayBuffer = await file.arrayBuffer();
      const pdfInfo = await getPdfInfo(arrayBuffer);
      return {
        file,
        name: file.name,
        size: file.size,
        lastModified: new Date(file.lastModified).toLocaleString(),
        totalPages: pdfInfo.numPages,
      };
    });

    const fileInfos = await Promise.all(fileInfoPromises);
    setPdfFiles(fileInfos);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value.replace(/\s/g, ""); // Remove spaces
    setPassword(value);
  };

  const handleEncrypt = async () => {
    if (pdfFiles.length > 0 && password) {
      setLoading(true);
      try {
        // Use Promise.all to handle multiple file encryptions
        const promises = pdfFiles.map((pdfFile) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async () => {
              try {
                const pdfData = reader.result;
                const wordArray = CryptoJS.lib.WordArray.create(pdfData);
                const encrypted = CryptoJS.AES.encrypt(wordArray, password).toString();
                const blob = new Blob([encrypted], { type: "application/octet-stream" });
  
                const storageRef = firebase.storage().ref();
                const fileRef = storageRef.child(`${pdfFile.name}.encrypted`);
                
                // Upload encrypted file to Firebase
                const snapshot = await fileRef.put(blob);
                const downloadURL = await snapshot.ref.getDownloadURL();
                await POSTEncryptionData(downloadURL, pdfFile.name);
                setDownloadUrl(downloadURL);
                
                resolve(); // Resolve when done
              } catch (error) {
                console.error("Error encrypting or uploading file:", error);
                reject(error); // Reject on error
              }
            };
  
            reader.onerror = reject; // Reject on reader error
            reader.readAsArrayBuffer(pdfFile.file);
          });
        });
  
        // Wait for all files to be processed
        await Promise.all(promises);
  
        // Click download link after all files are done
        setTimeout(() => {
          if (downloadLinkRef.current) {
            downloadLinkRef.current.click();
          }
        }, 1000);
        
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please select at least one PDF file and enter a password.");
    }
  };
  

  const POSTEncryptionData = async (downloadURL, fileName) => {
    const encryptedObj = {
      fileName: fileName,
      fileURL: downloadURL,
      encryptionKey: password,
      email: user.primaryEmailAddress.emailAddress,
    };

    try {
      const response = await fetch(`${authURL}/encrypt-pdf`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(encryptedObj),
      });

      if (response.status === 201) {
        toast.success(`File ${fileName} Saved & Encrypted Successfully`);
        postFeatureLog();
      } else {
        toast.error(`Failed to save & encrypt file ${fileName}. Please try again later.`);
      }
    } catch (error) {
      console.error("Error during POSTEncryptionData:", error);
      toast.error("Internal Server Error!");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="my-32 h-screen">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto flex items-end justify-between">
        <div>
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">Encrypt your PDF</h1>
          <p className="mt-3 text-gray-600">Encrypt your PDFs files to protect your data.</p>
        </div>
        <div>
          <Link to="/home/encrypt-pdf/encryption-history" className="text-blue-600 underline">
            <span className="flex items-center">
              Encryption History <i className="fa-duotone fa-arrow-right ml-1 no-underline"></i>
            </span>
          </Link>
        </div>
      </div>

      <div className="mt-7 sm:mt-12 md:max-w-4xl xl:max-w-6xl mx-auto relative">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
          <div className="h-72 rounded-lg bg-gray-200">
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {pdfFiles.length === 0 ? (
                    <>
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-gray-500">
                        Any <code className="font-semibold">.pdf</code> PDF type
                      </p>
                    </>
                  ) : (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      {pdfFiles.map((file) => (
                        <div key={file.name} className="text-center p-4 mb-4">
                          <p className="mb-2 text-lg font-semibold text-gray-700">PDF Loaded Successfully</p>
                          <svg
                            className="w-12 mx-auto h-12 mb-4 text-green-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <strong>File Name:</strong> <span className="text-blue-600">{file.name}</span>
                          <p><strong className="text-gray-600">File Size:</strong> {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                          <p><strong className="text-gray-600">Last Modified:</strong> {file.lastModified}</p>
                          <p><strong className="text-gray-600">Total Pages:</strong> {file.totalPages}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="application/pdf"
                  multiple
                  className="hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </label>
            </div>
          </div>

          <div className="h-72 rounded-lg border-2 border-gray-300 border-dashed p-8 bg-gray-50">
            <div>
              <h1 className="text-xl font-semibold text-start">ENCRYPTION DETAILS</h1>
              <p className="text-sm text-gray-500">Add your password to the associated PDF file for encryption.</p>
            </div>
            <div className="h-full flex flex-col justify-center">
              <label className="text-sm text-gray-500 mb-1">Encryption Key / Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  maxLength={16}
                  className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter password..."
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  onClick={togglePasswordVisibility}
                >
                  <i className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                </button>
              </div>
              <button
                className="w-full mt-4 px-6 py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                onClick={handleEncrypt}
                disabled={loading}
              >
                {loading ? "Encrypting..." : "Encrypt"}
              </button>
              {loading && <div className="mt-2"><p className="text-sm text-gray-500">Please wait...</p></div>}
              {downloadUrl && (
                <a
                  ref={downloadLinkRef}
                  href={downloadUrl}
                  download=".pdf.encrypted"
                  style={{ display: "none" }}
                >
                  Download Encrypted Files
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EncryptPDF;
