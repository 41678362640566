import React, { useEffect, useState } from "react";
import APIsDocumentation from "./APIsDocumentation";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../context/data";

function APIsPage() {
  const { user } = useUser();
  const { authURL } = useAuth();
  const [status, setStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const response = await fetch(
          `${authURL}/get-status?email=${user.primaryEmailAddress.emailAddress}`
        );
        if (response.status === 404) {
          console.error("Status not found");
          return;
        }
        const data = await response.json();
        console.log("API Response:", data._id);
        setStatus(data._id); // Update status
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    getStatus(); // Invoke the function inside useEffect
  }, [authURL, user.primaryEmailAddress.emailAddress]); // Add dependencies

  // ==============================Modal
  // Handle ESC key press
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) setIsOpen(false);
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <div className="mt-24">
      <div className="w-full flex items-center justify-center flex-col gap-5 mb-4">
        <h1 className="font-light capitalize md:text-6xl">
          <span className="underline font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
            APIs Documentation
          </span>
        </h1>
        <p className="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
          We provide APIs that can easily integrate into your system with tons
          of PDF Tools and Document Management Systems. Use these credentials as
          Basic Authorization to use APIs.
        </p>

        <button
          onClick={() => setIsOpen(true)}
          className="mt-6 px-8 py-4 bg-gradient-to-l from-blue-600 to-violet-500 text-white rounded-md"
        >
          See Authentication Details
        </button>

        {/* Modal Backdrop */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-[999] transition-opacity duration-300"
            onClick={() => setIsOpen(false)}
          />
        )}

        {/* Modal */}
        <div
          className={`fixed inset-0 flex items-center justify-center z-[999] p-4 ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            } transition-opacity duration-300`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`bg-white rounded-lg shadow-xl w-full max-w-md transform ${isOpen ? "scale-100" : "scale-95"
              } transition-transform duration-300`}
          >
            {/* Modal Header */}
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold">Authentication Details</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6 space-y-4">
              <div className="space-y-4">
                <div className="space-y-4">
                  <code className="block mt-2 p-2 bg-gray-100 rounded-md">
                    username: <span className="text-blue-600">{user.primaryEmailAddress.emailAddress}</span>
                  </code>
                  <code className="block mt-2 p-2 bg-gray-100 rounded-md">
                    Password: <span className="text-blue-600">{status || "Loading..."} </span>
                  </code>
                </div>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-end p-6 border-t">
              <button
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <APIsDocumentation />
    </div>
  );
}

export default APIsPage;
