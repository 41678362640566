import React, { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import toast from "react-hot-toast";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../../context/data";

function SplitPDF() {
  const { authURL } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [splitFiles, setSplitFiles] = useState([]);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSplitted, setIsSplitted] = useState(false);
  const [showSplitChunks, setShowSplitChunks] = useState(false);
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  const [pdfDetails, setPdfDetails] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [password, setPassword] = useState("");

  const postFeatureLog = async () => {
    const postData = {
      userID: user.primaryEmailAddress.emailAddress,
      featureName: "Split PDF",
      featureType: "Freemium",
      featureCount: 1,
    };

    try {
      const response = await fetch(`${authURL}/feature-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Feature log posted successfully:", result);
    } catch (error) {
      console.error("Error posting feature log:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 10 * 1024 * 1024
    ) {
      try {
        const fileArrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileArrayBuffer, {
          ignoreEncryption: true,
        });

        if (pdfDoc.isEncrypted) {
          setIsLocked(true);
          setPdfDetails(null);
          setSelectedFile(file);
          toast.error(
            "This PDF is password-protected. Please unlock it to proceed."
          );
          return;
        }

        const totalPages = pdfDoc.getPageCount();

        setSelectedFile(file);
        setSplitFiles([]);
        setMergedPdfUrl(null);
        setIsSplitted(false);
        setIsLocked(false);

        const lastModified = new Date(file.lastModified).toLocaleString();

        setPdfDetails({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2),
          numPages: totalPages,
          lastModified: lastModified,
        });

        toast.success("PDF Uploaded Successfully!");
      } catch (error) {
        console.error("Error processing PDF:", error);
        toast.error("Error processing PDF. Please try again.");
      }
    } else {
      setSelectedFile(null);
      setSplitFiles([]);
      setPdfDetails(null);
      toast.error("Please upload a valid PDF file less than 10MB.");
    }
  };

  const handleUnlockPDF = async () => {
    if (!selectedFile || !password) {
      toast.error("Please select a file and enter a password.");
      return;
    }

    try {
      const fileArrayBuffer = await selectedFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileArrayBuffer, { password });

      const totalPages = pdfDoc.getPageCount();
      const lastModified = new Date(selectedFile.lastModified).toLocaleString();

      setPdfDetails({
        name: selectedFile.name,
        size: (selectedFile.size / (1024 * 1024)).toFixed(2),
        numPages: totalPages,
        lastModified: lastModified,
      });

      setIsLocked(false);
      toast.success("PDF unlocked successfully!");
    } catch (error) {
      console.error("Error unlocking PDF:", error);
      toast.error("Incorrect password. Please try again.");
    }
  };

  const handleStartPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= endPage) {
      setStartPage(value);
    } else if (value > endPage) {
      toast.error("Start page cannot be greater than end page.");
    }
  };

  const handleEndPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= startPage) {
      setEndPage(value);
    } else if (value < startPage) {
      toast.error("End page cannot be less than start page.");
    }
  };

  const handleSplitPDF = async () => {
    if (!selectedFile) {
      toast.error("Please upload a PDF file first.");
      return;
    }

    if (startPage < 1) {
      toast.error("Start page must be greater than or equal to 1.");
      return;
    }

    setIsLoading(true);
    const fileArrayBuffer = await selectedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(fileArrayBuffer);
    const totalPages = pdfDoc.getPageCount();

    if (startPage > totalPages) {
      toast.error(
        `Start page cannot be greater than total pages (${totalPages}).`
      );
      setIsLoading(false);
      return;
    }

    if (endPage > totalPages) {
      toast.error(
        `End page cannot be greater than total pages (${totalPages}).`
      );
      setIsLoading(false);
      return;
    }

    if (startPage > endPage) {
      toast.error("Start page cannot be greater than end page.");
      setIsLoading(false);
      return;
    }

    const newFiles = [];
    for (let i = startPage - 1; i < endPage; i++) {
      const newPdfDoc = await PDFDocument.create();
      const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
      newPdfDoc.addPage(copiedPage);

      const pdfBytes = await newPdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      newFiles.push({ url, pageNumber: i + 1 });
    }

    setSplitFiles(newFiles);
    setIsSplitted(true);
    setShowSplitChunks(true);
    setIsLoading(false);
    toast.success("PDF Split Successfully!");
    postFeatureLog();

    setTimeout(() => {
      document
        .getElementById("split-section")
        .scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const handleMergePDF = async () => {
    if (splitFiles.length === 0) {
      toast.error("No splitted PDF files to merge.");
      return;
    }

    setIsLoading(true);
    const mergedPdfDoc = await PDFDocument.create();

    for (let file of splitFiles) {
      const fileBytes = await fetch(file.url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(fileBytes);
      const copiedPages = await mergedPdfDoc.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
    }

    const mergedPdfBytes = await mergedPdfDoc.save();
    const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    const mergedUrl = URL.createObjectURL(mergedBlob);

    setMergedPdfUrl(mergedUrl);
    setIsLoading(false);
  };

  const toggleToMergedPDF = async () => {
    if (!mergedPdfUrl) {
      await handleMergePDF();
    }
    setShowSplitChunks(false);
  };

  const toggleToSplitChunks = () => {
    setShowSplitChunks(true);
  };

  return (
    <div className="relative overflow-hidden my-32">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto px-4 space-y-8">
        <div className="text-start">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Generate Split PDF
          </h1>
          <p className="mt-3 text-gray-600">
            Upload and Split a single PDF file into multiple PDFs to merge them
            later.
          </p>
        </div>

        <div className="mx-auto max-w-7xl relative">
          <div className="h-full rounded-lg bg-gray-200">
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {!selectedFile && (
                    <>
                      <svg
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        PDF (MAX. 10MB)
                      </p>
                    </>
                  )}

                  {selectedFile && (
                    <div className="w-full max-w-md p-6 ">
                      {isLocked ? (
                        <div className="space-y-4">
                          <h2 className="text-2xl font-bold text-red-600">
                            Locked PDF
                          </h2>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter PDF password"
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                          />
                          <button
                            onClick={handleUnlockPDF}
                            className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                          >
                            Unlock PDF
                          </button>
                        </div>
                      ) : (
                        pdfDetails && (
                          <div className="max-w-md mx-auto  rounded-xl  overflow-hidden md:max-w-2xl">
                            <div className="p-8">
                              <div className="flex justify-center items-center ">
                                <svg
                                  className="w-12 h-12 mb-4 text-green-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                              <div className="text-center">
                                <p className="mb-2 text-lg font-semibold text-gray-700">
                                  PDF Loaded Successfully
                                </p>
                                <p className="text-gray-600">
                                  <strong>File Name:</strong>{" "}
                                  <span className="text-blue-600">
                                    {pdfDetails.name}
                                  </span>
                                </p>

                                <p className="text-gray-600">
                                  <strong>File Size:</strong> {pdfDetails?.size}{" "}
                                  MB
                                </p>
                                <p className="text-gray-600">
                                  <strong>Last Modified:</strong>{" "}
                                  {pdfDetails?.lastModified}
                                </p>
                                <p className="text-gray-600">
                                  <strong>Pages:</strong>{" "}
                                  {pdfDetails?.numPages}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="application/pdf"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>
        </div>

        {selectedFile && !isLocked && (
          <div className="p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50">
            <div>
              <h1 className="text-xl uppercase font-semibold">Actions</h1>
              <p className="text-sm">
                Specify the range of pages to split the PDF from the starting
                page to the ending page
              </p>
            </div>
            <div className="flex items-end justify-end space-x-8">
              <div className="w-full">
                <label className="text-sm text-gray-600">
                  Starting Page Number
                </label>
                <input
                  type="number"
                  placeholder="Start Page"
                  value={startPage}
                  onChange={handleStartPageChange}
                  className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="w-full">
                <label className="text-sm text-gray-600">
                  Ending Page Number
                </label>
                <input
                  type="number"
                  placeholder="End Page"
                  value={endPage}
                  onChange={handleEndPageChange}
                  className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <button
                onClick={handleSplitPDF}
                className="px-6 py-3 w-1/2 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
              >
                Split PDF
              </button>
            </div>
          </div>
        )}

        {/* Split Chunks Section */}
        {isSplitted && showSplitChunks && (
          <div
            id="split-section"
            className="p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50"
          >
            <div className="flex items-center justify-between px-2">
              <h1 className="text-3xl font-semibold">Split Chunks</h1>
              <button
                onClick={toggleToMergedPDF}
                className="px-6 py-3 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
              >
                Show Merged PDF
              </button>
            </div>
            {splitFiles.map((file, index) => (
              <div key={index} className="mb-4">
                <iframe
                  src={file.url}
                  title={`Page ${file.pageNumber}`}
                  className="border border-gray-300 rounded w-full h-[75rem]"
                />
                <a
                  href={file.url}
                  download={`page_${file.pageNumber}.pdf`}
                  className="px-4 py-2 bg-blue-500 text-white rounded mt-2 inline-block"
                >
                  Download Page {file.pageNumber}
                </a>
              </div>
            ))}
          </div>
        )}

        {/* Merged PDF Section */}
        {mergedPdfUrl && !showSplitChunks && (
          <div className="p-8 border-2 border-gray-300 border-dashed rounded-lg space-y-4 cursor-pointer bg-gray-50">
            <div className="flex items-center justify-between px-2">
              <h1 className="text-3xl font-semibold">Merged PDF</h1>
              <div className="space-x-4">
                <a
                  href={mergedPdfUrl}
                  download="merged.pdf"
                  className="px-6 py-3 rounded-lg bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-green-400 hover:shadow-xl transition duration-200"
                >
                  <i className="fa-duotone fa-solid fa-download fa-lg"></i>
                </a>
                <button
                  onClick={toggleToSplitChunks}
                  className="px-6 py-3 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                >
                  Show Split Chunks
                </button>
              </div>
            </div>
            <iframe
              src={mergedPdfUrl}
              title="Merged PDF"
              className="border border-gray-300 rounded w-full h-[75rem]"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SplitPDF;
