import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/data';
import { ClerkProvider } from '@clerk/clerk-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCLeWYnEnpIgwEBh9ILU-s9SuOZi1z4rdg",
  authDomain: "quickdoc-e0e11.firebaseapp.com",
  projectId: "quickdoc-e0e11",
  storageBucket: "quickdoc-e0e11.appspot.com",
  messagingSenderId: "986132772117",
  appId: "1:986132772117:web:e2e0db21f42bd30896e51c"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAbHGSUUVf8-JgGyWiupov7B-rs_rHc3tI",
//   authDomain: "quickdoc-fe293.firebaseapp.com",
//   projectId: "quickdoc-fe293",
//   storageBucket: "quickdoc-fe293.appspot.com",
//   messagingSenderId: "897317285053",
//   appId: "1:897317285053:web:14fcd797a3e07281405bd4",
//   measurementId: "G-D16X08EJH7"
// };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const clerkPubkey = 'pk_test_c3dlZXQtbmFyd2hhbC01Mi5jbGVyay5hY2NvdW50cy5kZXYk';
// const clerkPubkey = 'pk_test_cmVuZXdlZC1zaGVwaGVyZC0zMi5jbGVyay5hY2NvdW50cy5kZXYk';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <BrowserRouter>
        <ClerkProvider publishableKey={clerkPubkey}>
          <App />
        </ClerkProvider>
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>
);

reportWebVitals();
