import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/data';
import { format } from 'date-fns';

function FreemiumUser() {
    const [clients, setClients] = useState([]);
    const { authURL } = useAuth();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${authURL}/get-users`); // Assuming your API endpoint is '/api/users'
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const freemiumClients = data.data.filter(client => client.status === 'freemium');
                setClients(freemiumClients);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchUsers();
    }, []);

    const formatDate = (timestamp) => {
        return format(new Date(timestamp), 'dd MMM yyyy'); // Example format using date-fns
    };

    return (
        <div className='h-screen'>

            <div>
                <h1 className='text-5xl font-semibold'>Freemium Users</h1>
                <h1 className='text-gray-600'>List of users that have joined us with no premium Subscriptions</h1>
            </div>

            <div className='bg-white shadow-sm border-1 border-slate-300 p-4 rounded-lg my-12'>
                <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                    <thead className="hidden border-b lg:table-header-group">
                        <tr className="">
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                User ID
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                User Name
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                User Email
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Package category
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Package Subscription
                            </td>
                        </tr>
                    </thead>
                    <tbody className="bg-white lg:border-gray-300">
                        {clients.map(client => (
                            <tr key={client._id} className='border-b border-slate-200'>
                                <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                    {/* Assuming you have fields like createdAt, clientId, email, fullName, planName */}
                                    {formatDate(client.createdAt)}
                                </td>
                                <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                    {client.fullName}
                                </td>
                                <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                    {client.email}
                                </td>
                                <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                    {client.status}
                                </td>
                                <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                    <span className="ml-2 mr-3 whitespace-nowrap rounded-full bg-yellow-200 px-4 py-2">
                                        {client.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FreemiumUser;
