import React, { useEffect, useState } from 'react';
import { useAuth } from "../../../../context/data";
import { useUser } from "@clerk/clerk-react";
import toast from 'react-hot-toast';
import ClientModal from './Windows/ClientModal';

function ClientsData() {
    const { user } = useUser();
    const [clientData, setClientData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editRowId, setEditRowId] = useState(null);
    const [editableClient, setEditableClient] = useState({});
    const { authURL } = useAuth();
    const [showClientModal, setShowClientModal] = useState(false);


    const fetchClientData = async () => {
        try {
            const response = await fetch(
                `${authURL}/get-client?userID=${user.primaryEmailAddress.emailAddress}`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const res = await response.json();
            setClientData(res);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const startEdit = (client) => {
        setEditRowId(client._id);
        setEditableClient(client);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setEditableClient({});
    };

    const saveEdit = async () => {
        if (editableClient.clientName === '') {
            toast.error("Client Name is Required!");
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(`${authURL}/update-client?id=${editableClient._id}`, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editableClient),
            });

            if (response.ok) {
                console.log('Information Updated Successfully');
                setClientData(clientData.map(client => client._id === editableClient._id ? editableClient : client));
                cancelEdit();
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteClient = async (clientID) => {
        setLoading(true);
        try {
            const response = await fetch(`${authURL}/delete-client?id=${clientID}`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.ok) {
                console.log('Client deleted successfully');

                setClientData(clientData.filter(client => client._id !== clientID));
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientData();
    }, []);

    return (
        <div className='md:max-w-4xl xl:max-w-6xl mx-auto py-20 h-screen'>
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Clients Information
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Manage your Clients Data & Information that are connected to your business!
                    </p>
                </div>
            </div>

            <div className='flex items-end justify-end space-x-4'>
                <button
                    onClick={() => setShowClientModal(true)}
                    className="px-4 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">
                    <i className="fa-duotone fa-circle-plus"></i> Add a new Client
                </button>
            </div>

            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader"></div>
                </div>
            ) : (
                <table className="md:max-w-4xl xl:max-w-6xl mx-auto w-full mt-12 text-left border border-separate rounded border-slate-200">
                    <thead>
                        <tr>
                            <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Client Name</th>
                            <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Client Phone</th>
                            <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Client Email</th>
                            <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Client Address</th>
                            <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientData.map((client, index) => (
                            <tr key={index} className="bg-slate-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {editRowId === client._id ? (
                                        <input
                                            type="text"
                                            value={editableClient.clientName}
                                            maxLength={32}
                                            onChange={
                                                (e) => {
                                                    let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s@._-]/g, '');
                                                    // Prevent leading spaces
                                                    if (cleanedValue.startsWith(' ')) {
                                                        cleanedValue = cleanedValue.trimStart();
                                                    }
                                                    setEditableClient({ ...editableClient, clientName: cleanedValue })

                                                }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    ) : (
                                        client.clientName
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {editRowId === client._id ? (
                                        <input
                                            type="text"
                                            value={editableClient.clientPhone}
                                            maxLength={32}
                                            onChange={
                                                (e) => {
                                                    let cleanedValue = e.target.value.replace(/[^0-9\s+]/g, '');
                                                    // Prevent leading spaces
                                                    if (cleanedValue.startsWith(' ')) {
                                                        cleanedValue = cleanedValue.trimStart();
                                                    }
                                                    setEditableClient({ ...editableClient, clientPhone: cleanedValue })
                                                }}
                                            className=" w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    ) : (
                                        client.clientPhone
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {editRowId === client._id ? (
                                        <input
                                            type="text"
                                            value={editableClient.clientEmail}
                                            maxLength={32}
                                            onChange={(e) => {
                                                // Allow only valid email characters: letters, numbers, and certain special characters
                                                let cleanedValue = e.target.value
                                                    .replace(/[^a-zA-Z0-9@._-]/g, '')
                                                    .trim(); // Remove leading/trailing spaces
                                                setEditableClient({ ...editableClient, clientEmail: cleanedValue })
                                            }}

                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    ) : (
                                        client.clientEmail
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {editRowId === client._id ? (
                                        <input
                                            type="text"
                                            value={editableClient.clientAddress}
                                            maxLength={62}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;
                                                // Prevent leading spaces
                                                if (cleanedValue.startsWith(' ')) {
                                                    cleanedValue = cleanedValue.trimStart();
                                                }
                                                setEditableClient({ ...editableClient, clientAddress: e.target.value })
                                            }}

                                            className=" w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    ) : (
                                        client.clientAddress
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                                    {editRowId === client._id ? (
                                        <>
                                            <button onClick={saveEdit} className="text-blue-500 mr-4">Save</button>
                                            <button onClick={cancelEdit} className="text-gray-500">Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => startEdit(client)}><i className="fa-duotone fa-pen text-blue-500 mr-4"></i></button>
                                            <button onClick={() => deleteClient(client._id)}><i className="fa-duotone fa-trash text-red-500"></i></button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <ClientModal
                isVisible={showClientModal}
                onClose={() => setShowClientModal(false)}
            />
        </div>
    );
}

export default ClientsData;
