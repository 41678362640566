import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import BuisnessInfoModal from './Document/Windows/BuisnessInfoModal'

function GenerateDocument() {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <div className='mt-32 md:max-w-4xl xl:max-w-6xl mx-auto h-screen'>
                <div className='flex items-end justify-between'>
                    <div className='flex items-start justify-between w-full'>
                        <div className='w-full'>
                            <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                                Reciepts
                            </h1>
                            <p className="mt-3 text-gray-600">
                                Select which reciepts type you want to generate.
                            </p>
                        </div>
                        <div className='flex flex-col items-end justify-center space-y-2 w-1/2'>
                            <button onClick={() => setShowModal(true)} className='className="w-full font-normal py-2 px-8 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"'>
                                Buisness Information
                            </button>
                            <Link to='manage-clients' className='className="w-full font-normal py-2 px-8 rounded-full bg-gradient-to-b from-green-500 to-green-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"'>
                                Manage Clients
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-1 mt-8 gap-4 lg:grid-cols-3 lg:gap-8">

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='invoice-data' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-blue">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-file-invoice fa-2xl text-blue-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Invoice</h1>
                                        <p className='text-sm'>Effortless Invoices, Seamless Business</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='/home/generate-docx/invoice-data/invoice-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-blue">
                                View Invoice History
                            </Link>
                        </div>
                    </div>

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='sales-reciept-form' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-red">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-receipt fa-2xl text-yellow-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Sales Receipt</h1>
                                        <p className='text-sm'>Streamlined Receipts, Simplified Sales</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='/home/generate-docx/sales-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-red">
                                View Sales Receipt History
                            </Link>
                        </div>
                    </div>

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='bills-reciept-form' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-yellow">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-solid fa-file-invoice-dollar fa-2xl text-yellow-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Bills</h1>
                                        <p className='text-sm'>Billing Made Easy, Business Made Simple.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='/home/generate-docx/bills-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-yellow">
                                View Bill History
                            </Link>
                        </div>
                    </div>

                </div>

                <div class="grid grid-cols-1 mt-8 gap-4 lg:grid-cols-3 lg:gap-8">

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='quotes-form' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-green">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-quotes fa-2xl text-green-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Quotes</h1>
                                        <p className='text-sm'>Precision Quotes, Profitable Deals</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='quotes-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-green">
                                View Quotes History
                            </Link>
                        </div>
                    </div>

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='purchase-form' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-red">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Purchase Receipt</h1>
                                        <p className='text-sm'>Streamlined Receipts, Simplified Purchase</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='purchase-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-red">
                                View Purchase Receipt History
                            </Link>
                        </div>
                    </div>

                    <div class="h-52 rounded-lg">
                        <div>
                            <Link to='memo-form' className="group py-4 flex flex-co items-center justify-center h-full bg-white border-t border-l border-r shadow-sm rounded-tl-xl rounded-tr-xl hover:shadow-md transition ripple-blue">
                                <div className="flex flex-col items-center justify-center p-4">
                                    <div className='rounded-full'>
                                        <i className="fa-duotone fa-memo-circle-info fa-2xl text-purple-600"></i>
                                    </div>
                                    <div className='mt-6 text-center'>
                                        <h1 className='text-lg font-semibold'>Generate Memo</h1>
                                        <p className='text-sm'>Crisp Memos, Clear Communication</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div>
                            <Link to='memo-history' className="group p-3 rounded-bl-xl rounded-br-xl border flex flex-co items-center justify-center h-full shadow-sm rounded-xl-t hover:shadow-md transition ripple-blue">
                                View Memo History
                            </Link>
                        </div>
                    </div>

                </div>

            </div>

            <BuisnessInfoModal isVisible={showModal} onClose={() => setShowModal(false)} />
        </>
    )
}

export default GenerateDocument