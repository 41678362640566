import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useAuth } from "../context/data";
import { Helmet } from "react-helmet-async";

function ContactUs() {
  const [Fname, setFName] = useState("");
  const [Lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [contactNumber, setContactNumber] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const { authURL } = useAuth();
  const FnameChange = (e) => setFName(e.target.value);
  const LnameChange = (e) => setLName(e.target.value);
  const emailChange = (e) => setEmail(e.target.value);
  const messageChange = (e) => setMessage(e.target.value);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  // Function to handle the reCAPTCHA change event
  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // reCAPTCHA is completed or not
  };

  const contactNumberChange = (e) => setContactNumber(e.target.value);
  const categoryChange = (e) => setSelectedCategory(e.target.value);
  const projectNameChange = (e) => setSelectedProject(e.target.value);

  const projects = [
    { id: 1, name: "Resturant" },
    { id: 2, name: "TRANSPORT MANAGEMENT SYSTEM" },
    { id: 3, name: "Investment Management System" },
    { id: 4, name: "P-TRACK" },
    { id: 5, name: "Electronic Permit System" },
    { id: 6, name: "Property Management System" },
    { id: 7, name: "School Management System" },
    { id: 8, name: "Zatca" },
    { id: 9, name: "Document Management System" },
    { id: 10, name: "TaxSlips" },
  ];

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    if (!captchaVerified) {
      toast.error("Please complete the reCAPTCHA.");
      setIsLoading(false);
      return;
    }

    // Check if required fields are empty
    if (!Fname) {
      toast.error("First Name is required.");
      setIsLoading(false);
      return;
    }

    if (!Lname) {
      toast.error("Last Name is required.");
      setIsLoading(false);
      return;
    }

    if (!email) {
      toast.error("Email is required.");
      setIsLoading(false);
      return;
    }

    if (!message) {
      toast.error("Message is required.");
      setIsLoading(false);
      return;
    }

    const data = {
      fullName: Fname + Lname,
      email: email,
      description: message,
      phone: contactNumber,
      appGenre: selectedCategory,
      appGenre: selectedProject,
    };

    try {
      const response = await fetch(
        "https://admin-bzs-server.vercel.app/admin/auth/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data");
      }

      toast.success("Successfully submitted - Check your email!", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      // Clear form fields after successful submission
      setFName("");
      setLName("");
      setEmail("");
      setMessage("");
      setContactNumber("");
      setSelectedCategory("");
      setSelectedProject("");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting your request - try again later");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - QuickDoc Support and Inquiries</title>
        <meta
          name="description"
          content="Get in touch with QuickDoc for support, inquiries, or feedback about our document management solutions. We're here to help you streamline your document processes."
        />
        <meta
          name="keywords"
          content="Contact QuickDoc, document management support, QuickDoc inquiries, document generation assistance, customer support"
        />
        <meta
          property="og:title"
          content="Contact QuickDoc - We're Here to Help"
        />
        <meta
          property="og:description"
          content="Have questions or need support? Reach out to QuickDoc for assistance with our document management tools and solutions."
        />
        <meta
          property="og:image"
          content="https://www.quickdoc.app/static/media/ss2.59e19d71ac4c4c36e691.png"
        />
        <meta property="og:url" content="https://www.quickdoc.app/contact" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - QuickDoc Support" />
        <meta
          name="twitter:description"
          content="Contact QuickDoc for inquiries or support regarding our document management and generation services."
        />
        <meta
          name="twitter:image"
          content="https://www.quickdoc.app/static/media/ss2.59e19d71ac4c4c36e691.png"
        />
      </Helmet>

      {/* Contact Us */}
      <div className="max-w-[85rem] px-4 py-10 mt-24 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl lg:max-w-5xl mx-auto">
          <div className="max-w-7xl mx-auto flex flex-col items-start justify-center">
            <div className="w-full flex items-center justify-center flex-col gap-5 mb-14">
              <span className="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
                Contact Us
              </span>
              <h1 className="font-light capitalize md:text-5xl">
                Get in{" "}
                <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                  Touch
                </span>{" "}
                with us!
              </h1>
              <p className="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
                In the world of architecture or organization, structure provides
                the backbone for a purposeful and harmonious existence.
              </p>
            </div>
          </div>

          <div className="my-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-16">
            {/* Card */}
            <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8">
              <h2 className="mb-8 text-xl font-semibold text-gray-800">
                Fill in the form
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4">
                  {/* Grid */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="hs-firstname-contacts-1"
                        className="sr-only"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        onChange={FnameChange}
                        value={Fname}
                        className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="First Name"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="hs-lastname-contacts-1"
                        className="sr-only"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        onChange={LnameChange}
                        value={Lname}
                        className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  {/* Email Input */}
                  <div>
                    <input
                      type="email"
                      onChange={emailChange}
                      value={email}
                      className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      placeholder="Email"
                    />
                  </div>

                  {/* Contact Number Input */}
                  {/* <div>
                    <input
                      type="tel"
                      onChange={contactNumberChange}
                      value={contactNumber}
                      maxLength={16}
                      className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      placeholder="Contact Number"
                    />
                  </div> */}

                  {/* Project Category Dropdown */}
                  <div>
                    <select
                      onChange={categoryChange}
                      value={selectedCategory}
                      className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                      <option value="">Select Project Category</option>
                      <option value="saas">SAAS</option>
                      <option value="paas">PAAS</option>
                      <option value="migration">Migration</option>
                      <option value="integration">Integration</option>
                    </select>
                  </div>

                  {/* Project Name Dropdown */}
                  <div>
                    <select
                      onChange={projectNameChange}
                      value={selectedProject}
                      className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                      <option value="">Select Project</option>
                      {projects.map((project) => (
                        <option key={project.id} value={project.name}>
                          {project.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Details Textarea */}
                  <div>
                    <textarea
                      onChange={messageChange}
                      value={message}
                      rows={4}
                      className="border max-h-48 w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      placeholder="Details"
                    />
                  </div>
                </div>

                {/* ReCAPTCHA */}
                <div>
                  <ReCAPTCHA
                    sitekey={"6LeYA0AqAAAAAKALbWXN9kETlQIiASDEa5VQebV2"}
                    onChange={handleCaptchaChange}
                  />
                </div>

                {/* Submit Button */}
                <div className="mt-4 grid">
                  <button
                    type="submit"
                    className="w-full mt-4 px-6 py-3 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    {isLoading ? "Submitting..." : "Submit Request"}
                  </button>
                </div>

                <div className="mt-3 text-center">
                  <p className="text-sm text-gray-500">
                    We'll get back to you in 1-2 business days.
                  </p>
                </div>
              </form>
            </div>
            {/* End Card */}

            {/* Icon Block */}
            <div className="divide-y divide-gray-200">
              {/* Icon Block */}
              <div className="flex gap-x-7 py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx={12} cy={12} r={10} />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold text-gray-800">Knowledgebase</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    We're here to help with any questions or code.
                  </p>
                  <a
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                    href="#"
                  >
                    Contact support
                    <svg
                      className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
              {/* Icon Block */}
              <div className="flex gap-x-7 py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                  <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold text-gray-800">FAQ</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Search our FAQ for answers to anything you might ask.
                  </p>
                  <Link
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                    to="/#faqs"
                  >
                    Visit FAQ
                    <svg
                      className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              {/* End Icon Block */}
              {/* Icon Block */}
              <div className=" flex gap-x-7 py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m7 11 2-2-2-2" />
                  <path d="M11 13h4" />
                  <rect width={18} height={18} x={3} y={3} rx={2} ry={2} />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold text-gray-800">
                    Developer APIs
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Check out our development quickstart guide.
                  </p>
                  <a
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                    href="#"
                  >
                    Contact sales
                    <svg
                      className="flex-shrink-0 size-2.5 transition ease-in-out group-hover:translate-x-1"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
              {/* Icon Block */}
              <div className=" flex gap-x-7 py-6">
                <svg
                  className="flex-shrink-0 size-6 mt-1.5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                  <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                </svg>
                <div className="grow">
                  <h3 className="font-semibold text-gray-800">
                    Contact us by email
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    If you wish to write us an email instead please use
                  </p>
                  <a
                    className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                    href="#"
                  >
                    admin@bzsoftech.com
                  </a>
                </div>
              </div>
              {/* End Icon Block */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
