import React, { useState, useEffect, useRef } from "react";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../../context/data";
import toast from "react-hot-toast";
import * as pdfjsLib from "pdfjs-dist/webpack";  // Import pdfjs-dist for parsing

function PDFtoImage() {
    const { authURL } = useAuth();
    const { user } = useUser();
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [pdfDetails, setPdfDetails] = useState(null);
    const [images, setImages] = useState([]); // State to hold converted images
    const pdfContainerRef = useRef(null);

    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "PDF to Images",
            featureType: "Freemium",
            featureCount: 1,
        };

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log("Feature log posted successfully:", result);
        } catch (error) {
            console.error("Error posting feature log:", error);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (
            file &&
            file.type === "application/pdf" &&
            file.size <= 40 * 1024 * 1024
        ) {
            setSelectedPdf(file);
            postFeatureLog();

            // Set PDF details
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async function () {
                const pdfData = new Uint8Array(reader.result);
                const pdfDocument = await pdfjsLib.getDocument(pdfData).promise;
                const numPages = pdfDocument.numPages;

                // Convert PDF pages to images
                const pageImages = await Promise.all(
                    Array.from({ length: numPages }, async (_, index) => {
                        const page = await pdfDocument.getPage(index + 1);
                        const viewport = page.getViewport({ scale: 2 });
                        const canvas = document.createElement("canvas");
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;
                        const context = canvas.getContext("2d");
                        await page.render({ canvasContext: context, viewport }).promise;

                        // Return the image data URL
                        return canvas.toDataURL();
                    })
                );

                setPdfDetails({
                    name: file.name,
                    size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB
                    lastModified: new Date(file.lastModified).toLocaleString(),
                    totalPages: numPages,  // Set the total number of pages
                });
                setImages(pageImages); // Set the images state
            };
        } else {
            setSelectedPdf(null);
            setPdfDetails(null);
            setImages([]); // Reset images on invalid file
            toast.error("Please select a valid PDF file (up to 40MB).");
        }
    };

    useEffect(() => {
        if (pdfContainerRef.current && selectedPdf) {
            pdfContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [selectedPdf]);

    const handleDownload = (imageSrc, pageNumber) => {
        const link = document.createElement("a");
        link.href = imageSrc;
        link.download = `page-${pageNumber}.png`; // Set download filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="relative overflow-hidden h-full mt-32 mb-48">
            <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
                <div className="text-start">
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        PDF to Image Converter
                    </h1>
                    <p className="mt-3 text-gray-600">
                        View your PDF file up to a size limit of 40MB
                    </p>
                </div>

                <div className="mt-7 sm:mt-12">
                    <div className="h-full rounded-lg p-8">
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300"
                            >
                                {!selectedPdf ? (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-12 h-12 mb-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-lg text-gray-500">
                                            <span className="font-semibold">Click to upload</span> or
                                            drag and drop
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            Any <code className="font-semibold">.pdf</code> file (up
                                            to 40MB)
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-12 h-12 mb-4 text-green-500"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <p className="mb-2 text-lg font-semibold text-gray-700">
                                            PDF Loaded Successfully
                                        </p>
                                        <p className="text-gray-600">
                                            <strong>File Name:</strong>{" "}
                                            <span className="text-blue-600">{pdfDetails?.name}</span>
                                        </p>
                                        <p className="text-gray-600">
                                            <strong>File Size:</strong> {pdfDetails?.size} MB
                                        </p>
                                        <p className="text-gray-600">
                                            <strong>Last Modified:</strong> {pdfDetails?.lastModified}
                                        </p>
                                        <p className="text-gray-600">
                                            <strong>Total Pages:</strong> {pdfDetails?.totalPages}
                                        </p>
                                    </div>
                                )}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="application/pdf"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                {images.length > 0 && (
                    <div className="my-24 max-w-7xl mx-auto h-auto" ref={pdfContainerRef}>
                        <h1 className="mb-4 text-xl text-start font-semibold">Preview</h1>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {images.map((imageSrc, index) => (
                                <div key={index} >
                                    <img
                                        src={imageSrc}
                                        alt={`PDF Page ${index + 1}`}
                                        className="max-w-[24rem] h-auto border-2 border-gray-300 rounded-lg"
                                    />
                                    <button
                                        onClick={() => handleDownload(imageSrc, index + 1)}
                                        className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                                    >
                                        Download Page {index + 1}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}

export default PDFtoImage;
