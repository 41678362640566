import React, { useState, useEffect, useRef } from "react";
import ClientModal from "../Windows/ClientModal";
import { useAuth } from "../../../../../context/data";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import BuisnessInfoModal from "../Windows/BuisnessInfoModal";
import CModal from "../Windows/ManageClientModal";
import SignatureCanvas from 'react-signature-canvas';

function Quotes() {
    const { user } = useUser();
    const [showClientModal, setShowClientModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCModal, setShowCModal] = useState(false);
    const { authURL, clientID } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    // ! Use States for Buinsess Data ============================================
    const [base64Image, setBase64Image] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [businessPhone, setbusinessPhone] = useState("");
    const [businessEmail, setbusinessEmail] = useState("");
    const [businessAddress, setBusinessAddress] = useState("");

    // ! Use States for Client Data ===============================================
    const [clientData, setClientData] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientName, setclientName] = useState("");
    const [clientPhone, setclientPhone] = useState("");
    const [clientEmail, setclientEmail] = useState("");
    const [clientAddress, setclientAddress] = useState("");

    const [items, setItems] = useState([
        { id: 1, productName: '', quantity: 0, discount: 0, tax: 0, unitPrice: 0, total: 0 }
    ]);

    const [paymentOption, setPaymentOption] = useState('noStatus');

    const handleChange = (event) => {
        setPaymentOption(event.target.value);
    }

    // ! Use States for Currency Data ===============================================
    const [currencySymbol, setCurrencySymbol] = useState("$");
    const currencies = ["Rs", "$", "€", "£", "¥"];

    // ! Use States for Invoice Info Data ===============================================
    let generatedID = `QUOTE-${Math.floor(Math.random() * 9000) + 1000}`;
    const [invoiceId, setInvoiceId] = useState(generatedID);

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const [invoiceDate, setInvoiceDate] = useState(formattedDate);
    const [dueDate, setDueDate] = useState(formattedDate);

    const [invoiceTitle, setInvoiceTitle] = useState("");
    const [invoiceNote, SetinvoiceNote] = useState("");
    const [termsConditions, settandC] = useState("");


    const [selectedClientName, setSelectedClientName] = useState('');

    // ! Funtionality code for Table Rows ===============================================
    const [rows, setRows] = useState([{
        productName: "",
        quantity: 0,
        discount: 0,
        tax: 0,
        unitPrice: 0,
        total: 0,
    }]);

    const addRow = () => {
        const newItem = {
            id: items.length + 1,
            productName: '',
            quantity: 0,
            discount: 0,
            tax: 0,
            unitPrice: 0,
            total: 0
        };
        setItems([...items, newItem]);
    };

    const deleteRow = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    // ! Funtionality code for Total ===============================================
    const calculateTotalItem = (item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const subtotal = quantity * unitPrice;

        const taxPercentage = parseFloat(item.tax) || 0;
        const discountPercentage = parseFloat(item.discount) || 0;
        const discount = (discountPercentage / 100) * subtotal;
        const amountAfterDiscount = subtotal - discount;
        const tax = (taxPercentage / 100) * amountAfterDiscount;

        const total = subtotal + tax - discount;
        return total;  // Return as a number
    };

    const calculateSubtotal = () => {
        return items.reduce((acc, item) => acc + (item.quantity * item.unitPrice), 0);
    };

    const calculateTotalDiscount = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;
            const discountPercentage = parseFloat(item.discount) || 0;
            const discount = (discountPercentage / 100) * subtotal;
            return acc + discount;
        }, 0);
    };

    const calculateTotalTax = () => {
        return items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;
            const taxPercentage = parseFloat(item.tax) || 0;
            const tax = (taxPercentage / 100) * subtotal;
            return acc + tax;
        }, 0);
    };

    const calculateTotals = (items) => {
        return items.reduce(
            (totals, item) => {
                const quantity = parseFloat(item.quantity) || 0;
                const unitPrice = parseFloat(item.unitPrice) || 0;
                const subtotal = quantity * unitPrice;

                const discountPercentage = parseFloat(item.discount) || 0;
                const discount = (discountPercentage / 100) * subtotal;

                // Amount after applying discount
                const amountAfterDiscount = subtotal - discount;

                const taxPercentage = parseFloat(item.tax) || 0;
                const tax = (taxPercentage / 100) * amountAfterDiscount;

                totals.subtotal += subtotal;
                totals.discount += discount;
                totals.tax += tax;
                totals.total += amountAfterDiscount + tax;

                return totals;
            },
            { subtotal: 0, tax: 0, discount: 0, total: 0 }
        );
    };

    const validateAndFormatValue = (value) => {
        if (!value) return '';

        // Ensure value has at most 10 digits including 2 decimal places
        const maxDigits = 10;
        const maxDecimals = 2;

        let [integerPart, decimalPart] = value.split('.');

        if (integerPart.length > maxDigits - maxDecimals) {
            integerPart = integerPart.slice(0, maxDigits - maxDecimals);
        }

        if (decimalPart && decimalPart.length > maxDecimals) {
            decimalPart = decimalPart.slice(0, maxDecimals);
        }

        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    };

    const handleInputChange = (index, field, value) => {
        const validatedValue = field === 'productName' ? value : validateAndFormatValue(value);

        const updatedItems = [...items];
        updatedItems[index] = {
            ...updatedItems[index],
            [field]: field === 'productName' ? validatedValue : parseFloat(validatedValue) || 0,
        };

        // Update the total for the item
        updatedItems[index].total = calculateTotalItem(updatedItems[index]);

        // Recalculate totals for all items
        const updatedItemsWithTotals = updatedItems.map(item => ({
            ...item,
            total: calculateTotalItem(item),
        }));

        setItems(updatedItemsWithTotals);
    };

    const calculateTotalAmount = () => {
        return (calculateSubtotal() + calculateTotalTax()) - calculateTotalDiscount();
    };

    const validateItems = () => {
        let hasTaxErrors = false;
        let hasDiscountErrors = false;
        let hasProductNameErrors = false;

        items.forEach(item => {
            // Parse and calculate values
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const subtotal = quantity * unitPrice;
            const taxPercentage = parseFloat(item.tax) || 0;
            const discountPercentage = parseFloat(item.discount) || 0;
            const tax = (taxPercentage / 100) * subtotal;
            const discount = (discountPercentage / 100) * subtotal;

            // Check for tax errors
            if (tax > subtotal) {
                hasTaxErrors = true;
                toast.error(`Tax for item ${item.productName} cannot be greater than 100%`);
            }

            // Check for discount errors
            if (discount > subtotal) {
                hasDiscountErrors = true;
                toast.error(`Discount for item ${item.productName} cannot be greater than 100%`);
            }

            // Check for missing product name
            if (!item.productName) {
                hasProductNameErrors = true;
                toast.error("Product Name is required for all items.");
            }
        });

        // Return true if there are no errors
        return !(hasTaxErrors || hasDiscountErrors || hasProductNameErrors);
    };

    const totals = calculateTotals(items);

    // ! Fetching Buisness & Client Data ==========================================
    const postFeatureLog = async () => {
        const postData = {
            userID: user.primaryEmailAddress.emailAddress,
            featureName: "Quotation Reciept",
            featureType: "Premium",
            featureCount: 1
        }

        try {
            const response = await fetch(`${authURL}/feature-log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Feature log posted successfully:', result);
        } catch (error) {
            console.error('Error posting feature log:', error);
        }
    };

    // ! Use States for Signature Data ============================================
    const [signature, setSignature] = useState("");
    const [signatureLabel, setSignatureLabel] = useState("Signature");
    const [signBox, setSginBox] = useState(false);

    const sigCanvas = useRef({});
    const clear = () => {
        sigCanvas.current.clear();
    };
    const save = () => {
        if (sigCanvas.current.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            handleSaveSignature(dataURL);
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("item show")
            const reader = new FileReader();
            reader.onloadend = () => {
                setSignature(reader.result);
            };
            reader.readAsDataURL(file);
        }
        event.target.value = '';
    };

    const handleSaveSignature = (dataURL) => {
        console.log("Data URL received from SignaturePad:", dataURL);
        setSignature(dataURL);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted, signature Data URL:", signature);
        // Handle form submission and save signature data URL
    };
    const handleRemoveSignature = () => {
        setSginBox(false);
    };
    const handleLabelChange = (e) => {
        setSignatureLabel(e.target.value);
    };


    // ! Fetching Buisness & Client Data ==========================================
    const fetchBuisnessData = async () => {
        try {
            const response = await fetch(
                `${authURL}/get-buisness-info?userID=${user.primaryEmailAddress.emailAddress}`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const res = await response.json();
            if (res.data.businessName) {
                setBase64Image(res.data.base64Image);
                setBusinessName(res.data.businessName);
                setBusinessAddress(res.data.businessAddress);
                setbusinessEmail(res.data.businessEmail);
                setbusinessPhone(res.data.businessPhone);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Buisness data:', error);
            setLoading(false);
        }
    };

    const fetchClientData = async () => {
        try {
            const response = await fetch(
                `${authURL}/get-client?userID=${user.primaryEmailAddress.emailAddress}`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const res = await response.json();
            setClientData(res);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClientChange = (event) => {
        const selectedClientName = event.target.value;
        setSelectedClientName(selectedClientName); // Update the state with the selected value

        if (selectedClientName) {
            const client = clientData.find((c) => c.clientName === selectedClientName);
            setSelectedClient(client);
            setclientName(client.clientName);
            setclientPhone(client.clientPhone);
            setclientEmail(client.clientEmail);
            setclientAddress(client.clientAddress);
            console.log(client);
            clientID(client._id);
        } else {
            resetClientData();
        }
    };

    const resetClientData = () => {
        setSelectedClient(null);
        setclientName('');
        setclientPhone('');
        setclientEmail('');
        setclientAddress('');
    };

    const closeModal = () => {
        resetClientData();
        setSelectedClientName('');
        setShowCModal(false);
    };

    const QuotesObj = {
        userID: user.primaryEmailAddress.emailAddress,
        QuotesId: invoiceId,
        businessName,
        businessEmail,
        base64Image,
        businessPhone,
        businessAddress,
        clientName,
        clientEmail,
        clientPhone,
        clientAddress,
        currencySymbol,
        QuotesTitle: invoiceTitle,
        QuotesDate: invoiceDate,
        dueDate: paymentOption,
        items,
        QuotesNote: invoiceNote,
        termsConditions,
        signature,
        signatureLabel,
    }

    const generateInvoice = async () => {
        console.log(QuotesObj);

        // Validate items
        if (!validateItems()) {
            return; // Prevent saving if validation fails
        }

        // Additional validation
        if (!selectedClient) {
            toast.error("Select a client from the list");
            return;
        }

        if (!clientName) {
            toast.error("Name of the Client is required");
            return;
        }

        if (!invoiceTitle) {
            toast.error("Invoice Title is required");
            return;
        }

        if (dueDate < invoiceDate) {
            return toast.error("Due date cannot be earlier than invoice date.");
        }

        for (const item of items) {  // Use items here
            if (!item.productName) {
                toast.error("Product Name is required for all items");
                return; // Exit if validation fails
            }
            if (!item.quantity || item.quantity <= 0) {
                toast.error("Product Quantity is required and must be greater than 0");
                return; // Exit if validation fails
            }
            if (!item.unitPrice || item.unitPrice <= 0) {
                toast.error("Product Unit Price is required and must be greater than 0");
                return; // Exit if validation fails
            }
        }

        try {
            const response = await fetch(`${authURL}/create-Quotes`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(QuotesObj),
            });

            if (response.status === 400) {
                toast.error('Quotes with this ID already exists');
            } else if (response.status === 201) {
                toast.success("Quotes generated successfully!");
                navigate(`/home/generate-docx/quotes-form/quotes-template?QuotesId=${invoiceId}`);
                return await response.json();
            } else if (response.status === 409) {
                toast.error("Fill all the required fields above!");
            }
        } catch (error) {
            toast.error("Internal Server Error Occurred!");
            console.log(error);
        }
    }

    // ! ====================================================================
    useEffect(() => {
        fetchClientData();
        fetchBuisnessData();
    }, [fetchBuisnessData, fetchClientData]);

    const redirect = () => {
        navigate('/home/generate-docx/invoice-data/invoice-history')
    }

    return (
        <div className="my-32 md:max-w-4xl xl:max-w-6xl mx-auto">

            <div className='flex items-start justify-between'>
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Generate Quotes Reciept
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Precision Quotes, Profitable Deals
                    </p>
                </div>
            </div>

            <div className="flex items-end justify-end w-full space-x-4 mb-4">
                <button
                    onClick={generateInvoice}
                    className="px-8 py-3 mt-4 rounded bg-gradient-to-b from-emerald-500 to-emerald-600 text-white focus:ring-2 focus:ring-emerald-400 hover:shadow-xl transition duration-200">
                    Generate Quote Reciept
                </button>
            </div>

            <div className=" h-full bg-slate-100 shadow-sm border-2 border-slate-200 p-8">
                <div className="topDiv flex items-start justify-between w-full space-x-4">
                    <div className="flex flex-col items-center justify-center h-full">
                        {loading ? (
                            // Show loading if any details are missing or during the initial 2 seconds
                            <div className="loader m-8">Loading...</div>
                        ) : (
                            businessName ? (
                                <div className="flex flex-col items-start h-full justify-start text-start">
                                    <div className="flex items-start justify-start space-x-4">
                                        {base64Image && (
                                            <img
                                                src={base64Image}
                                                alt="Business"
                                                className="w-full min-h-[8rem] max-h-[10rem] min-w-[14rem] max-w-[16rem] object-contain"
                                            />
                                        )}
                                        <button onClick={() => setShowModal(true)} className="text-blue-600">
                                            <i className="fa-duotone fa-user-pen fa-xl mr-1"></i>
                                        </button>
                                    </div>
                                    <h1 className="text-4xl font-semibold mt-4">{businessName}</h1>
                                    <h1>{businessEmail}</h1>
                                    <h1>{businessPhone}</h1>
                                    <h1>{businessAddress}</h1>
                                </div>
                            ) : (
                                // Show button if all details are empty
                                <button onClick={() => setShowModal(true)} className="p-4 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white">
                                    Add New Business Details
                                </button>
                            )
                        )}
                    </div>

                    <div className="flex flex-col items-start justify-start w-1/3">
                        <h1 className="text-[2.5rem] w-full uppercase tracking-wide mb-4">quote reciept</h1>
                        <div className="w-full">
                            <label className="leading-7 text-gray-600 text-start">Quote Reciept ID</label><span className="text-red-600">*</span>
                            <input
                                type="text"
                                id="invoiceId"
                                value={invoiceId}
                                onChange={(e) => {
                                    let cleanedValue = e.target.value;

                                    // Regular expression to allow only alphanumeric characters and hyphens
                                    const validInput = /^[a-zA-Z0-9-]*$/;

                                    // Check if input starts with a hyphen
                                    if (cleanedValue.startsWith('-')) {
                                        toast.error("Quote ID cannot start with a hyphen.");
                                        return; // Prevent updating state
                                    }

                                    // Ensure input contains only valid characters
                                    if (!validInput.test(cleanedValue)) {
                                        toast.error("Quote ID can only contain alphanumeric characters and hyphens.");
                                        return; // Prevent updating state
                                    }

                                    // Update state if all checks pass
                                    setInvoiceId(cleanedValue);
                                }}
                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                placeholder="Quote ID"
                                maxLength={18}
                                required
                            />
                            <label className="leading-7 text-xs text-gray-500">This is auto generated Quote Reciept ID, You may change that.</label>
                        </div>
                    </div>
                </div>

                <hr className="my-4 border-t-2 rounded-full" />

                <div className="flex w-full space-x-4">
                    <div className="w-full">
                        <label className="leading-7 text-gray-600">Client</label><span className="text-red-600">*</span>

                        <div className="flex space-x-4 h-[40px] w-full">
                            <select
                                className="w-full px-2 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                                onChange={handleClientChange}
                                value={selectedClientName} // Controlled value
                            >
                                <option value="">Select a client</option>
                                {clientData.map(client => (
                                    <option key={client._id} value={client.clientName}>
                                        {client.clientName}
                                    </option>
                                ))}
                            </select>

                            <button
                                onClick={() => setShowClientModal(true)}
                                className="px-4 py-1 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">
                                <i className="fa-duotone fa-circle-plus"></i>
                            </button>
                        </div>

                        {selectedClient && (
                            <button
                                onClick={() => setShowCModal(true)}
                                className="leading-7 text-xs text-blue-500 underline"
                            >
                                Click to Manage Client Details
                            </button>
                        )}
                    </div>

                    <div className="w-full">
                        <label className="leading-7 text-gray-600">Payment Option</label>
                        <select value={paymentOption} onChange={handleChange} className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                            <option value="no method chosen">Select Payment Option</option>
                            <option value="via Cash">via Cash</option>
                            <option value="via Visa Card">via Visa Card</option>
                            <option value="via MasterCard">via Mastercard</option>
                        </select>
                    </div>

                    <div className="w-full">
                        <label className="leading-7 text-gray-600">Quote Reciept Title</label><span className="text-red-600">*</span>
                        <input
                            type="text"
                            maxLength={28}
                            value={invoiceTitle}
                            onChange={(e) => {
                                let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                // Prevent leading spaces
                                if (cleanedValue.startsWith(' ')) {
                                    cleanedValue = cleanedValue.trimStart();
                                }
                                setInvoiceTitle(cleanedValue)
                            }}
                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>

                    <div className="w-full">
                        <label className="leading-7 text-gray-600">Quote Reciept Date</label><span className="text-red-600">*</span>
                        <input type="date" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        <label className="leading-7 text-xs text-gray-500">The default date is set to today's date..</label>
                    </div>

                </div>

                <hr className="my-4 border-t-2 rounded-full" />

                <div className="flex items-end justify-end space-x-4 my-4">
                    <label className="leading-7 text-gray-600">Currency</label>
                    <select
                        value={currencySymbol}
                        onChange={(e) => setCurrencySymbol(e.target.value)}
                        className="w-[80px] h-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    >
                        {currencies.map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>
                </div>

                {/* !Copy This */}
                <div className="flex w-full space-x-4">
                    <div className="w-full overflow-x-auto">
                        <table
                            className="w-full text-left border border-separate rounded border-slate-200"
                            cellSpacing="2"
                        >
                            <thead>
                                <tr>
                                    <th className="h-12 px-6 text-sm font-medium text-center border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">#</th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Product Name<span className="text-red-600">*</span></th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Quantity<span className="text-red-600">*</span></th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Discount <span className="text-red-600 font-semibold">%</span></th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Tax <span className="text-red-600 font-semibold">%</span></th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Unit Price<span className="text-red-600">*</span></th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Total</th>
                                    <th className="h-12 px-6 text-sm font-medium border-l first:border-l-0 border-b stroke-slate-700 text-slate-700 bg-white">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className="h-16 text-sm text-center px-2 border-l first:border-l-0 border-b border-gray-300 text-slate-700 bg-white">{index + 1}</td>
                                        <td className="cellOne h-16 text-sm px-2 border-l first:border-l-0 border-b border-gray-300 text-slate-700">
                                            <input
                                                type="text"
                                                maxLength={25}
                                                value={item.productName}
                                                onChange={(e) => {
                                                    let cleanedValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                                    if (cleanedValue.startsWith(' ')) {
                                                        cleanedValue = cleanedValue.trimStart();
                                                    }
                                                    handleInputChange(index, "productName", cleanedValue);
                                                }}
                                                required
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </td>
                                        <td className="cellTwo h-16 px-2 text-sm border-l border-b first:border-l-0 border-gray-300 text-slate-700">
                                            <input
                                                type="number"
                                                step="0.5"
                                                min="1"
                                                max="99999999.99" // Limits value to 10 digits with 2 decimal places
                                                value={item.quantity}
                                                onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                                onBlur={(e) => {
                                                    let value = e.target.value;
                                                    if (value === "") {
                                                        handleInputChange(index, "quantity", 0);
                                                    }
                                                }}
                                                required
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </td>
                                        <td className="cellTwo h-16 px-2 text-sm border-l first:border-l-0 border-b border-gray-300 text-slate-700">
                                            <input
                                                type="number"
                                                step="0.5"
                                                min="0"
                                                max="99.99" // Limits value to 10 digits with 2 decimal places
                                                value={item.discount}
                                                onChange={(e) => handleInputChange(index, "discount", e.target.value)}
                                                onBlur={(e) => {
                                                    let value = e.target.value;
                                                    if (value === "") {
                                                        handleInputChange(index, "discount", 0);
                                                    }
                                                }}
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </td>
                                        <td className="cellTwo h-16 px-2 text-sm border-l first:border-l-0 border-b border-gray-300 text-slate-700">
                                            <input
                                                type="number"
                                                step="0.5"
                                                min="0"
                                                max="99999999.99" // Limits value to 10 digits with 2 decimal places
                                                value={item.tax}
                                                onChange={(e) => handleInputChange(index, "tax", e.target.value)}
                                                onBlur={(e) => {
                                                    let value = e.target.value;
                                                    if (value === "") {
                                                        handleInputChange(index, "tax", 0);
                                                    }
                                                }}
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </td>
                                        <td className="cellTwo h-16 px-2 text-sm border-l first:border-l-0 border-b border-gray-300 text-slate-700">
                                            <input
                                                type="number"
                                                step="0.5"
                                                min="0"
                                                max="99999999.99" // Limits value to 10 digits with 2 decimal places
                                                value={item.unitPrice}
                                                onChange={(e) => handleInputChange(index, "unitPrice", e.target.value)}
                                                onBlur={(e) => {
                                                    let value = e.target.value;
                                                    if (value === "") {
                                                        handleInputChange(index, "unitPrice", 0);
                                                    }
                                                }}
                                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </td>
                                        <td className="cellTwo h-16 px-2 text-center text-sm border-l first:border-l-0 border-b border-gray-300 text-slate-700 bg-white">
                                            <code className="font-semibold">{item.total.toFixed(2)}</code>
                                        </td>
                                        <td className="h-16 flex items-center justify-center text-sm border-l first:border-l-0 border-b border-gray-300 text-slate-700 bg-white">
                                            <button onClick={() => deleteRow(index)} className="w-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3h6v3"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <button
                    onClick={addRow}
                    className="my-4 mx-2 px-4 py-2 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 transition duration-200"
                >
                    <i className="fa-duotone fa-plus-circle text-white mr-2 "></i> ADD
                    Row
                </button>

                <div className="flex flex-col items-end justify-end space-y-4">
                    <div className="text-end">
                        <div className="p-6">
                            <div className="grid gap-2">
                                <div className="flex items-center justify-between bg-gray-200 p-4">
                                    <span className='text-md'>Subtotal</span>
                                    <code className="font-bold text-xl ml-24">
                                        {currencySymbol}
                                        {new Intl.NumberFormat('en-US', {
                                            minimumFractionDigits: 2
                                        }).format(totals.subtotal.toFixed(2))}
                                    </code>
                                </div>

                                <div className="flex items-center justify-between bg-green-300 p-4">
                                    <span className='text-md'>Total Discounted Amount</span>
                                    <code className="font-bold text-xl ml-24">
                                        {currencySymbol}
                                        {new Intl.NumberFormat('en-US', {
                                            minimumFractionDigits: 2
                                        }).format(totals.discount.toFixed(2))}
                                    </code>
                                </div>

                                <div className="flex items-center justify-between bg-red-300 p-4">
                                    <span className='text-md'>Total Tax Amount</span>
                                    <code className="font-bold text-xl">
                                        {currencySymbol}
                                        {new Intl.NumberFormat('en-US', {
                                            minimumFractionDigits: 2
                                        }).format(totals.tax.toFixed(2))}
                                    </code>
                                </div>

                                <div data-orientation="horizontal" role="none" className="shrink-0 bg-border h-[1px] bg-gray-400 w-full"></div>

                                <div className="flex items-center justify-between bg-blue-300 p-4">
                                    <span className='text-md'>Total</span>
                                    <code className="font-bold text-xl ml-24">
                                        {currencySymbol}
                                        {new Intl.NumberFormat('en-US', {
                                            minimumFractionDigits: 2
                                        }).format(totals.total.toFixed(2))}
                                    </code>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* !Copy This */}


                <div className="flex flex-col items-end w-full">
                    {/* Signature Form */}
                    {signBox ? (
                        <form onSubmit={handleSubmit} className="p-4 border border-gray-300 rounded-lg w-96">
                            <div className="flex justify-between">
                                <div className="text-xl font-semibold mb-4">
                                    Signature
                                    <span className="text-lg text-gray-400">{signature && " [Preview]"}</span>
                                </div>
                                <button
                                    type="button"
                                    style={{
                                        background: 'gray',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '50%',
                                        width: '25px',
                                        height: '25px',
                                        cursor: 'pointer',
                                        zIndex: '1',
                                    }}
                                    onClick={handleRemoveSignature}>
                                </button>
                            </div>
                            {signature ? (
                                <div className="border-2 border-dashed border-gray-400 rounded-lg h-32 flex justify-center items-center">
                                    <img src={signature} alt="signature" style={{ width: '200px', height: '100px' }} />
                                </div>
                            ) : (
                                <div className="mb-4">
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        penColor='black'
                                        canvasProps={{ width: 340, height: 200, className: 'border-2 border-dashed border-gray-500 mb-2 rounded-lg' }}
                                    />
                                    <button onClick={clear} className='px-4 py-2 mr-2 bg-red-500 text-white rounded hover:bg-gray-700 focus:outline-none'>
                                        <i className="fa-duotone fa-cancel "></i>
                                    </button>
                                    <button onClick={save} className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none'>
                                        <i className="fa-duotone fa-floppy-disk "></i>
                                    </button>
                                </div>
                            )}
                            <div className="flex justify-between items-center my-4">
                                <button type="button" onClick={() => setSignature("")} className="flex items-center text-gray-600">
                                    <i className="fa-duotone fa-pen mr-2 "></i>
                                    Use Signature Pad
                                </button>
                                <label htmlFor="file-upload" className="flex items-center text-gray-600 cursor-pointer">
                                    <i className="fa-duotone fa-upload mr-2 "></i>
                                    <span>Upload Signature</span>
                                    <input id="file-upload" type="file" accept="image/*" onChange={handleFileChange} className="hidden" />
                                </label>
                            </div>
                            <div>
                                <label htmlFor="signatureLabel" className="block text-sm font-medium text-gray-700 mb-2">Add Signature Label</label>
                                <input
                                    type="text"
                                    id="signatureLabel"
                                    value={signatureLabel}
                                    onChange={handleLabelChange}
                                    className="mt-1 block w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </form>
                    ) : (
                        <button onClick={() => setSginBox(true)} className="px-14 py-3 border-2 border-dashed border-gray-400">Add Signature</button>
                    )}

                    {/* Dummy Paid Image Section */}
                    <div className="flex items-center mt-4 space-x-4">
                        {signature && (
                            <div className="flex flex-col items-center justify-end mb-4 p-2">
                                <img src={signature} alt="signature" style={{ width: '200px', height: '100px' }} />
                                <div className="mt-4 border-t-2 border-gray-600 w-full">
                                    <center>{signatureLabel}</center>
                                </div>
                            </div>
                        )}
                    </div>
                </div>


                <hr className="my-4 border-t-2 rounded-full" />

                {/* Repalce this */}
                <div className="flex space-x-4 h-full mb-4">
                    <div className="h-full w-full">
                        <h1 className="text-lg text-gray-600">Terms and Conditions</h1>
                        <textarea
                            className="max-h-64 w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            value={termsConditions}
                            maxLength={200}
                            onChange={(e) => {
                                let newValue = e.target.value;

                                // Remove leading spaces
                                newValue = newValue.trimStart();

                                // Remove special characters (keeping letters, numbers, spaces, and basic punctuation)
                                newValue = newValue.replace(/[^a-zA-Z0-9 .,!?\-()\[\]{}]/g, '');

                                // Set the new value
                                settandC(newValue);
                            }}
                        />

                    </div>

                    <div className="h-full w-full">
                        <h1 className="text-lg text-gray-600">Invoice Note</h1>
                        <textarea
                            className="max-h-64 w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            value={invoiceNote}
                            maxLength={200}
                            onChange={(e) => {
                                let newValue = e.target.value;

                                // Remove leading spaces
                                newValue = newValue.trimStart();

                                // Remove special characters (keeping letters, numbers, spaces, and basic punctuation)
                                newValue = newValue.replace(/[^a-zA-Z0-9 .,!?\-()\[\]{}]/g, '');

                                // Set the new value
                                SetinvoiceNote(newValue);
                            }}
                        />
                    </div>
                </div>
                {/* =============== */}

                <footer >
                    <hr className="mt-16 mb-4 border-t-2 rounded-full" />

                    <div class="mt-4 flex-grow-1">
                        <h4 class="text-lg mb-2  font-semibold text-gray-600  ">
                            Regarding Reciept!
                        </h4>

                        <ul class="max-w-xlg space-y-1 text-gray-500 list-inside dark:text-gray-500">
                            <li class="flex items-center">
                                <svg
                                    class="w-3.5 h-3.5 me-2 text-blue-500 dark:text-blue-400 flex-shrink-0"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9 7h2v2H9V7zm1-5a9 9 0 100 18 9 9 0 000-18zM10 17a7 7 0 110-14 7 7 0 010 14zm-1-8h2v6H9v-6z" />
                                </svg>
                                Follow the table sequence when entering values.
                            </li>
                            <li class="flex items-center">
                                <svg
                                    class="w-3.5 h-3.5 me-2 text-blue-500 dark:text-blue-400 flex-shrink-0"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9 7h2v2H9V7zm1-5a9 9 0 100 18 9 9 0 000-18zM10 17a7 7 0 110-14 7 7 0 010 14zm-1-8h2v6H9v-6z" />
                                </svg>
                                Tax calculation is based on real scenario{" "}
                            </li>
                            <li class="flex items-center">
                                <svg
                                    class="w-3.5 h-3.5 me-2 text-blue-500 dark:text-blue-400 flex-shrink-0"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9 7h2v2H9V7zm1-5a9 9 0 100 18 9 9 0 000-18zM10 17a7 7 0 110-14 7 7 0 010 14zm-1-8h2v6H9v-6z" />
                                </svg>
                                Tax is calculated on the discounted price, not the original
                                price.
                            </li>
                            <li class="flex items-center">
                                <svg
                                    class="w-3.5 h-3.5 me-2 text-blue-500 dark:text-blue-400 flex-shrink-0"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9 7h2v2H9V7zm1-5a9 9 0 100 18 9 9 0 000-18zM10 17a7 7 0 110-14 7 7 0 010 14zm-1-8h2v6H9v-6z" />
                                </svg>
                                Both tax and discount are calculated as percentages and
                                displayed as amounts.
                            </li>
                        </ul>
                    </div>

                    <hr className="mt-4 mb-4 border-t-2 rounded-full" />

                    <div class="mt-4 flex-grow-1">
                        <h4 class="text-lg font-semibold text-gray-800  ">Thank you!</h4>
                        <p class="text-gray-500">
                            This Quote Reciept is generated by <span className="text-blue-600 underline">www.quickdoc.app</span> <br /> If you have any questions concerning this reciept, use the following
                            contact information:
                        </p>
                        <div class="mt-2">
                            <p class="block text-sm font-medium text-gray-800  ">
                                info@bzsoftech.com
                            </p>
                            <p class="block text-sm font-medium text-gray-800  ">
                                {businessPhone}
                            </p>
                        </div>
                    </div>
                </footer>

            </div>

            <ClientModal
                isVisible={showClientModal}
                onClose={() => setShowClientModal(false)}
            />
            <BuisnessInfoModal isVisible={showModal} onClose={() => setShowModal(false)} />
            <CModal isVisible={showCModal} onClose={closeModal} />

        </div>
    );
}

export default Quotes;
