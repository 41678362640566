import React from "react";
import "../App.css";
import HeroImg from "../assets/imgs/ss.png";
import ss from "../assets/imgs/ss2.png";
import { Helmet } from "react-helmet-async";

function About() {
  return (
    <>
      <Helmet>
        <title>About QuickDoc - Transforming Document Management</title>
        <meta
          name="description"
          content="QuickDoc is committed to empowering businesses with efficient, secure document generation and management tools. Learn about our mission, values, and dedication to simplifying document workflows."
        />
        <meta
          name="keywords"
          content="About QuickDoc, document management mission, document generation tools, secure document handling, QuickDoc values"
        />
        <meta
          property="og:title"
          content="About QuickDoc - Document Management Solutions"
        />
        <meta
          property="og:description"
          content="Discover QuickDoc's journey in creating innovative document solutions for businesses. We offer tools to streamline document creation, storage, and management."
        />
        <meta
          property="og:image"
          content="https://www.quickdoc.app/static/media/ss.472eb145becf50cdfedd.png"
        />
        <meta property="og:url" content="https://www.quickdoc.app/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About QuickDoc - Transforming Document Management"
        />
        <meta
          name="twitter:description"
          content="Learn about QuickDoc's commitment to empowering businesses with secure and efficient document solutions."
        />
        <meta
          name="twitter:image"
          content="https://www.quickdoc.app/static/media/ss.472eb145becf50cdfedd.png"
        />
      </Helmet>
      <div class="max-w-7xl mx-auto flex flex-col mt-40 items-start justify-center">
        <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
          <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
            About Us
          </span>
          <h1 className="font-light  capitalize  md:text-5xl">
            Get to know{" "}
            <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              About Us
            </span>{" "}
            !
          </h1>
          <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
            {" "}
            In the world of architecture or organization, structure provides the
            backbone for a purposeful and harmonious existence.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto flex flex-col mt-12 items-start justify-center">
        <div className="flex items-start justify-start  lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
          <div className="w-full my-auto lg:w-6/12">
            <div class="lg:ml-6 lg:col-start-2 lg:max-w-2xl">
              <div>
                <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 mb-4 rounded-full">
                  Securing your Documets
                </span>
                <h1 className="font-light  capitalize my-4  md:text-2xl">
                  We're here to make great{" "}
                  <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                    document management
                  </span>{" "}
                  accessible and delightful for everyone.
                </h1>
                <p class="text-md font-normal text-gray-500 max-w-3xl mx-auto text-start">
                  {" "}
                  In the world of architecture or organization, structure
                  provides the backbone for a purposeful and harmonious
                  existence.
                </p>
              </div>

              <ul class="mt-8 space-y-3 font-medium">
                <li class="flex items-start lg:col-span-1">
                  <div class="flex-shrink-0">
                    <svg
                      class="w-5 h-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 leading-5 text-gray-600">
                    QuickDoc: Your all-in-one PDF solution.
                  </p>
                </li>
                <li class="flex items-start lg:col-span-1">
                  <div class="flex-shrink-0">
                    <svg
                      class="w-5 h-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 leading-5 text-gray-600">
                    Effortless PDF management. Try QuickDoc.
                  </p>
                </li>
                <li class="flex items-start lg:col-span-1">
                  <div class="flex-shrink-0">
                    <svg
                      class="w-5 h-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 leading-5 text-gray-600">
                    Create, edit, manage PDFs with QuickDoc.
                  </p>
                </li>
                <li class="flex items-start lg:col-span-1">
                  <div class="flex-shrink-0">
                    <svg
                      class="w-5 h-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <p class="ml-3 leading-5 text-gray-600">
                    QuickDoc: Delightful document control.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full lg:w-6/12 border-2 rounded-xl">
            <img src={HeroImg} alt="er" className="rounded-xl" />
          </div>
        </div>
      </div>

      <section class="max-w-7xl mx-auto py-24 ">
        <div class="mx-auto max-w-8xl">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-lg mx-auto md:max-w-2xl lg:max-w-full">
            <div class="relative w-full h-auto md:col-span-2">
              <div class="bg-gray-800 rounded-2xl flex  justify-between flex-row flex-wrap">
                <div class="p-5  xl:p-8 w-full md:w-1/2 ">
                  <h3 class="text-4xl text-white py-5 w-full xl:w-64">
                    Our Mission
                  </h3>
                  <p class="text-sm font-normal text-gray-300 w-full mb-8 xl:w-64">
                    Our mission is to provide exceptional PDF tools that
                    simplify document management for all users, from individuals
                    to businesses. We aim to deliver a user-friendly, efficient
                    platform that exceeds your expectations and ensures complete
                    satisfaction.
                  </p>
                </div>
                <div class="relative hidden h-auto md:w-1/2 md:block">
                  <img
                    src="https://pagedone.io/asset/uploads/1695028873.png"
                    alt="Header tailwind Section"
                    class="h-full ml-auto"
                  />
                </div>
              </div>
            </div>

            <div class="relative w-full h-auto">
              <div class="bg-indigo-500 rounded-2xl p-5  xl:p-8 h-full">
                <div class="block">
                  <i className="fa-solid fa-file-pdf text-white fa-xl"></i>
                </div>
                <h3 class="py-5 text-white text-lg font-bold xl:text-xl">
                  Powerfull Tools
                </h3>
                <p class="text-xs font-normal text-white mb-8">
                  Easily generate high-quality PDF documents from your content
                  with our seamless PDF generation tools having tons of variety!
                </p>
                <button class="py-2 px-5 border border-solid border-gray-300 rounded-full gap-2 text-xs text-white font-semibold flex items-center justify-between transition-all duration-500 hover:bg-white/5">
                  View More
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L3.58579 6.41421C4.25245 5.74755 4.58579 5.41421 4.58579 5C4.58579 4.58579 4.25245 4.25245 3.58579 3.58579L1 1"
                      stroke="white"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div class="relative w-full h-auto">
              <div class="bg-violet-500 rounded-2xl p-5 xl:p-8 h-full">
                <div class="block">
                  <i className="fa-solid fa-file-invoice text-white fa-xl"></i>
                </div>
                <h3 class="py-5 text-white text-lg font-bold xl:text-xl">
                  Document Generator
                </h3>
                <p class="text-xs font-normal text-white mb-8">
                  Manage your documents efficiently with our comprehensive
                  document management system, keeping your files organized and
                  accessible.
                </p>
                <button class="py-2 px-5 border border-solid border-gray-300 rounded-full gap-2 text-xs text-white font-semibold flex items-center justify-between transition-all duration-500 hover:bg-white/5">
                  View More
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L3.58579 6.41421C4.25245 5.74755 4.58579 5.41421 4.58579 5C4.58579 4.58579 4.25245 4.25245 3.58579 3.58579L1 1"
                      stroke="white"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="h-full">
        <div class="relative mx-auto">
          <div class="absolute inset-0 bottom-[32rem] bg-gradient-to-b from-indigo-700 via-blue-700 to-blue-600"></div>
          <div class="relative pt-20 mx-auto w-full max-w-screen-xl px-2 text-left sm:px-10">
            <div class="rounded-xl bg-white shadow-2xl shadow-blue-200 lg:flex">
              <div class="flex flex-col p-4 pb-6 sm:p-10 sm:pt-14 lg:w-2/5">
                <div class="flex-auto">
                  <div class="flex items-center justify-center flex-col gap-5 mb-14">
                    <h1 className="font-light  capitalize  md:text-3xl">
                      Revolutionary{" "}
                      <span className="underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                        Document Generator
                      </span>{" "}
                      Features!
                    </h1>
                    <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-start">
                      {" "}
                      In the world of architecture or organization, structure
                      provides the backbone for a purposeful and harmonious
                      existence.
                    </p>
                  </div>

                  <section>
                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-file-invoice fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Generate Invoices
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          The company has high expectations and using Orstanding
                          of expectations and performance.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-regular fa-globe-pointer fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Share via Link
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-print-magnifying-glass fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Save and Print
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>

                    <div class="group relative flex gap-x-5">
                      <div class="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
                        <div class="relative z-10 size-6 flex justify-center items-center">
                          <i class="fa-solid fa-rectangle-history-circle-user fa-2xl text-blue-600"></i>
                        </div>
                      </div>

                      <div class="grow pb-8 group-last:pb-0">
                        <h3 class="mb-1 text-xs text-gray-600">QuickDoc</h3>

                        <p class="font-semibold text-sm text-gray-800">
                          Track Invoices History
                        </p>

                        <p class="mt-1 text-sm text-gray-600">
                          This is an excellent company and the the growth I
                          think, but everyone has to. Great place to work.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="m-8 lg:w-3/5 hidden overflow-hidden rounded-xl sm:block">
                <img class="object-cover w-full h-full" src={ss} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-7xl py-24 px-4 sm:px-6 lg:px-8 mx-auto">
        {/* Grid */}
        <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center lg:justify-between">
          <div className="lg:col-span-5 lg:col-start-1">
            {/* Title */}
            <div className="mb-8">
              <h2 className="mb-2 text-3xl text-gray-800 font-bold lg:text-4xl">
                It's all about efficiency
              </h2>
              <p className="text-gray-600">
                We provide you with tools that can be set up in seconds. Our
                main focus is delivering fast and reliable document conversion
                and generation solutions.
              </p>
            </div>
            {/* End Title */}
            {/* Blockquote */}
            <blockquote className="relative">
              <svg
                className="absolute top-0 start-0 transform -translate-x-6 -translate-y-8 size-16 text-gray-200"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="currentColor"
                />
              </svg>
              <div className="relative z-10">
                <p className="text-xl italic text-gray-800">
                  Fantastic tools that make document management a breeze. Very
                  efficient and professional service.
                </p>
              </div>
              <footer className="mt-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="size-8 rounded-full"
                      src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                      alt="Image Description"
                    />
                  </div>
                  <div className="grow ms-4">
                    <div className="font-semibold text-gray-800">
                      Josh Grazioso
                    </div>
                    <div className="text-xs text-gray-500">
                      Director of Operations | Tech Solutions
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
            {/* End Blockquote */}
          </div>
          {/* End Col */}
          <div className="mt-10 lg:mt-0 lg:col-span-6 lg:col-end-13">
            <div className="space-y-6 sm:space-y-8">
              {/* List */}
              <ul className="grid grid-cols-2 divide-y-2 divide-x-2 divide-gray-200 overflow-hidden">
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    100k+
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    users - from small businesses to large enterprises
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    <svg
                      className="flex-shrink-0 size-5 text-blue-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m5 12 7-7 7 7" />
                      <path d="M12 19V5" />
                    </svg>
                    35%
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    reduction in document processing time
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    <svg
                      className="flex-shrink-0 size-5 text-blue-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m5 12 7-7 7 7" />
                      <path d="M12 19V5" />
                    </svg>
                    50%
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    boost in productivity for document handling
                  </p>
                </li>
                <li className="flex flex-col -m-0.5 p-4 sm:p-8">
                  <div className="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold text-gray-800 mb-2">
                    3x
                  </div>
                  <p className="text-sm sm:text-base text-gray-600">
                    faster processing than previous solutions
                  </p>
                </li>
              </ul>
              {/* End List */}
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </section>

      <section class="pb-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">
              Trusted by <span class="text-blue-600">3k+</span> businesses &
              professionals for reliable document generation
            </h2>
          </div>

          <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
            <div class="overflow-hidden bg-white rounded-md shadow">
              <div class="px-8 py-12">
                <div class="relative w-24 h-24 mx-auto">
                  <img
                    class="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/1/avatar-1.jpg"
                    alt="User profile photo"
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      class="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-7">
                  <p class="text-lg text-black">
                    “This tool has streamlined our document workflow entirely.
                    Generating receipts and quotes is now a breeze, and our
                    clients are impressed with the professional look of every
                    PDF.”
                  </p>
                </blockquote>
                <p class="text-base font-semibold tex-tblack mt-9">
                  Jenny Wilson
                </p>
                <p class="mt-1 text-base text-gray-600">
                  Operations Manager at Tech Solutions
                </p>
              </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md shadow">
              <div class="px-8 py-12">
                <div class="relative w-24 h-24 mx-auto">
                  <img
                    class="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/1/avatar-2.jpg"
                    alt="User profile photo"
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      class="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-7">
                  <p class="text-lg text-black">
                    “We use this tool daily for all our document needs, from
                    memos to complex reports. It's efficient, customizable, and
                    saves us hours every week.”
                  </p>
                </blockquote>
                <p class="text-base font-semibold tex-tblack mt-9">
                  Robert Fox
                </p>
                <p class="mt-1 text-base text-gray-600">
                  CEO at BrainWave Technologies
                </p>
              </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md shadow">
              <div class="px-8 py-12">
                <div class="relative w-24 h-24 mx-auto">
                  <img
                    class="relative object-cover w-24 h-24 mx-auto rounded-full"
                    src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/1/avatar-3.jpg"
                    alt="User profile photo"
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-7 h-7">
                    <svg
                      class="w-4 h-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-7">
                  <p class="text-lg text-black">
                    “Creating polished, editable docs has never been easier.
                    From invoices to client proposals, this app is our go-to for
                    all document generation needs.”
                  </p>
                </blockquote>
                <p class="text-base font-semibold tex-tblack mt-9">
                  Kristin Watson
                </p>
                <p class="mt-1 text-base text-gray-600">
                  Product Designer at Pixel Labs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
