import React from "react";
import { motion } from "framer-motion";
import { 
  Database, 
  FileText, 
  BarChart, 
  Users, 
  Workflow, 
  Shield,
  Search,
  Cloud,
  Clock,
  Share2,
  Layout,
  FileCog
} from "lucide-react";
import { Link } from "react-router-dom";

const ErpDmsSolutions = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto flex flex-col px-4 mt-20 md:mt-40 items-start justify-center">
        <div className="w-full flex items-center justify-center flex-col gap-3 md:gap-5 mb-8 md:mb-14">
          <span className="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
            Enterprise Solutions
          </span>
          <h1 className="w-full md:w-3/4 mx-auto text-center font-light text-3xl md:text-5xl px-4">
            Integrated
            <span className="block md:inline mt-2 md:mt-0 underline md:ml-2 font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              ERP & DMS Solutions
            </span>
            for Your Business
          </h1>
          <p className="text-base md:text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center px-4">
            Combine the power of Oracle APEX for ERP and advanced Document Management Systems 
            to streamline your business processes and enhance organizational efficiency.
          </p>
        </div>
      </div>

      <section className="relative pt-8 md:pt-16 lg:pt-20 overflow-hidden">
        <div className="absolute top-0 right-0 w-48 md:w-72 h-48 md:h-72 bg-blue-100 rounded-full filter blur-3xl opacity-20 -z-10" />
        <div className="absolute bottom-0 left-0 w-64 md:w-96 h-64 md:h-96 bg-purple-100 rounded-full filter blur-3xl opacity-20 -z-10" />

        <div className="relative container px-4 mx-auto">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-start gap-8">
              <div className="w-full lg:w-[60%]">
                <div className="max-w-xl mx-auto lg:mx-0">
                  <div className="flex flex-wrap items-center gap-2 mb-6">
                    <span className="inline-block py-1 px-3 text-xs font-semibold text-blue-900 bg-blue-100 rounded-full">
                      ORACLE APEX
                    </span>
                    <span className="inline-block py-1 px-3 text-xs font-semibold text-purple-900 bg-purple-100 rounded-full">
                      DMS SOLUTION
                    </span>
                  </div>

                  <h1 className="font-heading text-3xl md:text-4xl lg:text-6xl font-bold mb-8">
                    <span className="block bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                      Integrated Solutions
                    </span>
                    <span className="block text-2xl md:text-3xl lg:text-5xl text-gray-600 mt-2">
                      Powering Your
                    </span>
                    <span className="block pb-2 font-serif italic bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                      Digital Transformation
                    </span>
                  </h1>

                  <div className="space-y-6">
                    <p className="text-base md:text-lg lg:text-xl text-gray-600 leading-relaxed">
                      Experience seamless integration of Oracle APEX-based ERP and advanced DMS. 
                      Manage documents, automate workflows, and optimize business processes all in one place.
                    </p>

                    <div className="flex flex-col sm:flex-row gap-4">
                      <Link
                        to="/contact-us"
                        className="w-full sm:w-auto text-center px-8 py-4 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
                      >
                        Request Demo
                      </Link>
                    </div>

                    <div className="grid grid-cols-3 gap-2 md:gap-3 text-center">
                      <div className="p-2 md:p-3 bg-white rounded-lg shadow-sm">
                        <div className="text-xl md:text-2xl font-bold text-blue-600">
                          99.9%
                        </div>
                        <div className="text-xs md:text-sm text-gray-600">
                          System Uptime
                        </div>
                      </div>
                      <div className="p-2 md:p-3 bg-white rounded-lg shadow-sm">
                        <div className="text-xl md:text-2xl font-bold text-blue-600">
                          60%
                        </div>
                        <div className="text-xs md:text-sm text-gray-600">
                          Faster Processing
                        </div>
                      </div>
                      <div className="p-2 md:p-3 bg-white rounded-lg shadow-sm">
                        <div className="text-xl md:text-2xl font-bold text-blue-600">
                          50%
                        </div>
                        <div className="text-xs md:text-sm text-gray-600">
                          Cost Savings
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-[38%]">
                <div className="mx-auto lg:mr-0">
                  <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 mb-4">
                    <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-800 mb-4">
                      Integrated Features
                    </h3>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4 mb-4">
                      {[
                        {
                          icon: <Database className="w-5 h-5 text-blue-600" />,
                          title: "Oracle APEX ERP",
                          desc: "Custom business applications",
                        },
                        {
                          icon: <FileText className="w-5 h-5 text-blue-600" />,
                          title: "Document Management",
                          desc: "Centralized content storage",
                        },
                        {
                          icon: <Workflow className="w-5 h-5 text-blue-600" />,
                          title: "Workflow Automation",
                          desc: "Streamlined processes",
                        },
                        {
                          icon: <Search className="w-5 h-5 text-blue-600" />,
                          title: "Smart Search",
                          desc: "Advanced document retrieval",
                        },
                        {
                          icon: <Cloud className="w-5 h-5 text-blue-600" />,
                          title: "Cloud Integration",
                          desc: "Seamless connectivity",
                        },
                        {
                          icon: <FileCog className="w-5 h-5 text-blue-600" />,
                          title: "Version Control",
                          desc: "Document lifecycle management",
                        },
                      ].map((feature, index) => (
                        <div key={index} className="p-2 md:p-3 bg-gray-50 rounded-lg">
                          <div className="flex items-center space-x-2 mb-1">
                            {feature.icon}
                            <h4 className="font-medium text-sm md:text-base text-gray-900">
                              {feature.title}
                            </h4>
                          </div>
                          <p className="text-xs md:text-sm text-gray-600">
                            {feature.desc}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div className="p-3 md:p-4 bg-blue-50 rounded-lg border border-blue-100">
                      <p className="text-xs md:text-sm text-gray-600 leading-relaxed">
                        "The integrated ERP and DMS solution has revolutionized our document workflows 
                        and business processes. We've seen a 60% reduction in processing time and 
                        significant improvements in compliance management."
                        <span className="block mt-2 font-medium">
                          — Operations Director, Enterprise Solutions Inc.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErpDmsSolutions;