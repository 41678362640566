import React from "react";
import { 
  Code, 
  Link as LinkIcon, 
  Shield, 
  Workflow,
  Server,
  Cpu,
  Network,
  CloudCog,
  Lock,
  RefreshCw
} from "lucide-react";
import { Link } from "react-router-dom";

const ApiSolutions = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto flex flex-col px-4 mt-20 md:mt-40 items-start justify-center">
        <div className="w-full md:w-3/4 mx-auto flex items-center justify-center flex-col gap-3 md:gap-5 mb-8 md:mb-14">
          <span className="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
            API Solutions
          </span>
          <h1 className="w-full md:w-3/4 mx-auto font-light capitalize text-3xl md:text-5xl text-center px-4">
            Powerful REST APIs for
            <span className="block sm:inline underline mt-2 sm:mt-0 sm:ml-2 font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              Seamless Integration
            </span>
          </h1>
          <p className="text-base md:text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center px-4">
            Access our comprehensive suite of REST APIs to integrate DMS and ERP features 
            into any environment. Built for developers, designed for scalability.
          </p>
        </div>
      </div>

      <section className="relative pt-10 md:pt-16 lg:pt-20 overflow-hidden">
        <div className="absolute top-0 right-0 w-48 md:w-72 h-48 md:h-72 bg-blue-100 rounded-full filter blur-3xl opacity-20 -z-10" />
        <div className="absolute bottom-0 left-0 w-64 md:w-96 h-64 md:h-96 bg-purple-100 rounded-full filter blur-3xl opacity-20 -z-10" />

        <div className="relative container px-4 mx-auto">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-wrap items-start gap-8 lg:gap-4">
              {/* Left Column */}
              <div className="w-full lg:w-[60%]">
                <div className="max-w-xl mx-auto lg:mx-0">
                  <div className="flex flex-wrap items-center gap-2 mb-6">
                    <span className="inline-block py-1 px-3 text-xs font-semibold text-blue-900 bg-blue-100 rounded-full">
                      REST APIs
                    </span>
                    <span className="inline-block py-1 px-3 text-xs font-semibold text-purple-900 bg-purple-100 rounded-full">
                      DEVELOPER-FIRST
                    </span>
                  </div>

                  <h1 className="font-heading text-3xl sm:text-4xl md:text-6xl font-bold mb-8">
                    <span className="block w-full bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                      API Integration
                    </span>
                    <span className="block text-2xl sm:text-3xl md:text-5xl text-gray-600 mt-2">
                      Powering Your
                    </span>
                    <span className="block pb-2 font-serif italic bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                      Digital Ecosystem
                    </span>
                  </h1>

                  <div className="space-y-6">
                    <p className="text-base sm:text-lg md:text-xl text-gray-600 leading-relaxed">
                      Integrate our powerful APIs into your existing systems. From document management 
                      to workflow automation, our RESTful endpoints provide secure, scalable access 
                      to all core functionalities.
                    </p>

                    <div className="flex flex-col sm:flex-row gap-6">
                      <Link
                        to="/pricing"
                        className="w-full sm:w-auto text-center px-8 py-4 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
                      >
                        Buy API Docs
                      </Link>

                      <div className="grid grid-cols-3 gap-3 text-center w-full">
                        <div className="p-3 bg-white rounded-lg shadow-sm">
                          <div className="text-xl md:text-2xl font-bold text-blue-600">
                            50+
                          </div>
                          <div className="text-xs md:text-sm text-gray-600">API Endpoints</div>
                        </div>
                        <div className="p-3 bg-white rounded-lg shadow-sm">
                          <div className="text-xl md:text-2xl font-bold text-blue-600">
                            25ms
                          </div>
                          <div className="text-xs md:text-sm text-gray-600">
                            Avg Response Time
                          </div>
                        </div>
                        <div className="p-3 bg-white rounded-lg shadow-sm">
                          <div className="text-xl md:text-2xl font-bold text-blue-600">
                            99.9%
                          </div>
                          <div className="text-xs md:text-sm text-gray-600">
                            API Uptime
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="w-full lg:w-[38%]">
                <div className="mx-auto lg:mr-0">
                  <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 mb-4">
                    <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-4">
                      API Features & Capabilities
                    </h3>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                      {[
                        {
                          icon: <Code className="w-5 h-5 text-blue-600" />,
                          title: "RESTful Endpoints",
                          desc: "Standard HTTP methods",
                        },
                        {
                          icon: <Lock className="w-5 h-5 text-blue-600" />,
                          title: "Basic Auth",
                          desc: "Secure authentication",
                        },
                        {
                          icon: <Server className="w-5 h-5 text-blue-600" />,
                          title: "Document APIs",
                          desc: "Full DMS integration",
                        },
                        {
                          icon: <Workflow className="w-5 h-5 text-blue-600" />,
                          title: "Workflow APIs",
                          desc: "Process automation",
                        },
                        {
                          icon: <CloudCog className="w-5 h-5 text-blue-600" />,
                          title: "Cloud Storage",
                          desc: "Scalable document storage",
                        },
                        {
                          icon: <RefreshCw className="w-5 h-5 text-blue-600" />,
                          title: "Real-time Sync",
                          desc: "WebSocket support",
                        },
                      ].map((feature, index) => (
                        <div key={index} className="p-3 bg-gray-50 rounded-lg">
                          <div className="flex items-center space-x-2 mb-1">
                            {feature.icon}
                            <h4 className="font-medium text-sm md:text-base text-gray-900">
                              {feature.title}
                            </h4>
                          </div>
                          <p className="text-xs md:text-sm text-gray-600">
                            {feature.desc}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div className="p-3 md:p-4 bg-blue-50 rounded-lg border border-blue-100">
                      <p className="text-xs md:text-sm text-gray-600 leading-relaxed">
                        "The API integration was seamless and the documentation comprehensive. 
                        We were able to integrate the entire DMS functionality into our existing 
                        system within weeks."
                        <span className="block mt-2 font-medium">
                          — Lead Developer, Tech Solutions Ltd
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApiSolutions;