import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";

import {
  Cpu,
  Layers,
  Zap,
  User,
  Headset,
  PencilRulerIcon,
  PackageCheckIcon,
  UserCog2,
  CheckCircle,
  Truck,
  Filter,
  NotepadText,
  FileLock,
  BookMarkedIcon,
  ScanEye,
  ChartNetwork,
  RotateCcw,
  Languages,
  Recycle,
  ShieldCheckIcon,
  ScanFaceIcon,
  ScanText,
  LucideBellDot,
  CandlestickChartIcon,
  Banknote,
  CircleDollarSignIcon,
} from "lucide-react"; // Ensure these are valid imports
import data from "../../assets/Data/Projects.json";

// Import images
import zatca1 from "../../assets/Projects/Zatca/zat1.png";
import zatca2 from "../../assets/Projects/Zatca/zat2.png";
import zatca3 from "../../assets/Projects/Zatca/zat3.png";
import zatca4 from "../../assets/Projects/Zatca/zat4.png";
import zatca5 from "../../assets/Projects/Zatca/zat5.png";

// Import images for resturant
import res1 from "../../assets/Projects/Resturant/res1.png";
import res2 from "../../assets/Projects/Resturant/res2.png";
import res3 from "../../assets/Projects/Resturant/res3.png";
import res4 from "../../assets/Projects/Resturant/res4.png";
import res5 from "../../assets/Projects/Resturant/res5.png";
import res6 from "../../assets/Projects/Resturant/res6.png";
import res7 from "../../assets/Projects/Resturant/res7.png";
import res8 from "../../assets/Projects/Resturant/res8.png";
import res9 from "../../assets/Projects/Resturant/res9.png";
//// Import images for TMS
import TMS1 from "../../assets/Projects/TMS/TMS1.png";
import TMS2 from "../../assets/Projects/TMS/TMS2.png";
import TMS3 from "../../assets/Projects/TMS/TMS3.png";
import TMS4 from "../../assets/Projects/TMS/TMS4.png";
import TMS5 from "../../assets/Projects/TMS/TMS5.png";
import TMS6 from "../../assets/Projects/TMS/TMS6.png";
import TMS7 from "../../assets/Projects/TMS/TMS7.png";
import TMS8 from "../../assets/Projects/TMS/TMS8.png";
//// Import images for DMS
import sc1 from "../../assets/Projects/Scanner/sc1.png";
import sc2 from "../../assets/Projects/Scanner/sc2.png";
import sc3 from "../../assets/Projects/Scanner/sc3.png";
import sc4 from "../../assets/Projects/Scanner/sc4.png";
import sc5 from "../../assets/Projects/Scanner/sc5.png";
import sc6 from "../../assets/Projects/Scanner/sc6.png";
import sc7 from "../../assets/Projects/Scanner/sc7.png";
import sc8 from "../../assets/Projects/Scanner/sc8.png";
import sc9 from "../../assets/Projects/Scanner/sc9.png";
import sc10 from "../../assets/Projects/Scanner/sc10.png";
import sc11 from "../../assets/Projects/Scanner/sc11.png";
import sc12 from "../../assets/Projects/Scanner/sc12.png";
import sc13 from "../../assets/Projects/Scanner/sc13.png";
import sc14 from "../../assets/Projects/Scanner/sc14.png";
import sc15 from "../../assets/Projects/Scanner/sc15.png";
import sc16 from "../../assets/Projects/Scanner/sc16.png";
import sc17 from "../../assets/Projects/Scanner/sc17.png";
import sc18 from "../../assets/Projects/Scanner/sc18.png";
import sc19 from "../../assets/Projects/Scanner/sc19.png";
//// Import images for Investment Management System
import in1 from "../../assets/Projects/Investment/in1.png";
import in2 from "../../assets/Projects/Investment/in2.png";
import in3 from "../../assets/Projects/Investment/in3.png";
import in4 from "../../assets/Projects/Investment/in4.png";
import in5 from "../../assets/Projects/Investment/in5.png";
import in6 from "../../assets/Projects/Investment/in6.png";
import in7 from "../../assets/Projects/Investment/in7.png";
import in8 from "../../assets/Projects/Investment/in8.png";
//// Import images for P-Track
import pt1 from "../../assets/Projects/PTrack/pt1.png";
import pt2 from "../../assets/Projects/PTrack/pt2.png";
import pt3 from "../../assets/Projects/PTrack/pt3.png";
import pt4 from "../../assets/Projects/PTrack/pt4.png";
import pt5 from "../../assets/Projects/PTrack/pt5.png";
import pt6 from "../../assets/Projects/PTrack/pt6.png";
import pt7 from "../../assets/Projects/PTrack/pt7.png";
import pt8 from "../../assets/Projects/PTrack/pt8.png";
import pt9 from "../../assets/Projects/PTrack/pt9.png";
import pt10 from "../../assets/Projects/PTrack/pt10.png";
import pt11 from "../../assets/Projects/PTrack/pt11.png";
import pt12 from "../../assets/Projects/PTrack/pt12.png";
import pt13 from "../../assets/Projects/PTrack/pt13.png";
//// Import images for Electronic Permit System
import EPS1 from "../../assets/Projects/EPS/EPS1.png";
import EPS2 from "../../assets/Projects/EPS/EPS2.png";
import EPS3 from "../../assets/Projects/EPS/EPS3.png";
import EPS4 from "../../assets/Projects/EPS/EPS4.png";
import EPS5 from "../../assets/Projects/EPS/EPS5.png";
import EPS6 from "../../assets/Projects/EPS/EPS6.png";
import EPS7 from "../../assets/Projects/EPS/EPS7.png";
import EPS8 from "../../assets/Projects/EPS/EPS8.png";
//// Import images for Property Management System
import PMS1 from "../../assets/Projects/Property/PMS1.png";
import PMS2 from "../../assets/Projects/Property/PMS2.png";
import PMS3 from "../../assets/Projects/Property/PMS3.png";
import PMS4 from "../../assets/Projects/Property/PMS4.png";
//// Import images for Property Management System
import TFS1 from "../../assets/Projects/TaxSlip/TFS1.png";
import TFS2 from "../../assets/Projects/TaxSlip/TFS2.png";
import TFS3 from "../../assets/Projects/TaxSlip/TFS3.png";
import TFS4 from "../../assets/Projects/TaxSlip/TFS4.png";
import TFS5 from "../../assets/Projects/TaxSlip/TFS5.png";
import TFS6 from "../../assets/Projects/TaxSlip/TFS6.png";
import TFS7 from "../../assets/Projects/TaxSlip/TFS7.png";
import TFS8 from "../../assets/Projects/TaxSlip/TFS8.png";
import TFS9 from "../../assets/Projects/TaxSlip/TFS9.png";
import TFS10 from "../../assets/Projects/TaxSlip/TFS10.png";
//// Import images for School Management System
import SMS1 from "../../assets/Projects/School/SMS1.png";
import SMS2 from "../../assets/Projects/School/SMS2.png";
import SMS3 from "../../assets/Projects/School/SMS3.png";
import SMS4 from "../../assets/Projects/School/SMS4.png";
import SMS5 from "../../assets/Projects/School/SMS5.png";
import SMS6 from "../../assets/Projects/School/SMS6.png";
import SMS7 from "../../assets/Projects/School/SMS7.png";
import SMS8 from "../../assets/Projects/School/SMS8.png";
import SMS9 from "../../assets/Projects/School/SMS9.png";
//// Import images for Share Investment System
import SIS1 from "../../assets/Projects/Shared/SIS1.png";
import SIS2 from "../../assets/Projects/Shared/SIS2.png";
import SIS3 from "../../assets/Projects/Shared/SIS3.png";
import SIS4 from "../../assets/Projects/Shared/SIS4.png";
import SIS5 from "../../assets/Projects/Shared/SIS5.png";
import SIS6 from "../../assets/Projects/Shared/SIS6.png";
import SIS7 from "../../assets/Projects/Shared/SIS7.png";
import SIS8 from "../../assets/Projects/Shared/SIS8.png";
import SIS9 from "../../assets/Projects/Shared/SIS9.png";
import SIS10 from "../../assets/Projects/Shared/SIS10.png";
import SIS11 from "../../assets/Projects/Shared/SIS11.png";
import SIS12 from "../../assets/Projects/Shared/SIS12.png";
import SIS13 from "../../assets/Projects/Shared/SIS13.png";
import SIS14 from "../../assets/Projects/Shared/SIS14.png";
import SIS15 from "../../assets/Projects/Shared/SIS15.png";

import cloud1 from "../../assets/Projects/Resturant/res4.png";
const iconMap = {
  Cpu,
  Layers,
  Zap,
  User,
  Headset,
  PencilRulerIcon,
  PackageCheckIcon,
  UserCog2,
  Truck,
  Filter,
  NotepadText,
  FileLock,
  BookMarkedIcon,
  ScanEye,
  ChartNetwork,
  RotateCcw,
  Languages,
  Recycle,
  ShieldCheckIcon,
  ScanFaceIcon,
  ScanText,
  LucideBellDot,
  CandlestickChartIcon,
  Banknote,
  CircleDollarSignIcon,
  // Remove BookTextIcon if it's not a valid import
};

const images = {
  //Images for Zatca
  zatca1,
  zatca2,
  zatca3,
  zatca4,
  zatca5,
  //
  cloud1,
  //Images for returant
  res1,
  res2,
  res3,
  res4,
  res5,
  res6,
  res7,
  res8,
  res9,
  //Images for Tramsport Management system
  TMS1,
  TMS2,
  TMS3,
  TMS4,
  TMS5,
  TMS6,
  TMS7,
  TMS8,
  //Images for Document scanner
  sc1,
  sc2,
  sc3,
  sc4,
  sc5,
  sc6,
  sc7,
  sc8,
  sc9,
  sc10,
  sc11,
  sc12,
  sc13,
  sc14,
  sc15,
  sc16,
  sc17,
  sc18,
  sc19,
  //Images for IMS
  in1,
  in2,
  in3,
  in4,
  in5,
  in6,
  in7,
  in8,
  // Images for P-Track
  pt1,
  pt2,
  pt3,
  pt4,
  pt5,
  pt6,
  pt7,
  pt8,
  pt9,
  pt10,
  pt11,
  pt12,
  pt13,
  // Images for Electric Permit System
  EPS1,
  EPS2,
  EPS3,
  EPS4,
  EPS5,
  EPS6,
  EPS7,
  EPS8,
  // Images for Property Management System
  PMS1,
  PMS2,
  PMS3,
  PMS4,
  // Images for Property Management System
  TFS1,
  TFS2,
  TFS3,
  TFS4,
  TFS5,
  TFS6,
  TFS7,
  TFS8,
  TFS9,
  TFS10,
  // School
  SMS1,
  SMS2,
  SMS3,
  SMS4,
  SMS5,
  SMS6,
  SMS7,
  SMS8,
  SMS9,
  // images for Share Investment System
  SIS1,
  SIS2,
  SIS3,
  SIS4,
  SIS5,
  SIS6,
  SIS7,
  SIS8,
  SIS9,
  SIS10,
  SIS11,
  SIS12,
  SIS13,
  SIS14,
  SIS15,
  // Add additional image mappings as needed
};

// Modal Component
const Modal = ({ isOpen, onClose, imageSrc, imageAlt }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative max-w-[90%] max-h-[90vh] bg-white p-4 rounded-lg overflow-hidden flex items-center justify-center">
        <button onClick={onClose} className="absolute top-0 right-0 p-2">
          <i className="fa-solid fa-xmark-large text-white border rounded-full md:p-1 bg-blue-600"></i>
        </button>
        <img
          src={imageSrc}
          alt={imageAlt}
          className="max-w-full max-h-full object-contain"
        />
      </div>
    </div>
  );
};
// const ImageGallery = ({ item, images, isProduct, isService, openModal }) => {
//   console.log("Images object:", images); // Debug: Log the images object
//   const handleImageClick = (src, alt) => {
//     console.log("Image clicked:", src, alt); // Debug: Log when an image is clicked
//     openModal(src, alt);
//   };
// };
const DetailPage = () => {
  const { id } = useParams();
  const item = data.projects.find((item) => item.id === id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ src: "", alt: "" });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  if (!item) {
    return <div className="text-center text-2xl mt-20">Project not found!</div>;
  }

  const openModal = (src, alt) => {
    console.log("Opening modal with:", src, alt); // Debug log
    setSelectedImage({ src, alt });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isProduct = item.category === "product";
  const isService = item.category === "service";

  console.log("Rendering DetailPage"); // Debug log

  return (
    <div className="min-h-screen ">
      {/* Hero Section */}
      <div className="max-w-7xl md:mt-0 mt-5 mx-auto px-4 sm:px-6 lg:px-8 pt-16 sm:pt-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16 sm:mb-24"
        >
          <motion.span
            whileHover={{ scale: 1.05 }}
            className="inline-block px-4 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-sm font-semibold rounded-full mb-6 shadow-lg"
          >
            {item.intro.badge}
          </motion.span>
          <h1 className="text-5xl pb-5 underline font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
            {item.intro.title}
          </h1>
          <p className="text-xl text-justify sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            {item.intro.description}
          </p>
        </motion.div>
      </div>

      {/* Image Gallery */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-20">
        <div className="flex flex-col space-y-8">
          {/* Main Image and Two Right Images */}
          <div className="flex flex-col lg:flex-row gap-4">
            {/* Main Image */}
            <motion.div
              className="w-full lg:w-2/3 h-[50vh] lg:h-[60vh] relative"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 p-2 rounded-3xl h-full">
                <img
                  src={images[item.imageGallery.main.src]}
                  alt={item.imageGallery.main.alt}
                  className="w-full h-full object-cover shadow-2xl rounded-2xl cursor-pointer"
                  onClick={() =>
                    openModal(
                      images[item.imageGallery.main.src],
                      item.imageGallery.main.alt
                    )
                  }
                />
              </div>
              <div className="absolute bottom-4 left-4 right-4 p-4 bg-black bg-opacity-70 rounded-xl">
                <h2 className="text-xl lg:text-2xl font-bold text-white mb-1">
                  {item.imageGallery.main.title}
                </h2>
                <p className="text-sm text-gray-300">
                  {item.imageGallery.main.location}
                </p>
              </div>
            </motion.div>

            {/* Two Right Images */}
            <div className="w-full lg:w-1/3 flex flex-row lg:flex-col gap-4">
              {item.imageGallery.additional.slice(0, 2).map((img, index) => (
                <motion.div
                  key={index}
                  className="relative h-48 lg:h-[calc(30vh-1rem)] rounded-2xl overflow-hidden shadow-xl cursor-pointer bg-gradient-to-r from-blue-600 to-purple-600 p-1"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.1 * (index + 1) }}
                  whileHover={{ scale: 1.05, zIndex: 10 }}
                  onHoverStart={() => setHoveredIndex(index)}
                  onHoverEnd={() => setHoveredIndex(null)}
                  onClick={() => openModal(images[img.src], img.alt)}
                >
                  <img
                    src={images[img.src]}
                    alt={img.alt}
                    className="w-full h-full object-cover rounded-xl"
                  />
                  <motion.div
                    className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-xl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: hoveredIndex === index ? 1 : 0 }}
                  >
                    <p className="text-white text-center font-semibold px-2">
                      {img.alt}
                    </p>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Bottom Row Images */}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
            {item.imageGallery.additional.slice(2).map((img, index) => (
              <motion.div
                key={index + 2}
                className="relative h-48 rounded-2xl overflow-hidden shadow-xl cursor-pointer bg-gradient-to-r from-blue-600 to-violet-600 p-1"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.1 * (index + 3) }}
                whileHover={{ scale: 1.05, zIndex: 10 }}
                onHoverStart={() => setHoveredIndex(index + 2)}
                onHoverEnd={() => setHoveredIndex(null)}
                onClick={() => openModal(images[img.src], img.alt)}
              >
                <img
                  src={images[img.src]}
                  alt={img.alt}
                  className="w-full h-full object-cover rounded-xl"
                />
                <motion.div
                  className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-xl"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: hoveredIndex === index + 2 ? 1 : 0 }}
                >
                  <p className="text-white text-center font-semibold px-2">
                    {img.alt}
                  </p>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto mb-8 sm:mb-12 md:mb-16 lg:mb-20 px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="rounded-2xl sm:rounded-3xl overflow-hidden shadow-md"
        >
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2 p-4 sm:p-6 md:p-8 lg:p-12">
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-blue-800 mb-4 sm:mb-6">
                {item.content.title}
              </h2>
              <ul className="space-y-3 sm:space-y-4 mb-6 sm:mb-8">
                {item.content.description.map((desc, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start text-base sm:text-lg text-gray-600"
                  >
                    <CheckCircle className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 text-blue-600 mr-2 sm:mr-3 mt-1" />
                    {desc}
                  </motion.li>
                ))}
              </ul>
            </div>
            <div className="w-full lg:w-1/2 bg-gradient-to-br from-blue-50 to-purple-50 p-4 sm:p-6 md:p-8 lg:p-12">
              <dl className="space-y-6 sm:space-y-8">
                {item.content.features.map((feature, index) => {
                  const Icon = iconMap[feature.icon];
                  return (
                    <motion.div
                      key={feature.name}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-start"
                    >
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 rounded-md bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg">
                          {Icon && (
                            <Icon
                              className="h-5 w-5 sm:h-6 sm:w-6"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                      <div className="ml-3 sm:ml-4">
                        <dt className="text-base sm:text-lg font-semibold text-blue-800">
                          {feature.name}
                        </dt>
                        <dd className="mt-1 sm:mt-2 text-sm sm:text-base text-gray-600">
                          {feature.description}
                        </dd>
                      </div>
                    </motion.div>
                  );
                })}
              </dl>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Summary Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8 sm:mb-12 md:mb-16 lg:mb-20">
        {item.summary.map((summaryItem, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="flex flex-col lg:flex-row items-center gap-6 sm:gap-8 md:gap-10 lg:gap-12 mb-12 sm:mb-16 md:mb-20"
          >
            <div
              className={`w-full lg:w-1/2 ${
                index % 2 === 0 ? "lg:order-1" : "lg:order-2"
              }`}
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="rounded-2xl sm:rounded-3xl overflow-hidden shadow-2xl"
              >
                <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-1 rounded-xl">
                  <img
                    src={images[summaryItem.image.src]}
                    alt={summaryItem.image.alt}
                    className="w-full h-auto object-cover rounded-xl sm:rounded-2xl cursor-pointer"
                    onClick={() =>
                      openModal(
                        images[summaryItem.image.src],
                        summaryItem.image.alt
                      )
                    }
                  />
                </div>
              </motion.div>
            </div>
            <div
              className={`w-full lg:w-1/2 ${
                index % 2 === 0 ? "lg:order-2" : "lg:order-1"
              }`}
            >
              <span className="inline-block px-3 sm:px-4 py-1.5 sm:py-2 bg-blue-100 text-blue-800 rounded-full text-xs sm:text-sm font-semibold mb-3 sm:mb-4">
                {summaryItem.badge}
              </span>
              <h2 className="text-2xl sm:text-3xl font-bold text-blue-800 mb-4 sm:mb-6">
                {summaryItem.title}
              </h2>
              <ul className="space-y-3 sm:space-y-4 mb-4 sm:mb-6">
                {summaryItem.description.map((desc, descIndex) => (
                  <motion.li
                    key={descIndex}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: descIndex * 0.1 }}
                    className="flex items-start"
                  >
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-blue-600 text-white text-xs font-bold">
                        {descIndex + 1}
                      </div>
                    </div>
                    <p className="ml-3 sm:ml-4 text-sm sm:text-base text-gray-600">
                      {desc}
                    </p>
                  </motion.li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Built With Section */}
      <div className="py-12 sm:py-16 md:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-8 sm:mb-12 md:mb-16"
          >
            <motion.span
              whileHover={{ scale: 1.05 }}
              className="inline-block px-3 sm:px-4 py-1.5 sm:py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-xs sm:text-sm font-semibold rounded-full mb-4 sm:mb-6 shadow-lg"
            >
              {item.builtWith.badge}
            </motion.span>
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mb-4 sm:mb-6">
              {item.builtWith.title}
            </h2>
          </motion.div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {item.builtWith.features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
                className="rounded-xl sm:rounded-2xl overflow-hidden shadow-xl p-6 sm:p-8 flex flex-col items-center"
              >
                <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full flex items-center justify-center mb-4 sm:mb-6">
                  <i className="fas fa-gear text-white text-2xl sm:text-3xl"></i>
                </div>
                <h3 className="text-xl sm:text-2xl font-semibold text-blue-800 mb-3 sm:mb-4">
                  {feature.title}
                </h3>
                <p className="text-sm sm:text-base text-gray-600 text-center">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImage.src}
        imageAlt={selectedImage.alt}
      />
    {/* User Satisfaction */}
<div className="px-4 sm:px-6 lg:px-8 max-w-[80rem] mx-auto">
  <div className="flex flex-col sm:flex-row flex-wrap items-center justify-between gap-2 sm:gap-4">
    <div className="w-full sm:w-1/2 md:w-auto">
      <div className="flex py-3 sm:py-5 items-center">
        <span className="inline-block mr-2 sm:mr-3">
          <i className="fa-solid fa-universal-access text-xl sm:text-2xl text-blue-700"></i>
        </span>
        <h4 className="font-bold text-sm sm:text-base">Universal Compatible</h4>
      </div>
    </div>
    <div className="w-full sm:w-1/2 md:w-auto">
      <div className="flex py-3 sm:py-5 items-center">
        <span className="inline-block mr-2 sm:mr-3">
          <svg
            width="24"
            height="24"
            className="w-6 h-6 sm:w-8 sm:h-8"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 10.6667V13.4667C24.4133 13.3867 23.7467 13.3467 23 13.3334V10.6667C23 6.46675 21.8133 3.66675 16 3.66675C10.1867 3.66675 9 6.46675 9 10.6667V13.3334C8.25333 13.3467 7.58667 13.3867 7 13.4667V10.6667C7 6.80008 7.93333 1.66675 16 1.66675C24.0667 1.66675 25 6.80008 25 10.6667Z"
              fill="#1D4ED8"
            ></path>
            <path
              d="M25 13.4666C24.4133 13.3866 23.7467 13.3466 23 13.3333H8.99999C8.25332 13.3466 7.58666 13.3866 6.99999 13.4666C3.59999 13.8799 2.66666 15.5466 2.66666 19.9999V22.6666C2.66666 27.9999 3.99999 29.3333 9.33332 29.3333H22.6667C28 29.3333 29.3333 27.9999 29.3333 22.6666V19.9999C29.3333 15.5466 28.4 13.8799 25 13.4666ZM11.6133 22.2799C11.36 22.5199 11.0133 22.6666 10.6667 22.6666C10.4933 22.6666 10.32 22.6266 10.16 22.5599C9.98666 22.4933 9.85332 22.3999 9.71999 22.2799C9.47999 22.0266 9.33332 21.6799 9.33332 21.3333C9.33332 21.1599 9.37332 20.9866 9.43999 20.8266C9.50666 20.6666 9.59999 20.5199 9.71999 20.3866C9.85332 20.2666 9.98666 20.1733 10.16 20.1066C10.6533 19.8933 11.24 20.0133 11.6133 20.3866C11.7333 20.5199 11.8267 20.6666 11.8933 20.8266C11.96 20.9866 12 21.1599 12 21.3333C12 21.6799 11.8533 22.0266 11.6133 22.2799ZM17.2267 21.8399C17.16 21.9999 17.0667 22.1466 16.9467 22.2799C16.6933 22.5199 16.3467 22.6666 16 22.6666C15.64 22.6666 15.3067 22.5199 15.0533 22.2799C14.9333 22.1466 14.84 21.9999 14.7733 21.8399C14.7067 21.6799 14.6667 21.5066 14.6667 21.3333C14.6667 20.9733 14.8133 20.6399 15.0533 20.3866C15.5467 19.8933 16.44 19.8933 16.9467 20.3866C17.1867 20.6399 17.3333 20.9733 17.3333 21.3333C17.3333 21.5066 17.2933 21.6799 17.2267 21.8399ZM22.28 22.2799C22.0267 22.5199 21.68 22.6666 21.3333 22.6666C20.9867 22.6666 20.64 22.5199 20.3867 22.2799C20.1467 22.0266 20 21.6933 20 21.3333C20 20.9733 20.1467 20.6399 20.3867 20.3866C20.8933 19.8933 21.7867 19.8933 22.28 20.3866C22.3333 20.4533 22.3867 20.5199 22.44 20.5999C22.4933 20.6666 22.5333 20.7466 22.56 20.8266C22.6 20.9066 22.6267 20.9866 22.64 21.0666C22.6533 21.1599 22.6667 21.2533 22.6667 21.3333C22.6667 21.6799 22.52 22.0266 22.28 22.2799Z"
              fill="#1D4ED8"
            ></path>
          </svg>
        </span>
        <h4 className="font-bold text-sm sm:text-base">Secure HRM, DMS System</h4>
      </div>
    </div>
    <div className="w-full sm:w-1/2 md:w-auto">
      <div className="flex py-3 sm:py-5 items-center">
        <span className="inline-block mr-2 sm:mr-3">
          <svg
            width="24"
            height="24"
            className="w-6 h-6 sm:w-8 sm:h-8"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1866 24.6532V11.1066C11.1866 10.5732 11.3466 10.0532 11.64 9.61322L15.28 4.19989C15.8533 3.33322 17.28 2.71989 18.4933 3.17322C19.8 3.61322 20.6666 5.07989 20.3866 6.38655L19.6933 10.7466C19.64 11.1466 19.7466 11.5066 19.9733 11.7866C20.2 12.0399 20.5333 12.1999 20.8933 12.1999H26.3733C27.4266 12.1999 28.3333 12.6266 28.8666 13.3732C29.3733 14.0932 29.4666 15.0266 29.1333 15.9732L25.8533 25.9599C25.44 27.6132 23.64 28.9599 21.8533 28.9599H16.6533C15.76 28.9599 14.5066 28.6532 13.9333 28.0799L12.2266 26.7599C11.5733 26.2666 11.1866 25.4799 11.1866 24.6532Z"
              fill="#1D4ED8"
            ></path>
            <path
              d="M6.94669 8.50659H5.57335C3.50669 8.50659 2.66669 9.30659 2.66669 11.2799V24.6933C2.66669 26.6666 3.50669 27.4666 5.57335 27.4666H6.94669C9.01335 27.4666 9.85335 26.6666 9.85335 24.6933V11.2799C9.85335 9.30659 9.01335 8.50659 6.94669 8.50659Z"
              fill="#1D4ED8"
            ></path>
          </svg>
        </span>
        <h4 className="font-bold text-sm sm:text-base">100% Customer Satisfaction</h4>
      </div>
    </div>
    <div className="w-full sm:w-1/2 md:w-auto">
      <div className="flex py-3 sm:py-5 items-center">
        <span className="inline-block mr-2 sm:mr-3">
          <svg
            width="24"
            height="24"
            className="w-6 h-6 sm:w-8 sm:h-8"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 2.66675C8.65335 2.66675 2.66669 8.65341 2.66669 16.0001C2.66669 23.3467 8.65335 29.3334 16 29.3334C23.3467 29.3334 29.3334 23.3467 29.3334 16.0001C29.3334 8.65341 23.3467 2.66675 16 2.66675ZM22.3734 12.9334L14.8134 20.4934C14.6267 20.6801 14.3734 20.7867 14.1067 20.7867C13.84 20.7867 13.5867 20.6801 13.4 20.4934L9.62669 16.7201C9.24002 16.3334 9.24002 15.6934 9.62669 15.3067C10.0134 14.9201 10.6534 14.9201 11.04 15.3067L14.1067 18.3734L20.96 11.5201C21.3467 11.1334 21.9867 11.1334 22.3734 11.5201C22.76 11.9067 22.76 12.5334 22.3734 12.9334Z"
              fill="#1D4ED8"
            ></path>
          </svg>
        </span>
        <h4 className="font-bold text-sm sm:text-base">Product Guarantee</h4>
      </div>
    </div>
  </div>
</div>

{/* Demo Connect */}
<section className="py-12 sm:py-16 md:py-24 overflow-hidden">
  <div className="container max-w-[80rem] px-4 sm:px-6 lg:px-8 mx-auto">
    <div className="flex flex-wrap items-center -mx-4">
      <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
        <div className="lg:max-w-xl">
          <h2 className="mb-3 text-2xl sm:text-3xl font-bold font-heading tracking-tight leading-tight">
            Discover Our Solutions
          </h2>
          <p className="font-medium text-gray-600 leading-relaxed text-sm sm:text-base">
            Experience the power of our Oracle APEX solutions firsthand—schedule a free demo today
            and see how we can elevate your application development!
          </p>
          <div className="mt-5 sm:mt-6 inline-block w-full sm:w-auto">
            <div className="flex">
              <div className="w-full">
                <Link
                  className="inline-block py-2 sm:py-3 px-4 sm:px-5 w-full sm:w-auto text-white text-sm sm:text-base font-semibold border border-blue-700 rounded-lg focus:ring focus:ring-indigo-300 bg-blue-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                  to="/contact-us"
                >
                  Request demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full sm:w-1/2 px-4 mb-4 sm:mb-0">
            <div className="p-4 sm:p-6 md:p-7 bg-gray-50 rounded-xl sm:rounded-2xl">
              <h2 className="mb-2 text-4xl sm:text-5xl md:text-6xl font-bold font-heading tracking-tight leading-tight">
                100+
              </h2>
              <p className="text-gray-800 font-semibold text-sm sm:text-base">
                Trusted by companies
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-4">
            <div className="p-4 sm:p-6 md:p-7 bg-gray-50 rounded-xl sm:rounded-2xl">
              <h2 className="mb-2 text-4xl sm:text-5xl md:text-6xl font-bold font-heading tracking-tight leading-tight">
                1000+
              </h2>
              <p className="text-gray-800 font-semibold text-sm sm:text-base">
                Project Accomplished
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default DetailPage;
