import React from 'react';
import toast from 'react-hot-toast';

const DemoJSONModal = ({ isVisible, onClose }) => {
    if (!isVisible) return null;
    const jsonData = `[
    {
        "name": "John Doe",
        "age": 30,
        "salary": 55000
    },
    {
        "name": "Jane Smith",
        "age": 25,
        "salary": 48000
    }
 ]`;

    const handleCopy = () => {
        navigator.clipboard.writeText(jsonData).then(() => {
            toast.success('JSON copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy JSON:', err);
        });
    };

    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] pt-24 bg-opacity-75 backdrop-blur-sm flex justify-center items-center'>
            <div className='flex flex-col max-w-2xl w-full'>
                <div className='bg-white h-full flex p-8 flex-col items-center justify-center w-full max-w-2xl mx-auto border-2 border-slate-100 rounded shadow-sm'>
                    <div className='flex items-start justify-between w-full'>
                        <div>
                            <h1 className='text-2xl font-semibold'>Demo JSON Format</h1>
                            <p className='text-sm text-gray-500'>This is the acceptable JSON format for converting to JSON in PDF. Copy to check it out!</p>
                        </div>

                        <button onClick={() => onClose()} className='activeBtn text-gray-800 text-sm'>
                            <i className="fa-solid fa-xmark fa-xl text-gray-800"></i>
                        </button>
                    </div>

                    <div className="flex flex-col h-full w-full rounded-lg   mt-8 bg-gray-900 text-gray-200 font-mono">
                        <div className="flex items-center h-10 px-4 rounded-lg bg-gray-800">
                            <button onClick={handleCopy}>
                                <i className="fa-light fa-copy mr-2"></i> Copy JSON
                            </button>
                        </div>
                        <div className="flex-1 p-4 w-full">
                            <pre className="mt-2 whitespace-pre-wrap text-green-500">
                                {jsonData}
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoJSONModal;
