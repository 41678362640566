import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../../context/data";

function MemoHistory() {
  const { user } = useUser();
  const [memoData, setMemoData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // New state for date filter
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const { authURL } = useAuth();

  const fetchMemosData = async () => {
    try {
      const response = await fetch(
        `${authURL}/memo-list?userID=${user.primaryEmailAddress.emailAddress}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const res = await response.json();
      const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setMemoData(sortedData);
      setFilteredData(res.data);
    } catch (error) {
      console.error("Error fetching Memo data:", error);
    }
  };

  const handleDelete = async (memoId) => {
    try {
      const response = await fetch(`${authURL}/delete-memo?_id=${memoId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete Memo");
      }
      toast.success("Memo deleted successfully!");
      const updatedMemos = memoData.filter((memo) => memo._id !== memoId);
      setMemoData(updatedMemos);
      setFilteredData(updatedMemos);
    } catch (error) {
      console.error("Error deleting Memo:", error);
      toast.error("Failed to delete Memo");
    }
  };

  useEffect(() => {
    fetchMemosData();
  }, []);

  useEffect(() => {
    const filtered = memoData.filter((memo) => {
      const matchesSearchTerm =
        memo.MemoTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        memo.MemoId.toString().includes(searchTerm) ||
        memo.status.toLowerCase().startsWith(searchTerm.toLowerCase());

      const matchesDate =
        selectedDate === "" ||
        new Date(memo.createdAt).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      return matchesSearchTerm && matchesDate;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [searchTerm, selectedDate, memoData]);

  const getStatusClasses = (status) => {
    switch (status) {
      case "unpaid":
        return "bg-yellow-200 text-yellow-700 border-yellow-300";
      case "paid":
        return "bg-green-200 text-green-700 border-green-300";
      case "failed":
        return "bg-red-200 text-red-700 border-red-300";
      default:
        return "bg-gray-200 text-gray-700 border-gray-300";
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMemos = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="md:max-w-4xl xl:max-w-6xl mx-auto my-32">

      <div className="flex w-full items-end justify-between">

        <div className="w-full">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Memo Reciept History
          </h1>
          <p className="mt-3 text-gray-600">History of generated Memo Reciept</p>
        </div>

        <div className="flex flex-col w-1/2">
          <div className="flex space-x-4 w-full">
            <input
              type="text"
              placeholder="Search memos..."
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[a-zA-Z0-9\s]*$/; // Allows only letters, numbers, and spaces

                if (value.length <= 16 && regex.test(value)) {
                  setSearchTerm(value);
                }
              }}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            {/* Date input for filtering */}
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <lable className="text-sm text-gray-400 mt-1">Filter Memo reciept with ID, Name, Status and with Date.</lable>
        </div>

      </div>

      {currentMemos.length === 0 ? (
        <p className="text-center text-gray-500 mt-8">
          No memos found matching your search.
        </p>
      ) : (
        <div className="flex flex-wrap -m-2 mt-8">
          {currentMemos.map((memo, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
              <div className="max-w-lg md:p-8 rounded-lg bg-white border border-slate-200 shadow-lg p-8">
                <div className="space-x-4">
                  <span className="text-xs font-medium text-gray-400">
                    <span className="bg-slate-100 border-2 tracking-wider border-slate-200 py-1 px-3 text-slate-700 rounded-full">
                      {memo.MemoId}
                    </span>
                  </span>

                  <span
                    className={`text-xs font-medium border-2 tracking-wider py-1 px-3 rounded-full uppercase ${getStatusClasses(
                      memo.status
                    )}`}
                  >
                    {memo.status}
                  </span>
                </div>

                <h3 className="text-2xl font-semibold mt-4 tracking-wider text-gray-900 capitalize">
                  Title: {memo.MemoTitle}
                </h3>
                <div className="text-sm text-gray-600">
                  Generated on: {new Date(memo.createdAt).toLocaleDateString()}
                </div>
                <div className="mt-8 flex w-full space-x-4">
                  <Link
                    to={`/home/generate-docx/memo-form/memo-template?MemoId=${memo.MemoId}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-eye"></i>
                  </Link>
                  <Link
                    to={`/home/generate-docx/memo-form/update-memo?_id=${memo._id}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-pen"></i>
                  </Link>
                  <button
                    className="activeBtn w-full px-8 py-2 rounded bg-gradient-to-b from-red-500 to-red-600 text-white focus:ring-2 focus:ring-red-400 hover:shadow-xl transition duration-200"
                    onClick={() => handleDelete(memo._id)}
                  >
                    <i className="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {currentMemos.length > 0 ? (
        <div className="flex justify-center mt-8">
          <nav className="inline-flex rounded-md shadow">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`px-4 py-2 border border-gray-300 text-sm font-medium ${currentPage === number
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700 hover:bg-blue-50"
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, pageNumbers.length))
              }
              disabled={currentPage === pageNumbers.length}
              className="px-4 py-2 rounded-r-md border border-gray-300  bg-white text-sm  font-medium text-gray-700 hover:bg-blue-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </nav>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default MemoHistory;
