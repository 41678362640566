import React from "react"

function Purchases() {
    return (
        <div className='md:max-w-4xl xl:max-w-6xl mx-auto py-20 h-screen'>

            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Subscriptions
                    </h1>
                    <p className="mt-3 text-gray-600">
                        Manage your subscriptons and packages purchased form QuickDoc.app
                    </p>
                </div>
            </div>


            <table class="md:max-w-4xl xl:max-w-6xl mx-auto w-full mt-12 text-left border border-separate rounded border-slate-200" cellspacing="0">
                <tbody>
                    <tr>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Package Name</th>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Order ID</th>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Purchased On</th>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Valid Till</th>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Cost</th>
                        <th scope="col" class="h-12 px-6 text-sm font-medium border-l first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100">Status</th>
                    </tr>

                    {/* <tr className="bg-slate-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">asd</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 underline">asdsd</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">asd</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">asd</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">asd</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">asd</td>
                    </tr> */}

                </tbody>
            </table>
            
        </div>
    )
}

export default Purchases