import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function FooterContainer({ children }) {
    const location = useLocation();

    const [showFooter, setshowFooter] = useState(false);
    useEffect(() => {
        if (location.pathname.startsWith('/admin')) {
            setshowFooter(false);
        } else {
            setshowFooter(true);
        }
    }, [location]);
    return (
        <div>
            {showFooter && children}
        </div>
    )
}

export default FooterContainer