import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const AnnouncementBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const announcementTexts = [
    "🎉 Personalised CRM , DMS !",
    "🚀 CRM, DMS Integration",
    "🌟 Join Us and get it done today",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(
        (prevIndex) => (prevIndex + 1) % announcementTexts.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="bg-gradient-to-r from-blue-300 via-indigo-400 to-blue-400 text-white py-3 px-4 relative overflow-hidden">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center text-center mx-auto space-x-2">
          {/* <span className="hidden sm:inline text-2xl animate-bounce">📢</span> */}
          <p className="text-sm sm:text-base font-medium">
            {announcementTexts[currentTextIndex]}
          </p>
          <Link
            to="/contact-us"
            class="flex items-center gap-2 text-white underline rounded-full  text-sm hover:text-indigo-600 font-semibold px-2 transition-all duration-500"
          >
            Get In Touch
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 14"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 7L16.4167 7M11.8333 12.5L16.6852 7.64818C16.9907 7.34263 17.1435 7.18985 17.1435 7C17.1435 6.81015 16.9907 6.65737 16.6852 6.35182L11.8333 1.5"
                stroke="white"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-1 bg-white opacity-20 animate-pulse"></div>
    </div>
  );
};

export default AnnouncementBanner;
