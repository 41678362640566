import React, { useState, useEffect, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../context/data';
import toast from 'react-hot-toast';

function DecryptPDF() {
  const { authURL } = useAuth();
  const { user } = useUser();
  const [encryptedPdfFile, setEncryptedPdfFile] = useState(null);
  const [password, setPassword] = useState('');
  const [decryptedPdfUrl, setDecryptedPdfUrl] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const decryptedPdfRef = useRef(null);
  const [decryptedBlob, setDecryptedBlob] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (decryptedPdfRef.current) {
      window.scrollTo({
        top: decryptedPdfRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }, [decryptedPdfUrl]);

  const postFeatureLog = async () => {
    const postData = {
      userID: user.primaryEmailAddress.emailAddress,
      featureName: "Decrypt PDF",
      featureType: "Premium",
      featureCount: 1
    }

    try {
      const response = await fetch(`${authURL}/feature-log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log('Feature log posted successfully:', result);
    } catch (error) {
      console.error('Error posting feature log:', error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      if (fileName.endsWith('.pdf.encrypted')) {
        setEncryptedPdfFile(file);
        setSelectedFileName(fileName);
      } else {
        toast.error('Please select a file with the .pdf.encrypted extension.');
        event.target.value = ''; // Clear the file input
        setEncryptedPdfFile(null);
        setSelectedFileName('');
      }
    }
  };


  const handlePasswordChange = (e) => {
    const value = e.target.value.replace(/\s/g, ''); // Remove spaces
    setPassword(value);
  };

  const handleDecrypt = () => {
    if (encryptedPdfFile && password) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const encryptedData = reader.result;
          const decrypted = CryptoJS.AES.decrypt(encryptedData, password);
          const typedArray = convertWordArrayToUint8Array(decrypted);

          // Check if the decrypted data is valid by checking the first bytes of a PDF file.
          if (typedArray[0] !== 0x25 || typedArray[1] !== 0x50 || typedArray[2] !== 0x44 || typedArray[3] !== 0x46) {
            throw new Error('Invalid password or corrupted data.');
          }

          const blob = new Blob([typedArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          setDecryptedBlob(blob); // Store the Blob
          setDecryptedPdfUrl(url);
        } catch (error) {
          alert('Invalid password or corrupted data.');
          setDecryptedPdfUrl(null); // Ensure previous incorrect decryption attempts do not display
        }
      };
      postFeatureLog();
      reader.readAsText(encryptedPdfFile);
    } else {
      alert('Please select an encrypted PDF file and enter the password.');
    }
  };

  const convertWordArrayToUint8Array = (wordArray) => {
    const arrayOfWords = wordArray.words;
    const length = wordArray.sigBytes;
    const uInt8Array = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      uInt8Array[i] = (arrayOfWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    }
    return uInt8Array;
  };

  const handleDownload = () => {
    if (decryptedBlob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(decryptedBlob);
      link.download = selectedFileName.replace('.encrypted', '.pdf'); // Replace the .encrypted extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className='md:max-w-4xl xl:max-w-6xl mx-auto'>
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto my-32">
        <div className="text-start">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Decrypt Your PDF
          </h1>
          <p className="mt-3 text-gray-600">
            All your PDF tools on palm of your hand!
          </p>

          <div className="mt-7 sm:mt-12 md:max-w-4xl xl:max-w-6xl mx-auto relative h-full">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
              <div className="h-72 rounded-lg bg-gray-200">
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      {selectedFileName && (
                        <p className="my-2">Selected File: <span className='text-blue-700 underline font-bold'>{selectedFileName}</span></p>
                      )}
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload a file</span>
                      </p>
                      <p className="text-xs text-gray-500">Any <code className='font-bold'>.pdf.encrypted </code> File type</p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>

              <div className="h-72 rounded-lg border-2 border-gray-300 border-dashed p-8 bg-gray-50">
                <div className='text-start'>
                  <h1 className='text-xl font-semibold text-start'>DECRYPTION DETAILS</h1>
                  <p className='text-sm text-gray-500'>Add your password to the associated PDF file for decryption.</p>
                </div>
                <div className="h-full flex flex-col  justify-center">
                  <label className='text-sm text-gray-500 mb-1'>Encryption Key / Password</label>
                  <div className="relative">
                    <input
                      maxLength={16}
                      type={showPassword ? "text" : "password"}
                      className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      placeholder="Enter password..."
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                    </button>
                  </div>
                  <button
                    className="w-full mt-4 px-6 py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                    onClick={handleDecrypt}
                  >
                    Decrypt
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {decryptedPdfUrl && (
        <div ref={decryptedPdfRef}>
          <div className='flex items-center justify-between mb-4'>
            <h3 className="text-2xl font-semibold">Decrypted PDF:</h3>
            <button
              className='mt-4 px-6 py-3 rounded bg-gradient-to-b from-emerald-500 to-emerald-600 text-white focus:ring-2 focus:ring-emerald-400 hover:shadow-xl transition duration-200'
              onClick={handleDownload}
            >
              Download PDF
            </button>
          </div>
          <iframe src={decryptedPdfUrl} className="w-full h-[75rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50" title="Decrypted PDF"></iframe>
        </div>
      )}
    </div>
  );
}

export default DecryptPDF;
