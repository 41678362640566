import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../../context/data";
import toast from "react-hot-toast";
import { getDocument } from "pdfjs-dist";

function LockPDF() {
  const { authURL } = useAuth();
  const { user } = useUser();
  const [pdfFiles, setPdfFiles] = useState([]);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const newPdfFile = {
        file: file,
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert to MB
        lastModified: new Date(file.lastModified).toLocaleString(),
        pages: "Calculating...", // We'll update this soon
      };

      // Load the PDF to get the number of pages
      try {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const pdf = await getDocument(arrayBuffer).promise;
          newPdfFile.pages = pdf.numPages;
          setPdfFiles([newPdfFile]);
        };
        fileReader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error reading PDF file:", error);
        toast.error("Failed to read PDF file.");
      }
    } else {
      toast.error("Please select a valid PDF .pdf file.");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    setPassword(value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
const postFeatureLog = async () => {
    const postData = {
        userID: user.primaryEmailAddress.emailAddress,
        featureName: "PDF Locker",
        featureType: "Premium",
        featureCount: 1
    };

    try {
        const response = await fetch(`${authURL}/feature-log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log('Feature log posted successfully:', result);
    } catch (error) {
        console.error('Error posting feature log:', error);
    }
};

  const handleSave = async (lockedPdfBase64) => {
    const file = pdfFiles[0].file;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `locked-pdfs/${file.name.split(".")[0]}-locked.pdf`
    );

    const byteCharacters = atob(lockedPdfBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    try {
      setProgress(75);
      const snapshot = await fileRef.put(blob);
      const downloadURL = await snapshot.ref.getDownloadURL();
      setDownloadUrl(downloadURL);
      await postLockedPDFData(downloadURL);
      setProgress(100);
      setTimeout(() => {
        setLoading(false);
        window.open(downloadURL, "_blank"); // Open in new tab
      }, 1000);
    } catch (error) {
      console.error("Error saving file:", error);
      setLoading(false);
    }
  };

  const postLockedPDFData = async (downloadURL) => {
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!password || pdfFiles.length === 0) {
      setError("Password and PDF file are required.");
      return;
    }

    setError("");
    setLoading(true);
    setProgress(25);

    const file = pdfFiles[0].file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const pdfBase64 = reader.result.split(",")[1];

      try {
        setProgress(50);
        const response = await fetch(`${authURL}/lock-pdf`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userPassword: password,
            pdfBase64: pdfBase64,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        await handleSave(data.pdf);
      } catch (error) {
        setError("Error encrypting PDF: " + error.message);
        setLoading(false);
      }
    };
  };

  return (
    <div className="my-32 h-screen">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto flex items-end justify-between">
        <div>
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Lock your PDF
          </h1>
          <p className="mt-3 text-gray-600">
            Lock your PDFs files to protect your data.
          </p>
        </div>
        <div>
          <Link
            to="/home/lock-pdf/pdf-locked-history"
            className="text-blue-600 underline"
          >
            <span className="flex items-center">
              Locked PDF History{" "}
              <i className="fa-duotone fa-arrow-right ml-1 no-underline"></i>
            </span>
          </Link>
        </div>
      </div>

      <div className="mt-7 sm:mt-12 md:max-w-4xl xl:max-w-6xl mx-auto relative">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
          <div className="h-78 rounded-lg bg-gray-200">
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {pdfFiles.length > 0 ? (
                    pdfFiles.map((file) => (
                      <div key={file.name} className="text-center p-4 mb-4">
                        <p className="mb-2 text-lg font-semibold text-gray-700">
                          PDF Loaded Successfully
                        </p>
                        <svg
                          className="w-12 mx-auto h-12 mb-4 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p>
                          <strong>File Name:</strong>{" "}
                          <span className="text-blue-600">{file.name}</span>
                        </p>
                        <p>
                          <strong className="text-gray-600">File Size:</strong>{" "}
                          {file.size} MB
                        </p>
                        <p>
                          <strong className="text-gray-600">
                            Last Modified:
                          </strong>{" "}
                          {file.lastModified}
                        </p>
                        <p>
                          <strong className="text-gray-600">
                            Total Pages:
                          </strong>{" "}
                          {file.pages}
                        </p>
                      </div>
                    ))
                  ) : (
                    <>
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-gray-500">
                        Any <code className="font-semibold">.pdf</code> PDF type
                      </p>
                      <p className="text-xs text-gray-500">
                        File size Limit:{" "}
                        <code className="font-semibold">50mb</code>
                      </p>
                    </>
                  )}
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="application/pdf"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          <div className="h-72 rounded-lg border-2 border-gray-300 border-dashed p-8 bg-gray-50">
            <div>
              <h1 className="text-xl font-semibold text-start uppercase">
                Protection DETAILS
              </h1>
              <p className="text-sm text-gray-500">
                Add your password to the associated PDF file for protection.
              </p>
            </div>
            <div className="h-full flex flex-col justify-center">
              <label className="text-sm text-gray-500 mb-1">Password</label>
              <div className="relative">
            <input
                type={showPassword ? 'text' : 'password'}
                maxLength={16}
                className="border w-full border-gray-300 rounded px-3 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter password..."
                value={password}
                onChange={handlePasswordChange}
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
               
                    {showPassword ? (
                        <i class="fa-solid fa-eye-slash text-black"></i>
                    ) : (
                        <i class="fa-solid fa-eye text-black"></i>
                    )}
                
            </button>
        </div>
              <button
                className="w-full mt-4 px-6 py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Protecting PDF..." : "Protect"}
              </button>
              {loading && (
                <div className="my-4">
                  <p className="text-sm text-gray-500">
                    Protecting: {progress}%
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LockPDF;
