import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/data';

function FeatureLogs() {
  const { authURL } = useAuth();
  const [featureLogs, setFeatureLogs] = useState([]);

  const getFeatureLogs = async () => {
    try {
      const response = await fetch(`${authURL}/get-feature-log`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      // Aggregating feature counts and processing the logs
      const aggregatedLogs = {};
      result.forEach((log) => {
        const { featureName, featureCount, userID, featureType, timeStamps } = log;
        const date = new Date(timeStamps).toLocaleDateString(); // Adjust if timeStamps is in seconds

        if (aggregatedLogs[featureName]) {
          aggregatedLogs[featureName].featureCount += parseInt(featureCount, 10);
        } else {
          aggregatedLogs[featureName] = {
            featureName,
            featureCount: parseInt(featureCount, 10),
            userID,
            featureType,
            date,
          };
        }
      });

      // Convert aggregated logs object to an array
      const processedLogs = Object.values(aggregatedLogs);
      setFeatureLogs(processedLogs);
    } catch (error) {
      console.error('Error retrieving feature logs:', error);
    }
  };

  useEffect(() => {
    getFeatureLogs();
  }, []);

  return (
    <div className='h-screen'>
      <div className='flex items-center justify-between px-4'>
        <div>
          <h1 className='text-5xl font-semibold'>Features Insight's</h1>
          <h1 className='text-gray-600'>Get exclusiv insight of Feature's Insigts</h1>
        </div>
      </div>

      <div className='bg-white shadow-sm border-1 border-slate-300 p-4 rounded-lg my-12'>
        <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
          <thead className="hidden border-b lg:table-header-group">
            <tr>
              <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                Feature Name
              </td>
              <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                Usage Count
              </td>
              <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                User ID
              </td>
              <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                Feature Type
              </td>
            </tr>
          </thead>

          <tbody className="bg-white lg:border-gray-300">
            {featureLogs.map((log, index) => (
              <tr key={index} className='border-b border-slate-200'>
                <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                  {log.featureName}
                </td>
                <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                  {log.featureCount}
                </td>
                <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                  {log.userID}
                </td>
                <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                  {log.featureType}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FeatureLogs;
