import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/data';
import { Navigate } from 'react-router-dom';

function AdminLogin() {
    const { authURL } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const [username, setEmail] = useState('');
    const emailChangeHandler = (e) => {
        setEmail(e.target.value);
    }
    const [password, setPass] = useState('');
    const passChangeHandler = (e) => {
        setPass(e.target.value);
    }

    //? ==========API for LOGIN=========== 
    const login = async (e) => {
        e.preventDefault();
        const loginObj = { username, password };

        if (!username || !password) {
            return toast.error("Fill all the details first");
        }

        try {
            const response = await fetch(`${authURL}/admin-login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(loginObj),
            })

            if (response.status === 401) {
                return toast.error("Your're not registered yet!")
            } else if (response.status === 409) {
                return toast.error("Incorrect Password");
            } else if (response.status === 200) {
                toast.success("Successfully Logged In");
                const data = await response.json();
                localStorage.setItem('token', data.token);
                navigate('/admin');
            } else {
                toast.error("Timed out, Try again later!")
            }
        } catch (error) {
            console.log(error);
            toast.error("Internal Server Error!");
        }
    }

    return (
        <div className='bgHero'>
            <section>
                <div class="container flex items-center justify-center min-h-screen px-6 mx-auto ">
                    <form class="w-full max-w-md bg-white p-8 rounded-xl shadow-xl border-2 border-slate-100">
                        <svg id="logo-35" width="50" height="39" viewBox="0 0 50 39" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z" class="ccompli1" fill="#007AFF"></path> <path d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z" class="ccustom" fill="#312ECB"></path> </svg>

                        <h1 class="mt-4 text-2xl font-semibold text-gray-800 capitalize sm:text-3xl">Administrator</h1>
                        <p class="mt-2 text-gray-600">Sign In into your Administrator account!</p>

                        <div class="relative mt-4 mb-2">
                            <label class="leading-7 text-sm text-gray-600">Username</label>
                            <input value={username} onChange={emailChangeHandler} type="text" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>

                        <div class="relative mt-2 mb-4">
                            <label class="leading-7 text-sm text-gray-600">Password</label>
                            <input value={password} onChange={passChangeHandler} type="password" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-3 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>

                        <div class="mt-6">
                            <button onClick={login} className="px-6 w-full py-3 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200">
                                Sign in
                            </button>

                            <div class="mt-6 text-center ">
                                <a href="/" class="text-sm text-blue-500 hover:underline">
                                    Back to site
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default AdminLogin