import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../../context/data";

function QuotesHistory() {
  const { user } = useUser();
  const [quotesData, setQuotesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // New state for date filter
  const quotesPerPage = 6;
  const { authURL } = useAuth();

  const fetchQuotesData = async () => {
    try {
      const response = await fetch(
        `${authURL}/quotes-list?userID=${user.primaryEmailAddress.emailAddress}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const res = await response.json();

      // Sort quotesData in descending order by createdAt
      const sortedData = res.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setQuotesData(sortedData);
      setFilteredData(sortedData); // Initialize filtered data
    } catch (error) {
      console.error("Error fetching quotes data:", error);
    }
  };

  const handleDelete = async (quoteId) => {
    try {
      const response = await fetch(`${authURL}/delete-quotes?_id=${quoteId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete quote");
      }
      toast.success("Quote deleted successfully!");
      const updatedQuotes = quotesData.filter((quote) => quote._id !== quoteId);
      setQuotesData(updatedQuotes);
      setFilteredData(updatedQuotes);
    } catch (error) {
      console.error("Error deleting quote:", error);
      toast.error("Failed to delete quote");
    }
  };

  useEffect(() => {
    fetchQuotesData();
  }, []);

  const handleFilter = () => {
    const filtered = quotesData.filter((quote) => {
      const matchesSearchTerm =
        quote.QuotesTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.QuotesId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.status.toLowerCase().startsWith(searchTerm.toLowerCase());

      const matchesDate =
        selectedDate === "" ||
        new Date(quote.createdAt).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      return matchesSearchTerm && matchesDate;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  };

  useEffect(() => {
    handleFilter();
  }, [searchTerm, selectedDate, quotesData]);

  const getStatusClasses = (status) => {
    switch (status) {
      case "unpaid":
        return "bg-yellow-200 text-yellow-700 border-yellow-300";
      case "paid":
        return "bg-green-200 text-green-700 border-green-300";
      case "failed":
        return "bg-red-200 text-red-700 border-red-300";
      default:
        return "bg-gray-200 text-gray-700 border-gray-300";
    }
  };

  // Pagination calculations
  const indexOfLastQuote = currentPage * quotesPerPage;
  const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
  const currentQuotes = filteredData.slice(indexOfFirstQuote, indexOfLastQuote);
  const totalPages = Math.ceil(filteredData.length / quotesPerPage);

  return (
    <div className="md:max-w-4xl xl:max-w-6xl mx-auto mt-32 mb-64">
      <div className="flex w-full items-end justify-between">

        <div className="w-full">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Quote Reciept History
          </h1>
          <p className="mt-3 text-gray-600">History of generated Quote Reciept</p>
        </div>

        <div className="flex flex-col w-1/2">
          <div className="flex space-x-4 w-full">
            <input
              type="text"
              placeholder="Search Quotes..."
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[a-zA-Z0-9\s]*$/; // Allows only letters, numbers, and spaces

                if (value.length <= 16 && regex.test(value)) {
                  setSearchTerm(value);
                }
              }}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            {/* Date input for filtering */}
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <lable className="text-sm text-gray-400 mt-1">Filter Quote reciept with ID, Name, Status and with Date.</lable>
        </div>

      </div>

      {filteredData.length === 0 ? (
        <p className="text-gray-600 mt-10 text-center">No such quote</p>
      ) : (
        <>
          <div className="flex flex-wrap -m-2 mt-8">
            {currentQuotes.map((quote, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                <div className="max-w-lg md:p-8 rounded-lg bg-white border border-slate-200 shadow-lg p-8">
                  <div className="space-x-4">
                    <span className="text-xs font-medium text-gray-400">
                      <span className="bg-slate-100 border-2 tracking-wider border-slate-200 py-1 px-3 text-slate-700 rounded-full">
                        {quote.QuotesId}
                      </span>
                    </span>

                    <span
                      className={`text-xs font-medium border-2 tracking-wider py-1 px-3 rounded-full uppercase ${getStatusClasses(
                        quote.status
                      )}`}
                    >
                      {quote.status}
                    </span>
                  </div>

                  <h3 className="text-2xl font-semibold mt-4 tracking-wider text-gray-900 capitalize">
                    Title: {quote.QuotesTitle}
                  </h3>
                  <div className="text-sm text-gray-600">
                    Generated on:{" "}
                    {new Date(quote.createdAt).toLocaleDateString()}
                  </div>
                  <div className="mt-8 flex w-full space-x-4">
                    <Link
                      to={`/home/generate-docx/quotes-form/quotes-template?QuotesId=${quote.QuotesId}`}
                      className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                    >
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link
                      to={`/home/generate-docx/quotes-form/update-quotes?_id=${quote._id}`}
                      className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                    >
                      <i className="fa-regular fa-pen"></i>
                    </Link>
                    <button
                      className="activeBtn w-full px-8 py-2 rounded bg-gradient-to-b from-red-500 to-red-600 text-white focus:ring-2 focus:ring-red-400 hover:shadow-xl transition duration-200"
                      onClick={() => handleDelete(quote._id)}
                    >
                      <i className="fa-regular fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {filteredData.length === 0 ? (
            <p className="text-gray-600 mt-10 text-center">No such quote</p>
          ) : (
            <div className="mt-8 flex justify-center">
              {/* Previous Button */}
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                }
                disabled={currentPage === 1}
                className="px-4 py-2 rounded-md rounded-r-none bg-gray-200 text-gray-700 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
              >
                <i className="fa-regular fa-arrow-left"></i>
              </button>

              {/* Pagination Buttons */}
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => setCurrentPage(i + 1)}
                  className={`px-4 py-2 ${currentPage === i + 1
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                    }`}
                >
                  {i + 1}
                </button>
              ))}

              {/* Next Button */}
              <button
                onClick={() =>
                  setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-4 py-2 rounded-md rounded-l-none bg-gray-200 text-gray-700 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
              >
                <i className="fa-regular fa-arrow-right"></i>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default QuotesHistory;
