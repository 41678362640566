import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./HeroSection.css"; // Ensure this path is correct

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [nextSlide, setNextSlide] = useState(null);
  const slides = [
    {
      title: "Powerful, Fast and Easy PDF Tools",
      description:
        "No more hassle editing and managing your PDFs with our powerful tools like Watermark PDF, Split & Merge PDF, Encrypt & Decrypt PDF and JSON PDF and much more, Quickdoc provides all tools at the palm of your hand",
      logos: [
        "fa-file-code",
        "fa-file-dashed-line",
        "fa-merge",
        "fa-file-lock",
        "fa-file-circle-check",
        "fa-file-word",
        "fa-scanner-image",
      ],
      buttons: [
        { text: "Get started", link: "/register", primary: true },
        { text: "See Pricing", link: "/pricing" },
      ],
    },
    {
      title: "Generate Seamless Invoices & Receipts",
      description:
        "Say goodbye to manual paperwork! Transform Your Business with Lightning-Fast Invoices and Receipts instantly with our smart, user-friendly tool. Streamline your billing process and impress clients effortlessly.",
      logos: [
        "fa-file-invoice",
        "fa-receipt",
        "fa-file-invoice-dollar",
        "fa-quotes",
        "fa-receipt",
        "fa-memo-circle-info",
      ],
      buttons: [
        { text: "Get started", link: "/register", primary: true },
        { text: "See Pricing", link: "/pricing" },
      ],
    },
  ];

  const slideRef = useRef(null);

  useEffect(() => {
    if (slideRef.current) {
      gsap.fromTo(
        slideRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power2.out" }
      );
    }

    const interval = setInterval(() => {
      handleNext();
    }, 3000); // Automatically slide every 36 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);

  const handlePrevious = () => {
    const prevSlide = (currentSlide - 1 + slides.length) % slides.length;
    animateSlideChange(prevSlide);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    animateSlideChange(nextSlide);
  };

  const animateSlideChange = (newSlide) => {
    if (slideRef.current) {
      gsap.to(slideRef.current, {
        opacity: 0,
        y: -50,
        duration: 1,
        ease: "power2.in",
        onComplete: () => {
          setCurrentSlide(newSlide);
          setNextSlide(newSlide);
          gsap.fromTo(
            slideRef.current,
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 1.5, ease: "power2.out" }
          );
        },
      });
    }
  };

  return (
    <div className="relative overflow-hidden min-h-screen">
      <div
        aria-hidden="true"
        className="flex absolute -top-96  start-1/2 transform -translate-x-1/2"
      >
        <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[144rem] rotate-[-60deg] transform -translate-x-[10rem]"></div>
        <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[80rem] rounded-full origin-top-left -rotate-12 -translate-x-[15rem]"></div>
        <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[144rem] rotate-[-60deg] transform -translate-x-[10rem]"></div>
      </div>

      <div className="z-10  flex flex-col items-center justify-center h-screen my-auto mx-auto relative">
        <div className="absolute md:block hidden left-6 md:left-12 top-1/2 transform -translate-y-1/2">
          <button
            className="w-12 h-12 p-2 border-2 border-gray-300 text-white rounded-full focus:outline-none"
            onClick={handlePrevious}
          >
            <i class="fa-light fa-arrow-left text-gray-700"></i>
          </button>
        </div>

        <div className="absolute md:block hidden  right-6 md:right-12 top-1/2 transform -translate-y-1/2">
          <button
            className="w-12 h-12 p-2 border-2 border-gray-300 text-white rounded-full focus:outline-none"
            onClick={handleNext}
          >
            <i class="fa-light fa-arrow-right text-gray-700"></i>
          </button>
        </div>

        {/* Slide Content */}
        <div ref={slideRef} className="relative text-center -mt-20 md:mt-12">
          <div className="max-w-3xl lg:max-w-6xl mx-auto">
            <p className="inline-block text-sm sm:text-base lg:text-xl font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              Welcome to QuickDoc
            </p>

            <div className="mt-3 md:mt-5 max-w-8xl">
              <h1 className="block font-semibold text-gray-800 text-2xl sm:text-4xl md:text-5xl lg:text-6xl">
                {slides[currentSlide].title}
              </h1>
            </div>

            <div className="mt-3 md:mt-5 max-w-2xl lg:max-w-6xl">
              <p className="text-sm sm:text-lg text-gray-600">
                {slides[currentSlide].description}
              </p>
            </div>

            {/* Logos */}
            <div className="flex flex-wrap justify-around mb-16 mt-12">
              {slides[currentSlide].logos.map((iconClass, index) => (
                <i
                  key={index}
                  className={`fa-light ${iconClass} text-3xl sm:text-4xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent`}
                  aria-hidden="true"
                ></i>
              ))}
            </div>

            <div className="gap-2 sm:gap-3 flex sm:flex-row justify-center">
              {slides[currentSlide].buttons.map((button, index) => (
                <a
                  key={index}
                  className={`py-2 px-4 md:w-auto w-28 sm:py-3 text-center sm:px-8 text-sm md:text-base fontAlt inline-flex items-center gap-x-2 font-medium rounded-full border border-transparent ${
                    button.primary
                      ? "bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                      : "text-gray-700 hover:text-gray-900"
                  }`}
                  href={button.link}
                >
                  {button.text}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
