import React from 'react'

function ProfitSales() {
    return (
        <div className='h-screen'>

            <div className='flex items-center justify-between px-4'>
                <div>
                    <h1 className='text-5xl font-semibold'>Total Sales & Profit</h1>
                    <h1 className='text-gray-600'>Data of total sales & profit from our Subscriptions packages</h1>
                </div>
                <div>
                    <h1 className='text-7xl text-green-500'>$2500</h1>
                </div>
            </div>

            <div className='bg-white shadow-sm border-1 border-slate-300 p-4 rounded-lg my-12'>
                <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                    <thead className="hidden border-b lg:table-header-group">
                        <tr className="">
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                User Mail
                            </td>

                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Package Category
                            </td>

                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Package Cost
                            </td>

                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Package Subscription
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Purchased on
                            </td>
                            <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                Valid till
                            </td>
                        </tr>
                    </thead>
                    <tbody className="bg-white lg:border-gray-300">
                        <tr className='border-b border-slate-200'>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                22 July 2024
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                62345231143
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                test@gmail.com
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Jane Doeson
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Free
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
                                <span className="ml-2 mr-3 whitespace-nowrap rounded-full bg-yellow-100 px-4 py-2">
                                    Freemium
                                </span>
                            </td>
                        </tr>

                        <tr className='border-b border-slate-200'>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                22 July 2024
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                62345231143
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                test@gmail.com
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Jane Doeson
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Free
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
                                <span className="ml-2 mr-3 whitespace-nowrap rounded-full bg-yellow-100 px-4 py-2">
                                    Freemium
                                </span>
                            </td>
                        </tr>

                        <tr className='border-b border-slate-200'>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                22 July 2024
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                62345231143
                            </td>
                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                test@gmail.com
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Jane Doeson
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                Free
                            </td>

                            <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
                                <span className="ml-2 mr-3 whitespace-nowrap rounded-full bg-yellow-100 px-4 py-2">
                                    Freemium
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default ProfitSales