import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../../context/data";

function PurchaseHistory() {
  const { user } = useUser();
  const [purchaseData, setPurchaseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // New state for date filter
  const { authURL } = useAuth();
  const purchasesPerPage = 6;

  const fetchPurchasesData = async () => {
    try {
      const response = await fetch(
        `${authURL}/purchase-list?userID=${user.primaryEmailAddress.emailAddress}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const res = await response.json();
      const sortedData = res.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setPurchaseData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error("Error fetching Purchase data:", error);
    }
  };

  const handleDelete = async (purchaseId) => {
    try {
      const response = await fetch(
        `${authURL}/delete-purchase?_id=${purchaseId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete Purchase");
      }
      toast.success("Purchase deleted successfully!");
      const updatedPurchases = purchaseData.filter(
        (purchase) => purchase._id !== purchaseId
      );
      setPurchaseData(updatedPurchases);
      setFilteredData(updatedPurchases);
    } catch (error) {
      console.error("Error deleting Purchase:", error);
      toast.error("Failed to delete Purchase");
    }
  };

  useEffect(() => {
    fetchPurchasesData();
  }, []);

  useEffect(() => {
    const filtered = purchaseData.filter((purchase) => {
      const matchesSearchTerm =
        purchase.PurchaseTitle.toLowerCase().includes(filter.toLowerCase()) ||
        purchase.PurchaseId.toLowerCase().includes(filter.toLowerCase()) ||
        purchase.status.toLowerCase().includes(filter.toLowerCase()) ||
        new Date(purchase.createdAt).toLocaleDateString().includes(filter);

      const matchesDate =
        selectedDate === "" ||
        new Date(purchase.createdAt).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      return matchesSearchTerm && matchesDate;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when filtering
  }, [filter, selectedDate, purchaseData]);

  const getStatusClasses = (status) => {
    switch (status) {
      case "unpaid":
        return "bg-yellow-200 text-yellow-700 border-yellow-300";
      case "paid":
        return "bg-green-200 text-green-700 border-green-300";
      case "failed":
        return "bg-red-200 text-red-700 border-red-300";
      default:
        return "bg-gray-200 text-gray-700 border-gray-300";
    }
  };

  const indexOfLastPurchase = currentPage * purchasesPerPage;
  const indexOfFirstPurchase = indexOfLastPurchase - purchasesPerPage;
  const currentPurchases = filteredData.slice(
    indexOfFirstPurchase,
    indexOfLastPurchase
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="md:max-w-4xl xl:max-w-6xl mx-auto mt-32 mb-64">

      <div className="flex w-full items-end justify-between">

        <div className="w-full">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Purchase Reciept History
          </h1>
          <p className="mt-3 text-gray-600">History of generated Purchase Reciept</p>
        </div>

        <div className="flex flex-col w-1/2">
          <div className="flex space-x-4 w-full">
            <input
              type="text"
              placeholder="Search purchases..."
              value={filter}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[a-zA-Z0-9\s]*$/; // Allows only letters, numbers, and spaces

                if (value.length <= 16 && regex.test(value)) {
                  setFilter(value);
                }
              }}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            {/* Date input for filtering */}
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <lable className="text-sm text-gray-400 mt-1">Filter Purchase reciept with ID, Name, Status and with Date.</lable>
        </div>

      </div>


      {currentPurchases.length === 0 ? (
        <p className="text-center text-gray-500 mt-8">
          No purchases found matching your search.
        </p>
      ) : (
        <div className="flex flex-wrap -m-2 mt-8">
          {currentPurchases.map((purchase, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
              <div className="max-w-lg md:p-8 rounded-lg bg-white border border-slate-200 shadow-lg p-8">
                <div className="space-x-4">
                  <span className="text-xs font-medium text-gray-400">
                    <span className="bg-slate-100 border-2 tracking-wider border-slate-200 py-1 px-3 text-slate-700 rounded-full">
                      {purchase.PurchaseId}
                    </span>
                  </span>
                  <span
                    className={`text-xs font-medium border-2 tracking-wider py-1 px-3 rounded-full uppercase ${getStatusClasses(
                      purchase.status
                    )}`}
                  >
                    {purchase.status}
                  </span>
                </div>
                <h3 className="text-2xl font-semibold mt-4 tracking-wider text-gray-900 capitalize">
                  Title: {purchase.PurchaseTitle}
                </h3>
                <div className="text-sm text-gray-600">
                  Generated on:{" "}
                  {new Date(purchase.createdAt).toLocaleDateString()}
                </div>
                <div className="mt-8 flex w-full space-x-4">
                  <Link
                    to={`/home/generate-docx/purchase-form/purchase-template?PurchaseId=${purchase.PurchaseId}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-eye"></i>
                  </Link>
                  <Link
                    to={`/home/generate-docx/purchase-form/update-purchase?_id=${purchase._id}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-pen"></i>
                  </Link>
                  <button
                    className="activeBtn w-full px-8 py-2 rounded bg-gradient-to-b from-red-500 to-red-600 text-white focus:ring-2 focus:ring-red-400 hover:shadow-xl transition duration-200"
                    onClick={() => handleDelete(purchase._id)}
                  >
                    <i className="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {currentPurchases.length > 0 ? (
        <div className="flex justify-center mt-8">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-4 py-2 rounded-r-none border text-sm font-medium rounded-md ${currentPage === 1
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                }`}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>

            {Array.from({
              length: Math.ceil(filteredData.length / purchasesPerPage),
            }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === index + 1
                  ? "z-10 border-indigo-500 bg-blue-600 text-white"
                  : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                  }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredData.length / purchasesPerPage)
              }
              className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md rounded-l-none ${currentPage ===
                Math.ceil(filteredData.length / purchasesPerPage)
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                }`}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </nav>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PurchaseHistory;
