import React, { useEffect, useState } from 'react';
import { SignedIn, useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';

function PremiumProtectedRoute({ Component }) {
    const { authURL } = useAuth();
    const { isSignedIn, user, isLoaded } = useUser();
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const getStatus = async () => {
            if (isSignedIn && user) {
                try {
                    const response = await fetch(`${authURL}/get-status?email=${user.primaryEmailAddress.emailAddress}`);
                    if (response.status === 404) {
                        console.error("Status not found");
                        return;
                    }
                    const data = await response.json();
                    console.log("API Response:", data.status);
                    setStatus(data.status);
                } catch (error) {
                    console.error("Error fetching status:", error);
                }
            }
        };

        getStatus();
    }, [isSignedIn, user, authURL]);

    useEffect(() => {
        console.log("Status updated:", status);
    }, [status]);

    if (!isLoaded) {
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='loader'></span>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" />;
    }

    if (status === null) {
        return (
            <div className='flex items-center justify-center h-screen'>
                <span className='loader'></span>
            </div>
        );
    }

    if (status !== 'premium') {
        return <Navigate to="/pricing" />;
    }

    return (
        <SignedIn>
            <Component />
        </SignedIn>
    );
}

export default PremiumProtectedRoute;
