import React from "react";
import { Link } from "react-router-dom";
import {
  FileText,
  Hospital,
  Database,
  ArrowRight,
  CheckCircle,
  Users,
  Clock,
  Shield,
  ChevronRight,
} from "lucide-react";
import DMSFeature from "../components/Feature/DMSFeature";
import Overview from "../components/OverView";
import { Helmet } from "react-helmet-async";

const ServiceCard = ({ icon: Icon, text }) => (
  <div className="flex items-center p-3 sm:p-4 bg-white rounded-xl shadow-md transition-all duration-300 hover:shadow-lg hover:scale-105 border border-gray-100">
    <Icon className="w-6 h-6 sm:w-8 sm:h-8 text-indigo-600 mr-3 sm:mr-4 flex-shrink-0" />
    <span className="text-xs sm:text-sm font-semibold text-gray-800">
      {text}
    </span>
  </div>
);

const StatCard = ({ icon: Icon, value, label }) => (
  <div className="bg-white p-4 sm:p-6 rounded-xl shadow-md flex items-center space-x-3 sm:space-x-4 border border-gray-100">
    <div className="bg-indigo-100 p-2 sm:p-3 rounded-full">
      <Icon className="w-6 h-6 sm:w-8 sm:h-8 text-indigo-600" />
    </div>
    <div>
      <div className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800">
        {value}
      </div>
      <div className="text-xs sm:text-sm font-medium text-gray-600">
        {label}
      </div>
    </div>
  </div>
);

const Product = () => {
  const services = [
    { icon: FileText, text: "Document Management System" },
    { icon: Hospital, text: "Hospital Management System" },
    { icon: Database, text: "Oracle APEX Services" },
    { icon: ArrowRight, text: "Migration Services" },
  ];

  const stats = [
    { icon: Users, value: "10,000+", label: "Users" },
    { icon: CheckCircle, value: "99.9%", label: "Uptime" },
    { icon: Clock, value: "24/7", label: "Support" },
    { icon: Shield, value: "ISO 27001", label: "Certified" },
  ];

  return (
    <>
      <Helmet>
        <title>
          QuickDoc Products - PaaS, SaaS, and Migration Solutions for Document
          Management
        </title>
        <meta
          name="description"
          content="Explore QuickDoc’s range of products, including PaaS, SaaS, and migration services designed to streamline document management, storage, and creation processes for businesses of all sizes."
        />
        <meta
          name="keywords"
          content="QuickDoc products, PaaS document solutions, SaaS document management, migration services, document generation, PDF tools, DOCX management"
        />
        <meta
          property="og:title"
          content="QuickDoc Products - PaaS, SaaS, and Migration Solutions"
        />
        <meta
          property="og:description"
          content="QuickDoc offers diverse products: PaaS for scalable document services, SaaS for easy-to-use tools, and migration services to transition to better document management systems."
        />
        <meta
          property="og:image"
          content="https://www.quickdoc.app/static/media/res1.5aed9cb43b01dd3ee8d4.png"
        />
        <meta property="og:url" content="https://www.quickdoc.app/products" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="QuickDoc Products - PaaS, SaaS, and Migration Solutions for Document Management"
        />
        <meta
          name="twitter:description"
          content="Learn about QuickDoc’s solutions for businesses, from scalable PaaS and convenient SaaS to seamless migration services."
        />
        <meta
          name="twitter:image"
          content="https://www.quickdoc.app/static/media/in1.41c7bd6d7d6bd533b35a.png"
        />
      </Helmet>

      <div className="max-w-[90rem] md:mt-0 mt-10  mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <section className="relative py-12 sm:py-20 md:py-32 overflow-hidden">
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiAgPHBhdGggZD0iTTAgMGg2MHY2MEgweiIgZmlsbD0ibm9uZSIvPgogIDxwYXRoIGQ9Ik0zMCAzMG0tMjggMGEyOCAyOCAwIDEgMCA1NiAwYTI4IDI4IDAgMSAwLTU2IDB6IiBmaWxsPSJub25lIiBzdHJva2U9IiNFOEVBRjYiIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPg==')] opacity-20"></div>

          <div className="relative container mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              {/* Left Column */}
              <div className="w-full lg:w-1/2 mb-12 lg:mb-0 lg:pr-6 xl:pr-12">
                <div className="inline-block py-2 px-4 mb-6 text-xs sm:text-sm font-semibold text-indigo-800 bg-indigo-100 rounded-full animate-pulse">
                  Innovative SaaS Solutions
                </div>

                <h1 className="font-heading text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6">
                  <span className="block sm:inline">Transform Your </span>
                  <span className="text-indigo-600 relative inline-block">
                    Business
                    <svg
                      className="absolute -bottom-2 left-0 w-full hidden sm:block"
                      viewBox="0 0 358 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 9C118.957 4.47226 364.497 -1.86658 355 9"
                        stroke="#818CF8"
                        strokeWidth="5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                  <span className="block sm:inline mt-1 sm:mt-0 sm:ml-2">
                    with Our Services
                  </span>
                </h1>

                <p className="text-base sm:text-lg lg:text-xl text-gray-700 font-medium mb-6 sm:mb-10">
                  Elevate your operations with our cutting-edge DMS, HMS, and
                  Oracle APEX solutions.
                </p>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-8 sm:mb-10">
                  {stats.map((stat, index) => (
                    <StatCard key={index} {...stat} />
                  ))}
                </div>

                <Link
                  to="/contact-us"
                  className="w-full sm:w-auto inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-indigo-600 to-blue-600 text-white font-semibold rounded-lg hover:from-indigo-700 hover:to-blue-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400 shadow-lg"
                  type="button"
                  aria-label="Get Started"
                >
                  Get Started
                  <ChevronRight className="ml-2 w-4 h-4 sm:w-5 sm:h-5" />
                </Link>
              </div>

              {/* Right Column */}
              <div className="w-full lg:w-1/2 px-4 sm:px-0">
                <div className="relative">
                  <img
                    className="w-full h-auto rounded-xl sm:rounded-2xl transition-transform duration-300 hover:scale-105"
                    src="https://img.freepik.com/premium-vector/two-professionals-engage-with-laptops-focusing-big-data-management-insights-while-surrounded-by-graphs-charts-digital-tools_538213-138026.jpg?ga=GA1.1.1469234575.1726214580&semt=ais_hybrid"
                    alt="SaaS Services Illustration"
                  />

                  <div className="absolute -bottom-4 sm:-bottom-6 -right-4 sm:-right-6 bg-white p-4 sm:p-6 rounded-lg sm:rounded-xl shadow-xl">
                    <div className="flex items-center mb-2 sm:mb-3">
                      <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6 text-green-500 mr-2 sm:mr-3" />
                      <span className="text-xs sm:text-sm font-bold text-gray-800">
                        Enterprise-grade Security
                      </span>
                    </div>
                    <div className="flex items-center">
                      <CheckCircle className="w-5 h-5 sm:w-6 sm:h-6 text-green-500 mr-2 sm:mr-3" />
                      <span className="text-xs sm:text-sm font-bold text-gray-800">
                        Scalable Solutions
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Overview */}
        <Overview />

        {/* Features */}
        <DMSFeature />
      </div>
    </>
  );
};

export default Product;
