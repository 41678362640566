import React from "react";
import { Calendar, ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import data from "../assets/Data/Projects.json";

// Import images for zatca
import zatca1 from "../assets/Projects/Zatca/zat1.png";
// Import images for resturant
import res1 from "../assets/Projects/Resturant/res1.png";
// Import images for TMS
import TMS1 from "../assets/Projects/TMS/TMS1.png";
//Import images for DMS
import sc2 from "../assets/Projects/Scanner/sc2.png";
//Import images for IMS
import in1 from "../assets/Projects/Investment/in1.png";
//Import images for PMS
import pt1 from "../assets/Projects/PTrack/pt1.png";
//Import images for EPS
import EPS1 from "../assets/Projects/EPS/EPS1.png";
//Import images for Property Management System
import PMS1 from "../assets/Projects/Property/PMS1.png";
//Import images for Tax Filing System
import TFS1 from "../assets/Projects/TaxSlip/TFS1.png";
//Import images for School System
import SMS1 from "../assets/Projects/School/SMS1.png";
//Import images for Share Investment System
import SIS1 from "../assets/Projects/Shared/SIS1.png";

import cloud1 from "../assets/Projects/Scanner/sc1.png"; // Corrected the import path

// Map image filenames to their imports
const images = {
  zatca1,
  cloud1,
  //returant image
  res1,
  //TMS1 image
  TMS1,
  //Scanner image
  sc2,
  //IMS image
  in1,
  //IMS image
  pt1,
  //IMS image
  EPS1,
  //PMS image
  PMS1,
  //Tax Filing System Images
  TFS1,
  //School System Images
  SMS1,
  //Shared INV Images
  SIS1,
  // Add other images here as needed
};

// Fallback image path
const defaultImage = "/path/to/default-image.png";

const EventCard = ({ id, title, date, category, imageSrc, type }) => (
  <Link
    to={`/detail/${id}`}
    className="group rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col h-full"
  >
    <div className="relative h-48 overflow-hidden">
      <img
        src={imageSrc}
        alt={title}
        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-60"></div>
      <div className="absolute top-4 left-4 transform -rotate-3">
        <span
          className={`px-3 py-1 text-gray-900 text-xs font-bold uppercase rounded-full shadow-md ${
            type === "product" ? "bg-yellow-400" : "bg-green-400"
          }`}
        >
          {category}
        </span>
      </div>
    </div>
    <div className="p-6 flex-grow flex flex-col">
      <h3 className="text-xl font-bold mb-3 text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
        {title}
      </h3>
      <div className="flex items-center text-gray-600 mb-4">
        <Calendar size={16} className="mr-2 text-blue-500" />
        <p className="text-sm">{date}</p>
      </div>
      <div className="mt-auto">
        <Link
          to={`/detail/${id}`}
          className="group inline-flex items-center px-4 py-2 bg-blue-100 text-blue-700 rounded-lg hover:bg-blue-200 transition duration-300"
        >
          <span className="mr-2">Explore More</span>
          <ArrowRight
            size={16}
            className="transition-transform duration-300 group-hover:translate-x-1"
          />
        </Link>
      </div>
    </div>
    <div
      className={`h-1 ${
        type === "product"
          ? "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
          : "bg-gradient-to-r from-green-400 via-teal-500 to-blue-500"
      }`}
    ></div>
  </Link>
);

const OverView = () => {
  const allItems = data.projects;
  const products = allItems.filter((item) => item.category === "product");
  const services = allItems.filter((item) => item.category === "service");
  const migration = allItems.filter((item) => item.category === "migration");

  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-4 pb-4 bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
          Our Latest Integration & DMS Products
        </h2>
        <p className="text-center text-gray-600 text-lg mb-12 max-w-2xl mx-auto">
          Explore our innovative solutions and experience our collaborative
          platform first-hand.
        </p>

        {/* Products Section */}
        <h2 className="text-3xl font-bold mb-4 pb-4 bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
          As a Product
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {products.map((product) => (
            <EventCard
              key={product.id}
              id={product.id}
              title={product.intro.title}
              date="August 2023" // Adjust this date as needed
              category={product.intro.badge}
              imageSrc={images[product.imageGallery.main.src] || defaultImage}
              type="product"
            />
          ))}
        </div>

        {/* Services Section */}
        <h2 className="text-3xl font-bold mb-4 pb-4 bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
          As a Service
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <EventCard
              key={service.id}
              id={service.id}
              title={service.intro.title}
              date="September 2023" // Adjust this date as needed
              category={service.intro.badge}
              imageSrc={images[service.imageGallery.main.src] || defaultImage}
              type="service"
            />
          ))}
        </div>

        {/* migration Section */}
        <h2 className="text-3xl font-bold mb-4 pb-4 bg-clip-text bg-gradient-to-l mt-5 from-blue-600 to-violet-500 text-transparent">
          Migration
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {migration.map((migration) => (
            <EventCard
              key={migration.id}
              id={migration.id}
              title={migration.intro.title}
              date="September 2023" // Adjust this date as needed
              category={migration.intro.badge}
              imageSrc={images[migration.imageGallery.main.src] || defaultImage}
              type="service"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OverView;
