import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Solutions = () => {
  return (
    <>
      <Helmet>
        <title>
          QuickDoc Solutions - Web DMS and API for Document Management
        </title>
        <meta
          name="description"
          content="Discover QuickDoc’s Web DMS and API solutions. Seamlessly manage, store, and access documents with our web-based DMS, or integrate document creation features into your app with our robust API."
        />
        <meta
          name="keywords"
          content="document management solutions, QuickDoc API, Web DMS, document storage, API integration, PDF and DOCX API, document management system"
        />
        <meta
          property="og:title"
          content="QuickDoc Solutions - Web DMS and API"
        />
        <meta
          property="og:description"
          content="QuickDoc provides flexible Web DMS and API solutions to streamline document management and creation for businesses. Manage documents effortlessly or integrate our tools with your platform."
        />
        <meta
          property="og:image"
          content="https://www.quickdoc.app/static/media/ss2.59e19d71ac4c4c36e691.png"
        />
        <meta property="og:url" content="https://www.quickdoc.app/solutions" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="QuickDoc Solutions - Web DMS and API for Document Management"
        />
        <meta
          name="twitter:description"
          content="QuickDoc offers solutions for web-based document management and API integration for document generation and storage."
        />
        <meta
          name="twitter:image"
          content="https://www.quickdoc.app/static/media/ss2.59e19d71ac4c4c36e691.png"
        />
      </Helmet>
      {/* page starter */}
      <div class="max-w-7xl mx-auto flex flex-col mt-40 items-start justify-center">
        <div class="w-full flex items-center justify-center flex-col gap-5 mb-14">
          <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
            Solutions
          </span>
          <h1 className="font-light  capitalize  md:text-5xl">
            Get it as You
            <span className="underline ml-2 font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              Want
            </span>{" "}
            !
          </h1>
          <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
            By focusing on these three pillars—ERP, WEB APP, and APIs—we empower
            organizations with the tools they need to create cohesive,
            efficient, and future-ready structures.
          </p>
        </div>
      </div>
      {/* section */}
      <section className="relative py-10 overflow-hidden">
        <div className="relative container px-4 mx-auto">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-wrap -mx-4 mb-18 items-center">
              <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
                <div>
                  <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">
                    Solution We offering
                  </span>
                  <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
                    <span>Delivering Tech</span>
                    <span className="font-serif italic">Solutions</span>
                  </h1>
                </div>
              </div>
              <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
                <div className="max-w-sm lg:ml-auto">
                  <p className="text-lg text-gray-500">
                    We are offering various type of solution to match your new
                    and existing business to ease the process
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full xl:w-1/2 px-4 mb-8 xl:mb-0">
                <div className="relative h-[600px] md:h-125">
                  <img
                    className="block h-full w-full rounded-3xl object-cover"
                    src="https://w0.peakpx.com/wallpaper/278/606/HD-wallpaper-cadetblue-windows-11-logo-windows-11.jpg"
                    alt=""
                  />
                  <div className="absolute top-0 left-0 h-full w-full p-8 md:p-12">
                    <div className="flex flex-col items-start justify-between h-full max-w-sm">
                      <div>
                        <span className="block mb-3 text-sm text-cyan-900 font-semibold uppercase">
                          Solutions
                        </span>
                        <h3 className="text-3xl md:text-4xl  text-white font-semibold">
                          You can avail every various solutions for your
                          business
                        </h3>
                      </div>
                      <Link
                        className="relative group inline-block w-full xs:w-auto py-4 px-6 text-orange-900 hover:text-white font-semibold bg-orange-50 rounded-md overflow-hidden transition duration-500"
                        to="/product"
                      >
                        <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500" />
                        <div className="relative flex items-center justify-center">
                          <span className="mr-4">See Incredible Work</span>
                          <span>
                            <svg
                              width={13}
                              height={13}
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-1/2 px-4">
                <div className="flex flex-wrap h-full -mx-4">
                  <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                    <div className="flex flex-col h-full">
                      <Link
                        className="relative block h-full mb-7 pt-8 px-8 pb-5 rounded-3xl bg-blue-50 hover:bg-blue-100 transition duration-200"
                        to="/webAppsolutions"
                      >
                        <div className="flex flex-col h-full justify-between max-w-sm pr-16">
                          <p className="text-sm text-gray-900 mb-10 md:mb-6">
                            You can avail web solutions within this website
                          </p>
                          <span className="text-3xl font-semibold text-gray-900">
                            WEB APPS
                          </span>
                        </div>
                        <img
                          className="absolute bottom-0 right-0 m-5"
                          src="https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/features/arrow.svg"
                          alt=""
                        />
                      </Link>
                      <Link
                        className="relative block h-full pt-8 px-8 pb-5 rounded-3xl bg-red-50 hover:bg-red-100 transition duration-200"
                        to="/dmssolutions"
                      >
                        <div className="flex flex-col h-full justify-between max-w-sm pr-16">
                          <p className="text-sm text-gray-900 mb-10 md:mb-6">
                            Get it as pre-made DMS product for handling your
                            docx
                          </p>
                          <span className="text-3xl font-semibold text-gray-900">
                            DMS
                          </span>
                        </div>
                        <img
                          className="absolute bottom-0 right-0 m-5"
                          src="https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/features/arrow.svg"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <Link
                    to="/apisolution"
                    className=" w-full z-[9] md:w-1/2 px-4 relative block md:h-full md:mx-0 mx-4 pt-8 pb-5 rounded-3xl bg-purple-50 hover:bg-purple-100 transition duration-200"
                  >
                    <div className="flex h-full flex-col items-start justify-between max-w-sm pr-16">
                      <p className="text-sm text-gray-900 mb-10 md:mb-6">
                        You can also get the REST APIs for all DMS features that
                        can easily integrate into any system.
                      </p>
                      <span className="text-3xl font-semibold text-gray-900">
                        API Solution
                      </span>
                    </div>
                    <img
                      className="absolute bottom-0 right-0 m-5"
                      src="https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/features/arrow.svg"
                      alt="err"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="absolute top-0 right-0"
          src="https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/features/star-element-right.png"
          alt=""
        />
      </section>

      <></>
    </>
  );
};

export default Solutions;
