import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../context/data';
import toast from 'react-hot-toast'

const ManageClientModal = ({ isVisible, onClose }) => {
    const { authURL, clientId } = useAuth();
    const [clientData, setClientData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        clientName: '',
        clientPhone: '',
        clientEmail: '',
        clientAddress: ''
    });
    const [saving, setSaving] = useState(false); // New state for save loading

    useEffect(() => {
        if (clientId && isVisible) {
            fetchClientData();
        }
    }, [clientId, isVisible]);

    const fetchClientData = async () => {
        console.log(`Fetching data from: ${authURL}/get-client-by-id?id=${clientId}`);
        try {
            const response = await fetch(`${authURL}/get-client-by-id?id=${clientId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log('Client data received:', data);
            if (data.data && data.data.length > 0) {
                setClientData(data.data[0]);
                setFormData({
                    clientName: data.data[0].clientName,
                    clientPhone: data.data[0].clientPhone,
                    clientEmail: data.data[0].clientEmail,
                    clientAddress: data.data[0].clientAddress
                });
            } else {
                console.log('Client data not found');
            }
        } catch (error) {
            console.error('Error fetching client data:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleInputChange = (e) => {
        const { name } = e.target;
        let cleanedValue;
        if (name === 'clientEmail') {
            cleanedValue = e.target.value
                .replace(/[^a-zA-Z0-9@._-]/g, '')
                .trim();
        } else {
            cleanedValue = e.target.value;

            if (cleanedValue.startsWith(' ')) {
                cleanedValue = cleanedValue.trimStart();
            }
        }

        setFormData(prevData => ({ ...prevData, [name]: cleanedValue }));
    };
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData(prevData => ({ ...prevData, [name]: value }));
    // };

    const handleUpdate = async () => {
        if (formData.clientName === '') {
            toast.error("Client Name is Required!");
            return;
        }
        setSaving(true); // Start loading state
        try {
            const response = await fetch(`${authURL}/update-client?id=${clientId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedData = await response.json();
            toast.success("Client info Updated Successfully")
            setClientData(updatedData);
            setEditMode(false);
        } catch (error) {
            console.error('Error updating client data:', error);
        } finally {
            setSaving(false); // End loading state
        }
    };

    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] bg-opacity-75 pt-24 px-8 backdrop-blur-sm flex justify-center items-center'>
            <div className='flex flex-col md:max-w-3xl xl:max-w-5xl mx-auto w-full'>
                <div className='bg-white h-full px-6 py-6 flex flex-col w-full md:max-w-3xl xl:max-w-4xl mx-auto border-2 border-slate-100 rounded shadow-sm'>
                    <div className='flex items-start justify-between'>
                        <div>
                            <h1 className='text-2xl font-semibold'>Client Information</h1>
                            <p className='text-sm text-gray-500'>Manage your Client Information for your business</p>
                        </div>

                        <button
                            onClick={onClose}
                            className='text-gray-800 text-sm'
                            aria-label='Close modal'
                        >
                            <i className="fa-solid fa-xmark fa-xl"></i>
                        </button>
                    </div>
                    {loading && <p>Loading...</p>}
                    {clientData && (
                        <div className='flex flex-col space-y-4 mt-8'>
                            {editMode ? (
                                <div className='flex flex-col space-y-4'>
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Client Name</p>
                                        <input
                                            type='text'
                                            name='clientName'
                                            maxLength={32}
                                            value={formData.clientName}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Prevent leading spaces
                                                cleanedValue = cleanedValue.trimStart();

                                                // Remove any special characters
                                                cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s]/g, '');

                                                // Update the form data
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    [e.target.name]: cleanedValue,
                                                }));
                                            }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Phone</p>
                                        <input
                                            type='text'
                                            name='clientPhone'
                                            maxLength={32}
                                            value={formData.clientPhone}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Prevent leading spaces
                                                cleanedValue = cleanedValue.trimStart();

                                                // Allow only numeric characters
                                                cleanedValue = cleanedValue.replace(/[^0-9]/g, '');

                                                // Update the form data
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    [e.target.name]: cleanedValue,
                                                }));
                                            }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Email</p>
                                        <input
                                            type='text'
                                            name='clientEmail'
                                            maxLength={32}
                                            value={formData.clientEmail}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Prevent the input from starting with @
                                                if (cleanedValue.startsWith('@')) {
                                                    cleanedValue = cleanedValue.slice(1); // Remove the @ at the start
                                                }

                                                // Allow only alphanumeric characters, dashes, @, and dots
                                                cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9@.-]/g, '');

                                                // Ensure only one @ symbol is present
                                                const atSymbolCount = (cleanedValue.match(/@/g) || []).length;
                                                if (atSymbolCount > 1) {
                                                    cleanedValue = cleanedValue.replace(/@/g, (match, offset) => offset === cleanedValue.indexOf('@') ? match : '');
                                                }

                                                // Update the form data
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    [e.target.name]: cleanedValue,
                                                }));
                                            }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Address</p>
                                        <input
                                            type='text'
                                            name='clientAddress'
                                            maxLength={62}
                                            value={formData.clientAddress}
                                            onChange={(e) => {
                                                let cleanedValue = e.target.value;

                                                // Prevent leading spaces
                                                cleanedValue = cleanedValue.trimStart();

                                                // Remove any special characters, allow only letters, numbers, and spaces
                                                cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s]/g, '');

                                                // Update the form data
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    [e.target.name]: cleanedValue,
                                                }));
                                            }}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>

                                    <button
                                        onClick={handleUpdate}
                                        className='px-8 w-full py-4 tracking-wide rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200 flex items-center justify-center'
                                        disabled={saving} // Disable button when saving
                                    >
                                        {saving ? (
                                            <>
                                                <i className="fa-solid fa-spinner fa-spin mr-2"></i> Saving...
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa-regular fa-save mr-2"></i> Save Changes
                                            </>
                                        )}
                                    </button>
                                </div>
                            ) : (
                                <div className='flex flex-col space-y-4'>
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Client Name</p>
                                        <h1 className='font-semibold text-end w-full'>{clientData.clientName}</h1>
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Phone</p>
                                        <h1 className='font-semibold text-end w-full'>{clientData.clientPhone}</h1>
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Email</p>
                                        <h1 className='font-semibold text-end w-full'>{clientData.clientEmail}</h1>
                                    </div>
                                    <hr />
                                    <div className='flex items-end justify-between'>
                                        <p className='text-gray-500 w-1/2'>Address</p>
                                        <h1 className='font-semibold text-end w-full'>{clientData.clientAddress}</h1>
                                    </div>

                                    <button
                                        onClick={() => setEditMode(true)}
                                        className='px-8 w-full py-4 tracking-wide rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200'
                                    >
                                        <i className="fa-regular fa-pen-to-square mr-2"></i> Edit Client Information
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageClientModal;
