import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/data';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

function PricePlans() {
    const [packageName, setpackageName] = useState('')
    const [packageCategory, setpackageCategory] = useState('')
    const [packageCost, setpackageCost] = useState('')
    const [description, setdescription] = useState('')
    const { authURL } = useAuth();
    const [pacakgeData, setData] = useState([]);

    const addPackage = async () => {
        const packageObj = { packageName, packageCategory, packageCost, description };


        try {
            const response = await fetch(`${authURL}/add-package`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(packageObj),
            })

            if (response.status === 201) {
                toast.success("Package Added Successfully");
            } else {
                toast.error("Timed out, Try again later!")
            }
        } catch (error) {
            console.log(error);
            toast.error("Internal Server Error!");
        }
    }

    const fetchPackages = async () => {
        try {
            const response = await fetch(`${authURL}/get-packages`); // Assuming your API endpoint is '/api/users'
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, [fetchPackages]);

    return (
        <div className='h-screen'>
            <div>
                <h1 className='text-5xl font-semibold'>Price Plans & Packages</h1>
                <h1 className='text-gray-600'>Manage Price Plans & Package of your Subscriptions</h1>
            </div>

            <div className='w-full'>

                <div class="grid grid-cols-1 mt-6 sm:grid-cols-3 gap-x-5 gap-y-4">
                    <div>
                        <label for="" class="text-base font-medium text-gray-900"> Package Name </label>
                        <div class="mt-2.5 relative">
                            <input onChange={(e) => setpackageName(e.target.value)} value={packageName} type='text' class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                        </div>
                    </div>

                    <div>
                        <label for="" class="text-base font-medium text-gray-900"> Package Category </label>
                        <div class="mt-2.5 relative">
                            <input onChange={(e) => setpackageCategory(e.target.value)} value={packageCategory} type='text' class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                        </div>
                    </div>

                    <div>
                        <label for="" class="text-base font-medium text-gray-900"> Package Price </label>
                        <div class="mt-2.5 relative">
                            <input onChange={(e) => setpackageCost(e.target.value)} value={packageCost} type='number' class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600" />
                        </div>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="" class="text-base font-medium text-gray-900"> Package Description </label>
                        <div class="mt-2.5 relative">
                            <textarea onChange={(e) => setdescription(e.target.value)} value={description} class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600" rows="2"></textarea>
                        </div>
                    </div>

                    <div class="sm:col-span-1">
                        <button onClick={addPackage} type="submit" class="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700">
                            Add New Package
                        </button>
                    </div>
                </div>

                <div className='bg-white shadow-sm border-1 border-slate-300 p-4 rounded-lg my-12'>
                    <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                        <thead className="hidden border-b lg:table-header-group">
                            <tr className="">
                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                    Date
                                </td>
                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                    Package Name
                                </td>

                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                    Package Category
                                </td>

                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                    Package Cost
                                </td>

                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-3">
                                    Package Description
                                </td>
                            </tr>
                        </thead>
                        <tbody className="bg-white lg:border-gray-300">
                            {pacakgeData.map(packages => (
                                <tr key={packages._id} className='border-b border-slate-200'>
                                    <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                        {packages.createdAt}
                                    </td>
                                    <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                        {packages.packageName}
                                    </td>
                                    <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                        {packages.packageCategory}
                                    </td>
                                    <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
                                        {packages.packageCost}
                                    </td>
                                    <td className="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">
                                        {packages.description}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>


        </div>
    )
}

export default PricePlans