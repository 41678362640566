import { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const authURL = `https://quickdoc-server.vercel.app/api/auth`;
//    const authURL = `http://localhost:5005/api/auth`;

    const [clientId, setClientId] = useState(null);

    const clientID = (id) => {
        setClientId(id);
        console.log('client id:', id);
    }

    return (
        <AuthContext.Provider value={{ authURL, clientID, clientId }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}
