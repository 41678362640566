import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/data';

function Dashboard() {
  const { authURL } = useAuth();
  const [featureCounts, setFeatureCounts] = useState({});

  const getFeatureLogs = async () => {
    try {
      const response = await fetch(`${authURL}/get-feature-log`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      const counts = {};

      // Sum the counts based on featureName, converting featureCount to a number
      result.forEach((log) => {
        const { featureName, featureCount } = log;
        const count = parseInt(featureCount, 10); // Convert to a number

        if (counts[featureName]) {
          counts[featureName] += count;
        } else {
          counts[featureName] = count;
        }
      });

      setFeatureCounts(counts);
    } catch (error) {
      console.error('Error retrieving feature logs:', error);
    }
  };

  useEffect(() => {
    getFeatureLogs();
  }, []);

  return (
    <div className='h-full'>

      <div class="max-w-[85rem] mx-auto">

        <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">

          <div class="flex flex-col items-center justify-center w-full p-4 bg-white border shadow-sm rounded-xl">
            <h1 className='text-4xl font-semibold'>Statistics</h1>
            <p className='text-gray-600 text-center'>Empowering Decisions with Insightful Statistics</p>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Freemium Users
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                    72,540
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px] bg-blue-600 text-white rounded-full">
                <i class="fa-regular fa-user-lock fa-g"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              View Statistics
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Premium Users
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                    72,540
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px] bg-blue-600 text-white rounded-full">
                <i class="fa-regular fa-user-unlock fa-g"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              View Statistics
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Sales
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                    $ 1800
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px] bg-blue-600 text-white rounded-full">
                <i class="fa-regular fa-dollar"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              View Statistics
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

        </div>

        <hr className='my-8 mx-8 border' />

        <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">

          <div class="flex flex-col items-center justify-center w-full p-4 col-span-2 bg-white border shadow-sm rounded-xl">
            <h1 className='text-4xl font-semibold'>Feature's Insight</h1>
            <p className='text-gray-600'>Empowering Decisions with Insightful Statistics</p>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['View PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-pdf fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              PDF Viewer
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Split PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-dashed-line fa-2xl text-red-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex font-medium justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Split PDF
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

        </div>

        <div class="grid sm:grid-cols-2 lg:grid-cols-4 mt-8 gap-4 sm:gap-6">

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['JSON to PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-code fa-2xl text-green-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              JSON to PDF
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Merge PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-merge fa-2xl text-yellow-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Merge PDF
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Encrypt PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-lock fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              PDF Encryption
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Decrypt PDF'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-circle-check fa-2xl text-yellow-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              PDF Decryption
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

        </div>

        <div class="grid sm:grid-cols-2 lg:grid-cols-4 mt-8 gap-4 sm:gap-6">

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Watermark'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-word fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              WaterMark PDF
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['OCR'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-scanner-image fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Optical Character Recognition
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Invoice Reciept'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Invoice Generator
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Sales Reciept'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-receipt fa-2xl text-yellow-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Sales Reciept Generator
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

        </div>


        <div class="grid sm:grid-cols-2 lg:grid-cols-4 mt-8 gap-4 sm:gap-6">

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Watermark'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-file-word fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              WaterMark PDF
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['OCR'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-scanner-image fa-2xl text-blue-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Optical Character Recognition
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Invoice Reciept'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Invoice Generator
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

          <div class="flex flex-col w-full bg-white border shadow-sm rounded-xl">
            <div class="p-4 md:p-5 flex justify-between gap-x-3">
              <div>
                <p class="text-xs uppercase tracking-wide text-gray-500">
                  Total Usage Count
                </p>
                <div class="mt-1 flex items-center gap-x-2">
                  <h3 class="text-2xl font-semibold text-gray-800">
                    {featureCounts['Sales Reciept'] || 0}
                  </h3>
                </div>
              </div>
              <div class="flex-shrink-0 flex justify-center items-center size-[46px]text-white rounded-full">
                <i className="fa-duotone fa-receipt fa-2xl text-red-600"></i>
              </div>
            </div>

            <a class="py-3 px-4 md:px-5 inline-flex justify-between font-medium items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 rounded-b-xl" href="#">
              Sales Reciept Generator
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
            </a>
          </div>

        </div>


      </div>
    </div>
  )
}

export default Dashboard