import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import { useAuth } from "../../../../../context/data";

function BillsHistory() {
  const { user } = useUser();
  const [invoiceData, setInvoiceData] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]); // Filtered bills
  const [currentPage, setCurrentPage] = useState(1); // Pagination
  const [searchTerm, setSearchTerm] = useState(""); // Filter term
  const [selectedDate, setSelectedDate] = useState(""); // Date filter
  const { authURL } = useAuth();

  const itemsPerPage = 6;

  const fetchInvoicesData = async () => {
    try {
      const response = await fetch(
        `${authURL}/bills-list?userID=${user.primaryEmailAddress.emailAddress}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const res = await response.json();
      const sortedData = res.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setInvoiceData(sortedData);
      setFilteredInvoices(sortedData); // Initialize filtered data
    } catch (error) {
      console.error("Error fetching Business data:", error);
    }
  };

  const handleDelete = async (invoiceId) => {
    try {
      const response = await fetch(`${authURL}/delete-bill?_id=${invoiceId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete bill");
      }
      toast.success("Bill deleted successfully!");
      const updatedData = invoiceData.filter(
        (invoice) => invoice._id !== invoiceId
      );
      setInvoiceData(updatedData);
      setFilteredInvoices(updatedData); // Update filtered data
    } catch (error) {
      console.error("Error deleting bill:", error);
      toast.error("Failed to delete bill");
    }
  };

  useEffect(() => {
    fetchInvoicesData();
  }, []);

  const handleFilter = () => {
    const filtered = invoiceData.filter(invoice => {
      const matchesSearchTerm =
        invoice.billTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.billId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        new Date(invoice.createdAt).toLocaleDateString().includes(searchTerm);

      const matchesDate =
        selectedDate === "" ||
        new Date(invoice.createdAt).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString();

      return matchesSearchTerm && matchesDate;
    });

    setFilteredInvoices(filtered);
    setCurrentPage(1); // Reset to first page
  };

  useEffect(() => {
    handleFilter();
  }, [searchTerm, selectedDate]);

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to get status classes
  const getStatusClasses = (status) => {
    switch (status) {
      case "unpaid":
        return "bg-yellow-200 text-yellow-700 border-yellow-300";
      case "paid":
        return "bg-green-200 text-green-700 border-green-300";
      case "failed":
        return "bg-red-200 text-red-700 border-red-300";
      default:
        return "bg-gray-200 text-gray-700 border-gray-300";
    }
  };

  return (
    <div className="md:max-w-4xl xl:max-w-6xl mx-auto my-32">

      <div className="flex w-full items-end justify-between">

        <div className="w-full">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Bill Reciept History
          </h1>
          <p className="mt-3 text-gray-600">History of generated Bill Reciept</p>
        </div>

        <div className="flex flex-col w-1/2">
          <div className="flex space-x-4 w-full">
            <input
              type="text"
              placeholder="Search bill receipts..."
              value={searchTerm}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[a-zA-Z0-9\s]*$/; // Allows only letters, numbers, and spaces

                if (value.length <= 16 && regex.test(value)) {
                  setSearchTerm(value);
                }
              }}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400" />

            {/* Date input for filtering */}
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="border w-full border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <lable className="text-sm text-gray-400 mt-1">Filter Bill reciept with ID, Name, Status and with Date.</lable>
        </div>

      </div>

      <div className="flex flex-wrap -m-2 mt-8">
        {currentInvoices.length > 0 ? (
          currentInvoices.map((invoice, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
              <div className="max-w-lg md:p-8 rounded-lg bg-white border border-slate-200 shadow-lg p-8">
                <div className="space-x-4">
                  <span className="text-xs font-medium text-gray-400">
                    <span className="bg-slate-100 border-2 tracking-wider border-slate-200 py-1 px-3 text-slate-700 rounded-full">
                      {invoice.billId}
                    </span>
                  </span>

                  <span
                    className={`text-xs font-medium border-2 tracking-wider py-1 px-3 rounded-full uppercase ${getStatusClasses(
                      invoice.status
                    )}`}
                  >
                    {invoice.status}
                  </span>
                </div>

                <h3 className="text-2xl font-semibold mt-4 tracking-wider text-gray-900 capitalize">
                  Title: {invoice.billTitle}
                </h3>
                <div className="text-sm text-gray-600">
                  Generated on:{" "}
                  {new Date(invoice.createdAt).toLocaleDateString()}
                </div>
                <div className="mt-8 flex w-full space-x-4">
                  <Link
                    to={`/home/generate-docx/bills-reciept-form/bills-template?billId=${invoice.billId}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-eye"></i>
                  </Link>
                  <Link
                    to={`/home/generate-docx/update-bills?_id=${invoice._id}`}
                    className="activeBtn w-full text-center px-8 py-2 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                  >
                    <i className="fa-regular fa-pen"></i>
                  </Link>
                  <button
                    className="activeBtn w-full px-8 py-2 rounded bg-gradient-to-b from-red-500 to-red-600 text-white focus:ring-2 focus:ring-red-400 hover:shadow-xl transition duration-200"
                    onClick={() => handleDelete(invoice._id)}
                  >
                    <i className="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full text-center text-gray-500 mt-8">
            No Bill Receipt Found
          </div>
        )}
      </div>

      {/* Pagination */}
      {currentInvoices.length > 0 ? (
        <div className="flex justify-center mt-10">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            {Array.from(
              { length: Math.ceil(filteredInvoices.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${currentPage === index + 1
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-500 hover:bg-gray-50"
                    }`}
                >
                  {index + 1}
                </button>
              )
            )}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredInvoices.length / itemsPerPage)
              }
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </nav>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default BillsHistory;
