import React, { useState } from 'react';
import { useAuth } from '../../../../../context/data';
import { useUser } from '@clerk/clerk-react';
import toast from 'react-hot-toast'

const ClientModal = ({ isVisible, onClose }) => {
    const { authURL } = useAuth();
    const { user } = useUser();

    const [clientName, setclientName] = useState('');
    const [clientPhone, setclientPhone] = useState('');
    const [clientEmail, setclientEmail] = useState('');
    const [clientAddress, setclientAddress] = useState('');


    async function sendBuisnessDetails() {
        if (clientName === "") {
            toast.error("Client Name is Required!");
            return;
        }
        const clientObj = {
            userID: user.primaryEmailAddress.emailAddress,
            clientName, clientPhone, clientEmail, clientAddress,
        };

        try {
            const response = await fetch(`${authURL}/add-client`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(clientObj),
            });
            toast.success("Client Added Successfully")
            onClose();
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    if (!isVisible) return null;
    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] pt-24 bg-opacity-75 backdrop-blur-sm flex justify-center items-center'>
            <div className='flex flex-col max-w-2xl w-full'>
                <div className='bg-white h-full flex p-8 flex-col items-center justify-center w-full max-w-2xl mx-auto border-2 border-slate-100 rounded shadow-sm'>
                    <div className='flex items-start justify-between w-full'>
                        <div>
                            <h1 className='text-2xl font-semibold'>Client Information</h1>
                            <p className='text-sm text-gray-500'>Manage your Client Information for your buisness</p>
                        </div>

                        <button onClick={() => onClose()} className='activeBtn text-gray-800 text-sm'>
                            <i className="fa-solid fa-xmark fa-xl text-gray-800"></i>
                        </button>

                    </div>
                    <div className='mt-8 space-y-4'>

                        <div className='w-full'>
                            <label className="leading-7 text-gray-600">Client Name</label>
                            <input
                                type="text"
                                value={clientName}
                                maxLength={32}
                                onChange={(e) => {
                                    let cleanedValue = e.target.value;

                                    // Allow only letters, numbers, spaces, hyphens, and underscores
                                    cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s-_]/g, '');

                                    // Prevent leading spaces
                                    if (cleanedValue.startsWith(' ')) {
                                        cleanedValue = cleanedValue.trimStart();
                                    }

                                    setclientName(cleanedValue);
                                }}
                                className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />
                        </div>

                        <div className='flex space-x-4'>
                            <div className='w-full'>
                                <label className="leading-7 text-gray-600">Client Phone</label>
                                <input
                                    type="text"
                                    value={clientPhone}
                                    maxLength={18}
                                    onChange={
                                        (e) => {
                                            let cleanedValue = e.target.value.replace(/[^0-9\s+]/g, '');
                                            // Prevent leading spaces
                                            if (cleanedValue.startsWith(' ')) {
                                                cleanedValue = cleanedValue.trimStart();
                                            }
                                            setclientPhone(cleanedValue)
                                        }}


                                    className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                            <div className='w-full'>
                                <label className="leading-7 text-gray-600">Client Email</label>
                                <input
                                    type="text"
                                    value={clientEmail}
                                    maxLength={32}
                                    onChange={(e) => {
                                        let cleanedValue = e.target.value;

                                        // Remove any invalid characters
                                        cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9@._-]/g, '');

                                        // Prevent starting with @
                                        if (cleanedValue.startsWith('@')) {
                                            cleanedValue = cleanedValue.slice(1);
                                        }

                                        // Ensure there is at most one @ symbol
                                        const atSymbolCount = (cleanedValue.match(/@/g) || []).length;
                                        if (atSymbolCount > 1) {
                                            // Remove extra @ symbols while keeping the first one
                                            cleanedValue = cleanedValue.replace(/@/g, (match, offset) => (offset === cleanedValue.indexOf('@') ? match : ''));
                                        }

                                        // Trim leading and trailing spaces
                                        cleanedValue = cleanedValue.trim();

                                        setclientEmail(cleanedValue);
                                    }}
                                    className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>

                        <div className='w-full'>
                            <label className="leading-7 text-gray-600">Client Address</label>
                            <textarea
                                value={clientAddress}
                                maxLength={62}
                                onChange={(e) => {
                                    let cleanedValue = e.target.value;

                                    // Prevent leading spaces
                                    if (cleanedValue.startsWith(' ')) {
                                        cleanedValue = cleanedValue.trimStart();
                                    }

                                    // Allow only letters, numbers, spaces, commas, periods, hyphens, and slashes
                                    cleanedValue = cleanedValue.replace(/[^a-zA-Z0-9\s,.-/]/g, '');

                                    setclientAddress(cleanedValue);
                                }}
                                className="w-full bg-white max-h-24 rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            />

                        </div>

                        <button onClick={sendBuisnessDetails} className='className="px-8 w-full py-4 rounded bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"'>
                            ADD Client
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientModal;