import React from 'react';
import { SignUp } from '@clerk/clerk-react';

function UserLogin() {
    return (
        <div className="relative overflow-hidden min-h-screen">
            <div aria-hidden="true" className="flex absolute -top-96 start-1/2 transform -translate-x-1/2">
                <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[144rem] rotate-[-60deg] transform -translate-x-[10rem]"></div>
                <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[80rem] rounded-full origin-top-left -rotate-12 -translate-x-[15rem]"></div>
                <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[144rem] rotate-[-60deg] transform -translate-x-[10rem]"></div>
            </div>
            <div className='flex items-center justify-center h-screen'>
                <SignUp signInUrl='/login' forceRedirectUrl={"/home"}></SignUp>
            </div>
        </div>
    )
}

export default UserLogin


